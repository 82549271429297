import { Box, styled } from '@mui/material';

export const DashboardLayoutContainer = styled(Box)<{ notMain?: boolean }>`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: ${({ theme, notMain }) => notMain && theme.palette.grey[200]};
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const AnalyticLayoutContainer = styled(Box)`
  width: 100%;
  padding: 4px 20px;
  position: relative;

  @media (max-width: 1024px) {
    height: auto;
  }
`;
