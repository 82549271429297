import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserType {
  name: string;
  search: string;
  selectedGroup: any;
  mailItem: any;
}

const initialState: UserType = {
  name: '',
  search: '',
  selectedGroup: null,
  mailItem: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<any>) => {
      state.selectedGroup = action.payload;
    },
    setMailItem: (state, action: PayloadAction<any>) => {
      state.mailItem = action.payload;
    },
  },
});

export const { setUser, setSearch, setSelectedGroup, setMailItem } = userSlice.actions;
export default userSlice.reducer;
