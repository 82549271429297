import React from 'react';

export const Info = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 5.92487 18.0751 1 12 0.999999C5.92487 0.999999 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM11 8L11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12L13 8C13 7.44771 12.5523 7 12 7C11.4477 7 11 7.44771 11 8ZM11.2929 15.7071C10.9024 15.3166 10.9024 14.6834 11.2929 14.2929C11.6834 13.9024 12.3166 13.9024 12.7071 14.2929C13.0976 14.6834 13.0976 15.3166 12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071Z"
        fill="#FB2626"
      />
      <mask id="mask0_5584_6383" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12C23 5.92487 18.0751 1 12 0.999999C5.92487 0.999999 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM11 8L11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12L13 8C13 7.44771 12.5523 7 12 7C11.4477 7 11 7.44771 11 8ZM11.2929 15.7071C10.9024 15.3166 10.9024 14.6834 11.2929 14.2929C11.6834 13.9024 12.3166 13.9024 12.7071 14.2929C13.0976 14.6834 13.0976 15.3166 12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5584_6383)">
        <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="#FB2626" />
      </g>
    </svg>
  );
};
