import axios from '../config/axios';
import { MailComposeType } from '../pages/Mail/MailType';
import { UserAddType } from '../types';

interface CredentialsLogin {
  email: string;
  password: string;
}

export const login = async (data: CredentialsLogin) => {
  const res = await axios({
    method: 'post',
    url: '/authorization/signin',
    data,
  });

  return res;
};

export const getUsers = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/user/search',
    params,
  });

  return res;
};
export const getAllParents = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/user/all-parents',
    params,
  });

  return res;
};

export const getUserId = async (uid: string) => {
  const res = await axios({
    method: 'get',
    url: `user/details/${uid}`,
  });

  return res;
};
export const deleteUser = async (uid: string) => {
  const res = await axios({
    method: 'delete',
    url: `/user`,
    data: { uid },
  });

  return res;
};
export const addStudentToParent = async (data: any) => {
  const res = await axios({
    method: 'put',
    url: `/user/parent/connect-student`,
    data,
  });

  return res;
};
export const removeStudentFromParent = async (data: { studentId: string; parentId: string }) => {
  const res = await axios({
    method: 'delete',
    url: `/user/parent/delete-student`,
    data,
  });

  return res;
};

export const editUser = async (data: {
  uid: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: number;
  phoneNumber: string;
  notes?: string;
  schoolYear?: number;
  address?: string;
  city: string;
  image?: {
    type: string;
    data: string;
  };
}) => {
  const res = await axios({
    method: 'PUT',
    url: '/user',
    data,
  });

  return res;
};
// Groups

export const getGroups = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/group/search',
    params,
  });

  return res;
};

export const createGroup = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/group',
    data,
  });
  return res;
};

export const removeGroupMember = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `group/member/remove`,
    data,
  });

  return res;
};

export const getGroupId = async (groupId: string) => {
  const res = await axios({
    method: 'get',
    url: `/group/groupId/${groupId}`,
  });

  return res;
};

export const addGroupMember = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `group/member/add`,
    data,
  });

  return res;
};

export const deleteGroup = async (groupId: string) => {
  const res = await axios({
    method: 'delete',
    url: `/group/groupId/${groupId}`,
  });

  return res;
};

export const createUser = async (data: UserAddType) => {
  const res = await axios({
    method: 'post',
    url: '/user',
    data,
  });
  return res;
};

export const importUsers = async (params: { role: string; notes?: string }, file: any) => {
  const res = await axios({
    method: 'post',
    url: '/user/import',
    params,
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

// Mails

export const getMails = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/mail/all',
    params,
  });

  return res;
};

export const composeMail = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/mail/compose',
    data,
  });
  return res;
};
export const reqPayment = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/mail/parent-payment',
    data,
  });
  return res;
};

export const favoriteMail = async (mailId: string, favorite: boolean) => {
  const res = await axios({
    method: 'put',
    url: '/mail/favorite',
    data: { mailId, favorite },
  });

  return res;
};

export const getInvoice = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `invoice/${id}`,
  });

  return res;
};

export const getInvoicePdf = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `invoice/pdf/${id}`,
  });

  return res;
};

// transactions

export const getTransactionsTotal = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'transaction/school/total-amount',
    params,
  });

  return res;
};
export const getTransactions = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'transaction/school',
    params,
  });

  return res;
};
export const getUserTransactions = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'transaction/school/view-transactions',
    params,
  });

  return res;
};

export const getTransaction = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `transaction/${id}/details`,
  });

  return res;
};

// transaction/school-bill?studentId=VnnYJVESQVggNNRCwG75hBF8zR83&page=1

export const getSchoolBillTransactions = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `transaction/parent/${id}/school-bill`,
  });

  return res;
};
export const getSchoolBillTransactionsStudent = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'transaction/school-bill',
    params,
  });

  return res;
};
export const updateSchoolBillAmount = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: 'transaction/parent/school-bill/paid',
    data,
  });

  return res;
};

// analytics

export const getAnalytics = async (analytic: string, filter?: any) => {
  let params: any;
  if (filter) {
    params = { ...filter };
    Object.keys(params).forEach((key: string) => {
      if (params[key] === '') {
        delete params[key];
      }
    });
  }
  const res = await axios({
    method: 'get',
    url: `/analytics/school/${analytic}`,
    params,
  });

  return res;
};

// vendors

export const getVendors = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/vendor/all',
    params,
  });

  return res;
};

export const createVendor = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `vendor`,
    data,
  });

  return res;
};

export const getVendorProfile = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `/vendor/${id}`,
  });

  return res;
};

export const deleteVendor = async (vendorId: string) => {
  const res = await axios({
    method: 'delete',
    url: `/vendor`,
    data: { vendorId },
  });

  return res;
};

export const editVendor = async (data: any) => {
  const res = await axios({
    method: 'put',
    url: `vendor`,
    data,
  });

  return res;
};

export const addVendorMember = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `vendor/staff/add`,
    data,
  });

  return res;
};

export const removeVendorMember = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `vendor/staff/remove`,
    data,
  });

  return res;
};

// school

export const getSchool = async () => {
  const res = await axios({
    method: 'get',
    url: `/school`,
  });

  return res;
};

// internal support

export const getInternalSupport = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'support/ticket/all',
    params,
  });

  return res;
};

export const getAgents = async () => {
  const res = await axios({
    method: 'get',
    url: 'support/agents',
  });

  return res;
};

export const getTicket = async (ticketId: string) => {
  const res = await axios({
    method: 'get',
    url: `support/ticket`,
    params: {
      ticketId,
    },
  });

  return res;
};

export const resolveTicket = async (data?: { ticketId?: string; enabled: boolean }) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/resolved`,
    data,
  });

  return res;
};

export const assignAgent = async (data: { ticketId: string; agentId: string }) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/agent/assign`,
    data,
  });

  return res;
};
export const sendReply = async (ticketId: string, message: string) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/reply`,
    data: {
      ticketId,
      message,
    },
  });

  return res;
};

export const exportToExternal = async (ticketId: string) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/export-external`,
    data: {
      ticketId,
    },
  });

  return res;
};

// external support

export const getExternalSupport = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'support/ticket/all-external',
    params,
  });

  return res;
};

export const composeExternalTicket = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket`,
    data,
  });

  return res;
};

// permissions

export const getPermisions = async () => {
  const res = await axios({
    method: 'get',
    url: 'permissions',
  });

  return res;
};

export const getMyPermisions = async () => {
  const res = await axios({
    method: 'get',
    url: 'permissions/my',
  });

  return res;
};

export const getPermisionGroups = async () => {
  const res = await axios({
    method: 'get',
    url: 'group/permissions',
  });

  return res;
};

// group/permissions

export const setPermissions = async (data: { groupId: string; permissionIds: string[] }) => {
  const res = await axios({
    method: 'post',
    url: `group/permissions`,
    data,
  });

  return res;
};
