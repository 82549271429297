import { styled, useTheme } from '@mui/material';
import React from 'react';
import { useTransactionSchoolBill } from '../hooks/useTransactionSchoolBill';
import CountUp from 'react-countup';

import { CustomText, UserListItemContainer } from '../pages/Users/Users.style';
import { Loading } from '../components/Loading';
import { currencyFormat } from '../utils';

const SchoolBillStyle = styled(UserListItemContainer)<{
  alignItems?: string;
}>`
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-direction: column;
`;

interface SchoolBillAmount {
  desc?: string;
  uid: string;
}

export const SchoolBill: React.FC<SchoolBillAmount> = ({ desc, uid }) => {
  const theme = useTheme();
  const { totalSchoolBill, isLoading } = useTransactionSchoolBill(uid);

  console.log(totalSchoolBill);

  return (
    <SchoolBillStyle padding="20px">
      <CustomText fontSize={1.1} bold={700}>
        {desc}
      </CustomText>
      <CustomText fontSize={3.3} bold={700} color={theme.color.darkerBlue}>
        {totalSchoolBill ? currencyFormat(totalSchoolBill / 100) : 0}
      </CustomText>
    </SchoolBillStyle>
  );
};
