import { useTheme } from '@mui/material';
import React, { useState } from 'react';

import { useForm } from '../../hooks/useForm';
import { UserAddType, UserType } from '../../types';
import { AddModalContent, ModalUser } from './Users.style';
import { createUser } from '../../api';
import { AddParent } from './AddParent';
import { AddStudent } from './AddStudent';
import { AddStaff } from './AddStaff';

const cities: string[] = ['London', 'New York', 'Los Angeles', 'Seeatle', 'Toronto', 'Miami'];

export interface UserAddProps {
  isOpen: boolean;
  onClose: () => void;
  tabs: string[];
  activeTab: number;
  refetch: any;
}

export const UserAdd: React.FC<UserAddProps> = ({ isOpen, onClose, tabs, activeTab, refetch }) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalUser
      open={isOpen}
      onClose={() => {
        if (!loading) {
          onClose();
        }
      }}
    >
      <AddModalContent component="form">
        {tabs[activeTab] === 'parent' && (
          <AddParent
            refetch={refetch}
            loading={loading}
            setLoading={setLoading}
            onClose={onClose}
          />
        )}
        {tabs[activeTab] === 'student' && (
          <AddStudent
            loading={loading}
            setLoading={setLoading}
            refetch={refetch}
            onClose={onClose}
          />
        )}
        {tabs[activeTab] === 'staff' && (
          <AddStaff loading={loading} setLoading={setLoading} refetch={refetch} onClose={onClose} />
        )}
      </AddModalContent>
    </ModalUser>
  );
};
