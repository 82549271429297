import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { CustomPieChart } from './PieChart';
import moment from 'moment';
import { SmallFillter } from './SmallFilter';
import { getAnalytics } from '../../api';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};

export const TotalVendors = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    permanentVendorCount: 0,
    temporaryVendorCount: 0,
    totalVendorCount: 0,
  });

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('vendors/total', {
      from: filter.from,
      to: filter.to,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);

  return (
    <>
      <CustomPieChart
        title={`Total Vendors: ${value.totalVendorCount}`}
        loading={loading}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        percentageTotal={value.totalVendorCount}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
        }}
        chartInfo={[
          {
            bgcolor: theme.color.lightBlue,
            banner: 'Temporary Vendors',
            amount: value?.temporaryVendorCount,
          },
          {
            bgcolor: theme.color.darkerBlue,
            banner: 'Permanent Vendors',
            amount: value.permanentVendorCount,
          },
        ]}
        data={[
          {
            name: 'Temporary Vendors',
            value: value?.temporaryVendorCount,
            color: theme.color.lightBlue,
          },
          {
            name: 'Permanent Vendors',
            value: value.permanentVendorCount,
            color: theme.color.darkerBlue,
          },
        ]}
      />
      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
