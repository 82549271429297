// import React from 'react';
// import { Flex } from '../../../UserDetail/UserDetail.style';
// import { TotalDeposits } from './TotalDeposits';
// import { TotalDepositsLinear } from './TotalDepositsLinear';
import { LineCharts } from '../../../../components/Charts/LineChart';
import { DepositAnalyticsContainer } from './AnalyticDeposits.style';
import { GrossRevenue } from '../../../../components/Charts/GrossRevenue';
import { BankAccount } from '../../../../components/Charts/BankAccount';
import { TotalFees } from '../../../../components/Charts/TotalFees';
import { Deposits } from '../../../../components/Charts/Deposits';

export const data = [
  {
    hours: 'Jan',
    credits: 0,
  },
  {
    hours: 'Feb',
    credits: 150,
  },
  {
    hours: 'Mar',
    credits: 400,
  },
  {
    hours: 'Apr',
    credits: 200,
  },
  {
    hours: 'Jun',
    credits: 150,
  },
  {
    hours: 'July',
    credits: 570,
  },
  {
    hours: 'Aug',
    credits: 150,
  },
  {
    hours: 'Sep',
    credits: 250,
  },
  {
    hours: 'Oct',
    credits: 200,
  },
  {
    hours: 'Nov',
  },
  {
    hours: 'Dec',
  },
];

export const AnalyticDeposits = () => (
  <DepositAnalyticsContainer>
    <GrossRevenue />
    <BankAccount />
    <TotalFees />
    <Deposits />
  </DepositAnalyticsContainer>
);
