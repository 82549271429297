import { Box, FormControl, Input, InputLabel, Select, styled } from '@mui/material';
import { CustomText } from '../../pages/Users/Users.style';

export interface WrapperTypes {
  info?: string;
  id?: string;
  alignitems?: string;
  width?: string;
  children?: any;
}

export const InputContainer = styled(Box)<{ width?: string; alignitems?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;

  & .Mui-focused {
    outline-color: blue;
  }
`;

export const InputWrapper: React.FC<WrapperTypes> = ({ width, alignitems, id, info, children }) => (
  <InputContainer width={width} alignitems={alignitems}>
    <InputLabel htmlFor={id} style={{ alignSelf: 'flex-start' }}>
      <CustomText color="rgb(0,0,0)" fontSize={1.2} bold={700}>
        {info}
      </CustomText>
    </InputLabel>
    {children}
  </InputContainer>
);
