import { AccountCircle } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/authContext';
import { ROUTES } from '../../constants';
import { MuiIconContainer, UserContainer, UserName } from '../AdminConsole/AdminConsole.style';
import { Logo } from './Logo';
import { LeftContainer, NavContainer } from './Nav.style';
// svg
import { QuestionMarkInCircle } from '../../svg/QuestionMarkInCircle';
import { NavArrow } from '../../svg/NavArrow';
import { FullScreen } from '../../svg/FullScreen';

interface NavProps {
  logout: () => void;
}

export const Nav: React.FC<NavProps> = ({ logout }) => {
  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = useState(false);
  const { user } = useContext(AuthContext);
  const elem = document.querySelector('body');

  useEffect(() => {
    if (fullScreen) {
      elem?.requestFullscreen?.();
    } else {
      document?.exitFullscreen?.();
    }
  }, [fullScreen]);

  return (
    <NavContainer>
      <LeftContainer>
        <Logo />
      </LeftContainer>
      <LeftContainer gap={9} align="flex-end">
        <UserContainer onClick={logout}>
          <MuiIconContainer height={37} width={37}>
            <AccountCircle />
          </MuiIconContainer>
          <UserName>{user && user.name}</UserName>
          <NavArrow />
        </UserContainer>
        <MuiIconContainer height={32} width={32} onClick={() => navigate(ROUTES.FAQ)}>
          <QuestionMarkInCircle />
        </MuiIconContainer>
        <MuiIconContainer
          height={30}
          width={30}
          onClick={() => {
            setFullScreen((prev: boolean) => !prev);
          }}
        >
          <FullScreen fullScreen={fullScreen} />
        </MuiIconContainer>
      </LeftContainer>
    </NavContainer>
  );
};
