import { Box, styled } from '@mui/material';
import emotionStyled from '@emotion/styled';

export const GroupPageContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const GroupPageContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SkeletonContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 1/1.5;
`;
export const GroupFilterContainer = styled(Box)<{ height?: string; width?: string; gap?: number }>`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || '100%'};
  display: flex;
  justify-content: flex-end;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  padding: 6px 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GroupItemsContainer = styled(Box)<{ gap?: number }>`
  width: 100%;
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  padding: 20px;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    gap: 9px;
  }
`;

export const GroupdItemContainer = styled(Box)<{ bgcolor?: string }>`
  width: 100%;
  aspect-ratio: 1/1.5;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme, bgcolor }) => (bgcolor ? bgcolor : theme.palette.common.white)};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GroupItemMain = styled(Box)<{ gap?: number }>`
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  padding: 18px;

  p:nth-of-type(1) {
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.color.lightBlue};
    }
  }
`;

export const GroupItemSecondary = styled(Box)<{ justifyContent?: string; gap?: string }>`
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${({ gap }) => gap || ''};
  padding: 18px;
  border-top: 1px solid ${({ theme }) => theme.color.borderColor};
`;

export const AddGroupPhoto = emotionStyled.label<{ gap?: number }>`
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  cursor: pointer;
`;
