import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAnalytics } from '../api';

export const useAnalytics = (analytic: string, filter?: any) => {
  const [value, setValue] = useState([]);
  const [totalValue, setTotalValue] = useState();
  const { data, isLoading } = useQuery(['user', filter], () => getAnalytics(analytic, filter));

  useEffect(() => {
    setValue(data?.data?.data?.monthsData);
    setTotalValue(data?.data?.data?.total);
  }, [data]);

  return {
    value,
    isLoading,
    totalValue,
    data,
  };
};

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2
