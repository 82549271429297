import { Tab } from '@mui/material';
import React from 'react';
import { CustomButton } from '../../common';
import CloseFilter from '../../common/CloseFilter';
import { hasValue } from '../../helpers';
import { Filter } from '../../svg/Filter';

import { FiltersContainer, TabsContainer } from '../Users/Users.style';
import { a11yProps } from '../Users/UsersControl';

interface InternalSupportControlProps {
  value: number;
  changeValue: any;
  tabs: string[];
  showModal: () => void;
  filter: any;
  clearFilter: () => void;
}

export const InternalSupportControl: React.FC<InternalSupportControlProps> = ({
  value,
  changeValue,
  clearFilter,
  filter,
  tabs,
  showModal,
}) => {
  console.log(filter);
  return (
    <FiltersContainer>
      <TabsContainer value={value} onChange={changeValue}>
        {tabs.map((item: string, index: number) => (
          <Tab key={index} label={item} {...a11yProps(index)} />
        ))}
      </TabsContainer>
      {!hasValue(filter) ? (
        <CustomButton content={<Filter />} onClick={showModal} />
      ) : (
        <CloseFilter clearFilter={clearFilter} />
      )}
    </FiltersContainer>
  );
};
