import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';

import { CustomText, UserHeaderContainer, UserListItemContent } from '../Users/Users.style';

export const TransactionDetailsListHeader = () => (
  <UserHeaderContainer>
    <UserListItemContent flex={15} gap={2} sx={{ cursor: 'pointer' }}>
      <CustomText bold={700}>Category</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <CustomText bold={700}>Item</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={5}>
      <CustomText bold={700}>Qty</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={5} jcontent="flex-end">
      <CustomText bold={700}>Price</CustomText>
    </UserListItemContent>
  </UserHeaderContainer>
);
