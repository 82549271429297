import { createTheme, ThemeOptions } from '@mui/material';
import '@fontsource/montserrat';

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      lightRed: string;
      lightGrey: string;
      borderGrey: string;
      grey: string;
      darkerBlue: string;
      lightBlue: string;
      borderColor: string;
      depositGreen: string;
      depositLightGreen: string;
      depositDarkGreen: string;
      schollBillRed: string;
      parentYellow: string;
      buttonGrey: string;
    };
  }

  interface ThemeOptions {
    color: {
      lightRed?: string;
      lightGrey?: string;
      borderGrey?: string;
      grey?: string;
      darkerBlue?: string;
      lightBlue?: string;
      borderColor?: string;
      depositGreen?: string;
      depositLightGreen?: string;
      depositDarkGreen?: string;
      schollBillRed?: string;
      parentYellow?: string;
      buttonGrey?: string;
    };
  }
}

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  color: {
    lightRed: '#F46A6A',
    lightGrey: '#ECECEC',
    borderGrey: '#DCDCDC',
    grey: '#979797',
    darkerBlue: '#0C84FC',
    lightBlue: '#54B1E5',
    borderColor: '#ABABAB',
    depositGreen: '#05B60C',
    depositLightGreen: '#8BEE23',
    depositDarkGreen: '#008234',
    schollBillRed: '#FB2626',
    parentYellow: '#EDBB3C',
    buttonGrey: '#DADADA',
  },
});
