import { IconButton, Modal, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ModalContainer, ModalTitle } from '../../components/FilterModal';
import { CustomText } from '../Users/Users.style';
import CloseIcon from '@mui/icons-material/Close';
import { AgentInput } from '../../common/Inputs/AgentInput';
import { Flex } from '../../common/Containers';
import { CustomButton } from '../../common';
import { useNotification } from '../../hooks/useNotification';

export const AssignAgentModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  agentAssign: any;
}> = ({ open, handleClose, agentAssign }) => {
  const theme = useTheme();
  const { notifyError } = useNotification();
  const [agentId, setAgentId] = useState(null);
  const handleSubmit = async () => {
    if (!agentId) {
      notifyError('Please select agent to assign!');
      return;
    }
    await agentAssign.mutateAsync(agentId);
    handleClose();
  };
  console.log(agentAssign.isLoading);
  return (
    <Modal
      open={open}
      onClose={() => {
        if (!agentAssign.isLoading) {
          handleClose();
        }
      }}
    >
      <ModalContainer>
        <ModalTitle>
          <CustomText color={theme.color.darkerBlue} bold={700} fontSize={1.2}>
            ASSIGN AGENT
          </CustomText>
          <IconButton
            onClick={() => {
              if (!agentAssign.isLoading) {
                handleClose();
              }
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </ModalTitle>
        <Flex p={20} column>
          <AgentInput
            info="Agent Name"
            value={agentId}
            onChange={(event: any, obj: any) => {
              setAgentId(obj.uid);
            }}
          />
          <CustomButton
            content="Submit"
            width="100%"
            onClick={handleSubmit}
            loading={agentAssign.isLoading}
          />
        </Flex>
      </ModalContainer>
    </Modal>
  );
};
