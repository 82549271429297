import { Divider, IconButton, MenuItem, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { CustomButton } from '../../../common';
import { Flex } from '../../../common/Containers';
import { PhoneNumberInput } from '../../../common/Inputs';
import { InputField, SelectField } from '../../../common/Inputs/Input';
import { InputWrapper } from '../../../common/Inputs/InputWrapper';
import { daysInMonth, months, years } from '../../../helpers/date';
import { useForm } from '../../../hooks/useForm';
import { AddUserPhoto } from '../../Users/AddUserPhoto';
import {
  AddModalContent,
  CustomText,
  FlexInputContainer,
  ModalUser,
} from '../../Users/Users.style';
import { VendorCreateType } from '../PermanentVendors/VendorTypes';
import Checkbox from '@mui/material/Checkbox';
import { createVendor } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

interface PermanentVendorsAddProps {
  bannerTitle: string;
  onClose: () => void;
  isOpen: boolean;
  refetch: any;
}

export const PermanentVendorsAdd: React.FC<PermanentVendorsAddProps> = ({
  onClose,
  isOpen,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { notifySuccess, notifyError } = useNotification();
  const { values, forceChange, onChange } = useForm<VendorCreateType>({
    name: '',
    email: '',
    phoneNumber: '',
    type: 'permanent',
    image: {
      type: '',
      data: '',
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    const res = await createVendor(values);
    if (res.status === 200) {
      notifySuccess(res.data.message);
      onClose();
    } else {
      notifyError(res.data.message);
    }
    refetch();
    setLoading(false);
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <Flex column gap="0px" noCenter>
          <CustomText
            fontSize={1.4}
            bold={700}
            uppercase
            color={theme.color.darkerBlue}
            margin="0 0 20px 0"
          >
            Add Permanent Vendor
          </CustomText>

          <Divider flexItem />
        </Flex>
        <AddUserPhoto size={7} onChange={(data: string) => forceChange(data, 'image')} />

        <InputField
          id="name"
          info="Permanent Vendor Name"
          name="name"
          value={values.name}
          onChange={onChange}
          placeholder="Enter here"
        />

        <InputField
          id="email"
          info="Email"
          name="email"
          onChange={onChange}
          value={values.email}
          type="email"
          placeholder="Enter here"
        />

        <FlexInputContainer>
          <Flex>
            <PhoneNumberInput
              type="text"
              name="phoneNumber"
              info="PhoneNumber"
              placeholder="+1 992 9922"
              onChange={(value: string) => forceChange(value, 'phoneNumber')}
              value={values.phoneNumber}
            />
          </Flex>
        </FlexInputContainer>

        <CustomButton
          content="Submit"
          width="100%"
          type="submit"
          margin="1% 0"
          onClick={handleSubmit}
          loading={loading}
        />
      </AddModalContent>
    </ModalUser>
  );
};
