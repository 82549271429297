import React from 'react';
import { Box, styled, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Flex } from '../common/Containers';
import { CustomText } from '../pages/Users/Users.style';
import { useNavigate } from 'react-router-dom';

const HeaderNavigationContainer = styled(Box)`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey};
  border-top: 1px solid ${({ theme }) => theme.color.grey};
`;

interface HeaderNavigationProps {
  title: string;
  children?: any;
  titleName?: string;
}

export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  title,
  titleName,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Flex p="0 20px" noFlex>
      <HeaderNavigationContainer>
        <Flex>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <CustomText bold={700} fontSize={1.7}>
            {title}
          </CustomText>
          {titleName && <CustomText fontSize={1.7}>/ {titleName}</CustomText>}
        </Flex>
        <Flex flexEnd fullHeight>
          {children}
        </Flex>
      </HeaderNavigationContainer>
    </Flex>
  );
};
