import { styled, Box, Icon } from '@mui/material';
import { CustomText, UserListItemContainer } from '../../pages/Users/Users.style';
import { Circle } from '../../common';
import { Profile } from '../../svg/Profile';
import { Flex } from '../../common/Containers';
import CountUp from 'react-countup';

const CardContainer = styled(UserListItemContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UserCard: React.FC<{
  title: string;
  color: string;
  count: number;
}> = ({ title, color, count }) => {
  return (
    <CardContainer>
      <Flex column spaceBetween p={10}>
        <Circle color={color}>
          <Profile />
        </Circle>
        <Flex column contectCenter noFlex>
          <CustomText fontSize={1.2} bold={700}>
            {title}
          </CustomText>
          <CustomText fontSize={2} bold={700}>
            {count ? <CountUp end={count} /> : 0}
          </CustomText>
        </Flex>
      </Flex>
    </CardContainer>
  );
};
