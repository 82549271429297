import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { CustomText, UserListItemContainer, UserListItemContent } from '../Users/Users.style';

interface TransactionItemProps {
  name: string;
  categoryName: string;
  price: number;
  quantity: string;
}

export const TransactionProduct: React.FC<TransactionItemProps> = ({
  name,
  categoryName,
  price,
  quantity,
}) => {
  const theme = useTheme();
  return (
    <UserListItemContainer>
      <UserListItemContent flex={15}>
        <CustomText>{categoryName}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={10}>
        <CustomText>{name}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <CustomText>{quantity}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={5} jcontent="flex-end">
        <CustomText color={theme.color.depositGreen}>£{price / 100}</CustomText>
      </UserListItemContent>
    </UserListItemContainer>
  );
};
