import { styled, Box } from '@mui/material';

export const DepositAnalyticsContainer = styled(Box)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 75vh;
`;
