import React from 'react';

export const Profile: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.5001 0.916626C16.4101 0.916626 0.916748 16.41 0.916748 35.5C0.916748 54.59 16.4101 70.0833 35.5001 70.0833C54.5901 70.0833 70.0834 54.59 70.0834 35.5C70.0834 16.41 54.5901 0.916626 35.5001 0.916626ZM35.5001 11.2916C41.2409 11.2916 45.8751 15.9258 45.8751 21.6666C45.8751 27.4075 41.2409 32.0416 35.5001 32.0416C29.7592 32.0416 25.1251 27.4075 25.1251 21.6666C25.1251 15.9258 29.7592 11.2916 35.5001 11.2916ZM35.5001 60.4C26.8542 60.4 19.2113 55.9733 14.7501 49.2641C14.8538 42.382 28.5834 38.6125 35.5001 38.6125C42.3822 38.6125 56.1463 42.382 56.2501 49.2641C51.7888 55.9733 44.1459 60.4 35.5001 60.4Z"
      fill={color ? color : 'white'}
    />
  </svg>
);
