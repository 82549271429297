import { Divider, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../common';
import { Flex } from '../../common/Containers';
import { CustomInput } from '../../common/Inputs/Input';
import { ModalTitle } from '../../components/FilterModal';
import CloseIcon from '@mui/icons-material/Close';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';
import { reqPayment } from '../../api';
import { useNotification } from '../../hooks/useNotification';

export const ReqPaymentModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  amount?: number | null;
  parentId: string;
}> = ({ isOpen, onClose, amount, parentId }) => {
  const theme = useTheme();
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(amount);
  const [editValue, setEditValue] = useState(null);

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const sendReq = async () => {
    setLoading(true);
    try {
      const res = await reqPayment({
        parentId,
        amount: value,
      });
      if (!res?.data.error) {
        notifySuccess('Request for payment sent!');
        setLoading(false);
        onClose();
      } else {
        notifyError(res.data.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      notifyError('Somethiing went wrong');
    }
  };
  return (
    <ModalUser open={isOpen} onClose={handleClose}>
      <AddModalContent component="form">
        <Flex spaceBetween>
          <CustomText uppercase color={theme.color.darkerBlue} bold={700} fontSize={1.2}>
            Payment Request
          </CustomText>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Flex>
        <Divider />
        <Flex column>
          <CustomText>You can request a full amount or a custom amount</CustomText>
          <CustomText bold={700}>Amount</CustomText>
          {!edit ? (
            <CustomText fontSize={2} bold={700} color={theme.color.darkerBlue}>
              £{!value ? 0 : value / 100}
            </CustomText>
          ) : (
            <CustomInput
              type="number"
              value={editValue}
              onChange={(e: any) => setEditValue(e.target.value)}
            />
          )}
          {!edit ? (
            <CustomButton width="100%" loading={loading} onClick={sendReq}>
              Send request
            </CustomButton>
          ) : (
            <CustomButton
              width="100%"
              onClick={() => {
                editValue && setValue(editValue * 100);
                setEdit(false);
              }}
            >
              Save
            </CustomButton>
          )}
          {!edit ? (
            <CustomButton
              width="100%"
              bgcolor={theme.color.lightGrey}
              textcolor={theme.palette.common.black}
              onClick={() => setEdit(true)}
              disabled={loading}
            >
              Edit amount
            </CustomButton>
          ) : (
            <CustomButton
              width="100%"
              bgcolor={theme.color.lightGrey}
              textcolor={theme.palette.common.black}
              onClick={() => setEdit(false)}
              disabled={loading}
            >
              Cancel
            </CustomButton>
          )}
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
