import React, { useEffect, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { FilterModal, FilterPropTypes } from '../FilterModal';
import { SelectField, YearInput, MonthInput, AutocompleteInput } from '../../common/Inputs';
import { Flex } from '../../common/Containers';
import { CustomButton } from '../../common';
import moment from 'moment';
import { terms, userTypes } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import { useUsers } from '../../hooks/useUsers';
import { MenuItem } from '@mui/material';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const BigFilter: React.FC<BigFilterPropTypes> = ({ open, handleClose, onSave }) => {
  const { users, tabs, setActiveTab, setSelectedGroupId } = useUsers(null);
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [userOption, setUserOptions] = useState<any[]>([]);
  const { values, forceChange } = useForm<{
    year?: null | number;
    term?: null | string;
    month?: null | number;
    user?: null | string;
    group?: null | {
      name: string | null;
      id: string | null;
    };
    name?: null | {
      name: string | null;
      id: string | null;
    };
  }>({
    year: '',
    term: '',
    month: '',
    user: '',
    group: '',
    name: '',
  });
  const { groups } = useGroups(values.user);

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  useEffect(() => {
    const arr: any[] = [];
    groups.map((groups: any) =>
      Object.values(groups).map((group: any, index: number) => arr.push(group)),
    );
    setGroupOptions(arr);
  }, [groups]);

  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
            placeholder="All Years"
          />
          <SelectField
            value={values.term}
            name="term"
            id="term"
            disabled={!values.year}
            placeholder="All Term"
            onChange={(e: any) => {
              forceChange(e.target.value, 'term');
            }}
            info="Term"
            options={terms}
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            placeholder="All Month"
            info="Month"
          />
        </Flex>
        <Flex>
          <Flex>
            <SelectField
              value={values.user}
              name="role"
              id="user"
              placeholder="All Users"
              info="Users"
              onChange={(e: any) => {
                setActiveTab(tabs.indexOf(e.target.value));
                forceChange(e.target.value, 'user');
              }}
            >
              {['student', 'parent'].map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </SelectField>
          </Flex>
          <Flex flex={2}>
            <AutocompleteInput
              value={values.group}
              name="groupId"
              id="group"
              placeholder="All Groups"
              info="Groups"
              disabled={!values.user}
              isDisabledOption={(option: any) => values.user && option.type !== values.user}
              options={groupOptions}
              onChange={(event: any, obj: any) => {
                if (obj) {
                  setSelectedGroupId(obj.id);
                  forceChange(obj, 'group');
                } else {
                  setSelectedGroupId('');
                  forceChange(
                    {
                      name: null,
                      id: null,
                    },
                    'group',
                  );
                }
              }}
            />
          </Flex>
        </Flex>
        <Flex alignEnd>
          <Flex flex={2}>
            <AutocompleteInput
              value={values.name}
              name="userId"
              id="name"
              placeholder="All Names"
              info="Name"
              options={userOption}
              disabled={!values.user}
              onChange={(event: any, obj: any) => {
                if (obj) {
                  forceChange(obj, 'name');
                } else {
                  forceChange(
                    {
                      name: null,
                      id: null,
                    },
                    'name',
                  );
                }
              }}
            />
            {/* {users &&
                users.map((users: any) =>
                  Object.values(users).map((user: any, index: number) => (
                    <UserListItem key={index} {...user} />
                  )),
                )} */}
          </Flex>
          <Flex>
            <CustomButton
              content="confirm"
              width="100%"
              height="56px"
              onClick={() => onSave(values)}
            />
          </Flex>
        </Flex>
      </Flex>
    </FilterModal>
  );
};

{
  /* <SelectField
  id="parents"
  info="Parents"
  name="parentId"
  value={values.parentId}
  onChange={onChange}
  placeholder="Select parent"
> */
}
