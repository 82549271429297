import React, { useState } from 'react';
import { Divider, useTheme } from '@mui/material';
import { useNotification } from '../../../hooks/useNotification';
import { AddModalContent, CustomText, ModalUser } from '../../Users/Users.style';
import { CustomButton, SmallCustomAvatar } from '../../../common';
import { Flex } from '../../../common/Containers';
import { deleteVendor } from '../../../api';
import { useNavigate } from 'react-router-dom';

export const DeleteVendorModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  vendor: any;
}> = ({ isOpen, onClose, vendor }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { notifySuccess } = useNotification();
  const handleDeleteVendor = async () => {
    setLoading(true);
    try {
      const res = await deleteVendor(vendor.id);
      onClose();
      navigate(-1);
      notifySuccess('Vendor deleted successfuly!');
      setLoading(false);
    } catch (err) {
      return err;
    }
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
          Delete Vendor
        </CustomText>
        <Divider />
        <Flex column noCenter gap={20}>
          <CustomText bold={700} fontSize={1.2}>
            Are you sure you want to delete this vendor?
          </CustomText>
          <Flex>
            <SmallCustomAvatar image={vendor?.image} />
            <Flex column noCenter gap="0px">
              <CustomText bold={700}>{vendor?.name}</CustomText>
              <CustomText>{vendor?.email}</CustomText>
            </Flex>
          </Flex>
          <Flex>
            <CustomButton
              content="No"
              width="100%"
              bgcolor={theme.color.buttonGrey}
              textcolor={theme.palette.common.black}
              onClick={onClose}
            />
            <CustomButton
              content="Yes"
              width="100%"
              loading={loading}
              onClick={handleDeleteVendor}
            />
          </Flex>
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
