import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import userReducer from './slice';
import notificationReducer from './notification';
import permissionsReducer from './permisions';
import schoolSlice from './schoolSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    permissions: permissionsReducer,
    school: schoolSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
