import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';
import { Flex } from '../../common/Containers';

import { CustomText, UserHeaderContainer, UserListItemContent } from '../Users/Users.style';

export const ExternalSupportHeaderInbox = () => (
  <UserHeaderContainer>
    <UserListItemContent flex={15} sx={{ cursor: 'pointer' }}>
      <Flex>
        <Flex flex={1}></Flex>
        <Flex flex={11}>
          <CustomText bold={700}>Name</CustomText>
        </Flex>
      </Flex>
    </UserListItemContent>
    <UserListItemContent flex={15}>
      <CustomText bold={700}>Subject</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <CustomText bold={700}>Date</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <CustomText bold={700}>Time</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={3}>
      <CustomText bold={700}>Status</CustomText>
    </UserListItemContent>
  </UserHeaderContainer>
);
