import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';

import { CustomText, UserHeaderContainer, UserListItemContent } from '../Users/Users.style';

export const GroupHeaderDetail = () => (
  <UserHeaderContainer>
    <UserListItemContent flex={15} gap={2} sx={{ cursor: 'pointer' }}>
      <CustomText fontSize={1.1} bold={700}>
        Member Name
      </CustomText>
    </UserListItemContent>

    <UserListItemContent flex={10}>
      <CustomText fontSize={1.1} bold={700}>
        Email
      </CustomText>
    </UserListItemContent>

    <UserListItemContent flex={5} />
  </UserHeaderContainer>
);
