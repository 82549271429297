import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getExternalSupport } from '../api';

export const useExternalSupport = () => {
  const [tickets, setTickets] = useState([]);

  const {
    data,
    error,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['tickets'],
    async ({ pageParam = 1 }) => {
      const res = await getExternalSupport({
        page: pageParam,
      });
      return res;
    },
    {
      cacheTime: 0,
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.tickets }));
      setTickets(Object.values(arr));
    }
  }, [data]);

  return {
    tickets,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
