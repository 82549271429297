import { Box, styled } from "@mui/material";

export const FaqListContainer = styled(Box)`
  height: 71vh;
  width: 100%;
  padding: 11px 20px;
  border-top: 2px solid ${({ theme }) => theme.color.borderGrey};
  position: relative;
`;

export const FaqItemContainer = styled(Box)<{ last: boolean; }>`
  width: 100%;
  margin: 1% 0 3% 0;
  margin-bottom: ${({ last }) => !last && 0};
  position: relative;
`;

export const FaqItemHeader = styled(Box)<{ gap?: string }>`
  max-width: 50%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '0'};
`;

export const PartBorder = styled(Box)`
  height: 2px;
  width: 44px;
  background-color: #C1C1C1;
  position: absolute;
  top: 120%;
  left: 0;
`;
