import { useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { CustomButton, ShadowInputField } from '../../common';
import { ElementaryDataContainer } from '../UserDetail/UserDetail.style';
import { ReplyContainer } from './InternalSupportTicket.style';

export interface CreateReplyProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  handleSubmit?: () => Promise<void>;
  handleExport?: () => Promise<void>;
  exportExternal?: boolean | undefined;
}

export const CreateReply: React.FC<CreateReplyProps> = ({
  value,
  onChange,
  loading,
  handleSubmit,
  handleExport,
  exportExternal,
}) => {
  const theme = useTheme();
  return (
    <ReplyContainer component="form">
      <ShadowInputField
        padding="20px"
        multiline
        name="reply"
        rows={9}
        placeholder="Reply"
        value={value}
        onChange={onChange}
      />
      <ElementaryDataContainer justifyContent="flex-end" gap="20px" margin="4% 0">
        {!exportExternal && (
          <CustomButton
            content="Export to Pupil Wallet"
            invert
            bgcolor={theme.color.buttonGrey}
            textcolor={theme.palette.common.black}
            loading={loading}
            onClick={handleExport}
          />
        )}
        <CustomButton loading={loading} content="Send Message" width="24%" onClick={handleSubmit} />
      </ElementaryDataContainer>
    </ReplyContainer>
  );
};
