import React from 'react';

export const AddStudent: React.FC<{ color?: string; size?: string }> = ({ color, size }) => {
  return (
    <svg
      width={size ? size : '37'}
      height={size ? size : '37'}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08334 18.5C3.08334 27.0144 9.98562 33.9167 18.5 33.9167C27.0144 33.9167 33.9167 27.0144 33.9167 18.5C33.9167 9.98565 27.0144 3.08337 18.5 3.08337C9.98562 3.08337 3.08334 9.98565 3.08334 18.5ZM32.375 18.5C32.375 26.163 26.163 32.375 18.5 32.375C10.8371 32.375 4.62501 26.163 4.62501 18.5C4.62501 10.8371 10.8371 4.62504 18.5 4.62504C26.163 4.62504 32.375 10.8371 32.375 18.5ZM16.9583 24.6667C16.9583 25.5181 17.6486 26.2084 18.5 26.2084C19.3514 26.2084 20.0417 25.5181 20.0417 24.6667V20.0417H24.6667C25.5181 20.0417 26.2083 19.3515 26.2083 18.5C26.2083 17.6486 25.5181 16.9584 24.6667 16.9584H20.0417V12.3334C20.0417 11.4819 19.3514 10.7917 18.5 10.7917C17.6486 10.7917 16.9583 11.4819 16.9583 12.3334V16.9584H12.3333C11.4819 16.9584 10.7917 17.6486 10.7917 18.5C10.7917 19.3515 11.4819 20.0417 12.3333 20.0417H16.9583V24.6667Z"
        fill={color ? color : '#0C84FC'}
      />
      <mask id="mask0_5340_5606" maskUnits="userSpaceOnUse" x="3" y="3" width="31" height="31">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.08334 18.5C3.08334 27.0144 9.98562 33.9167 18.5 33.9167C27.0144 33.9167 33.9167 27.0144 33.9167 18.5C33.9167 9.98565 27.0144 3.08337 18.5 3.08337C9.98562 3.08337 3.08334 9.98565 3.08334 18.5ZM32.375 18.5C32.375 26.163 26.163 32.375 18.5 32.375C10.8371 32.375 4.62501 26.163 4.62501 18.5C4.62501 10.8371 10.8371 4.62504 18.5 4.62504C26.163 4.62504 32.375 10.8371 32.375 18.5ZM16.9583 24.6667C16.9583 25.5181 17.6486 26.2084 18.5 26.2084C19.3514 26.2084 20.0417 25.5181 20.0417 24.6667V20.0417H24.6667C25.5181 20.0417 26.2083 19.3515 26.2083 18.5C26.2083 17.6486 25.5181 16.9584 24.6667 16.9584H20.0417V12.3334C20.0417 11.4819 19.3514 10.7917 18.5 10.7917C17.6486 10.7917 16.9583 11.4819 16.9583 12.3334V16.9584H12.3333C11.4819 16.9584 10.7917 17.6486 10.7917 18.5C10.7917 19.3515 11.4819 20.0417 12.3333 20.0417H16.9583V24.6667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5340_5606)"></g>
    </svg>
  );
};
