import { FormControl, InputLabel, MenuItem, Select, Tab, TextField } from '@mui/material';
import React from 'react';

import { CustomButton } from '../../common';
import { Filter } from '../../svg/Filter';
import { FiltersContainer, TabsContainer } from './Users.style';
import CloseIcon from '@mui/icons-material/Close';
import { hasValue, isEmpty } from '../../helpers';
import CloseFilter from '../../common/CloseFilter';
import { useNavigate } from 'react-router-dom';

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface UsersControlProps {
  changeValue: any;
  search: string;
  changeSearch: any;
  showModal: () => void;
  tabs: string[];
  filter: {};
  clearFilter: () => void;
  activeTab: number;
}

export const UsersControl: React.FC<UsersControlProps> = ({
  changeValue,
  search,
  changeSearch,
  showModal,
  tabs,
  filter,
  activeTab,
  clearFilter,
}) => {
  return (
    <FiltersContainer>
      <TabsContainer value={activeTab} onChange={changeValue} aria-label="basic tabs example">
        {tabs.map((item: string, index: number) => (
          <Tab key={index} label={item} {...a11yProps(index)} />
        ))}
      </TabsContainer>

      <FormControl sx={{ padding: '0 20px', width: '30%' }}>
        <TextField value={search} placeholder="Search users..." onChange={changeSearch} />
      </FormControl>

      {!hasValue(filter) ? (
        <CustomButton content={<Filter />} onClick={showModal} />
      ) : (
        <CloseFilter clearFilter={clearFilter} />
      )}
    </FiltersContainer>
  );
};
