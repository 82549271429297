import { AnalyticsCreditsContainer } from './AnalyticsCredits.style';
import { SpentCredits } from '../../../../components/Charts/SpentCredits';
import { BoughtCredit } from '../../../../components/Charts/BoughtCredit';
import { UnspentCredit } from '../../../../components/Charts/UnspentCredit';
import { CreditPieChart } from '../../../../components/Charts/CreditPieChart';

export const AnalyticsCredits = () => {
  return (
    <AnalyticsCreditsContainer>
      <SpentCredits />
      <BoughtCredit />
      <UnspentCredit />
      <CreditPieChart />
    </AnalyticsCreditsContainer>
  );
};
