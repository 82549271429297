import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getVendorProfile } from '../api';

export const useVendorProfile = (id: string) => {
  const [vendor, setVendor] = useState<any>(null);
  const [staff, setStaff] = useState([]);
  const { data, isLoading, refetch } = useQuery(['vendor', id], () => getVendorProfile(id));

  useEffect(() => {
    if (data) {
      setVendor(data.data.data.vendor);
      setStaff(data.data.data.staff);
    }
  }, [data]);

  return {
    vendor,
    isLoading,
    refetch,
    staff,
  };
};
