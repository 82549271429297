import { MenuItem, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CustomButton } from '../../common';
import {
  InputField,
  SelectField,
  MultipleAutocompleteInput,
  ColorPicker,
} from '../../common/Inputs';
import { AddModalContent, CustomText, FlexInputContainer, ModalUser } from '../Users/Users.style';
import { AddUserPhoto } from '../Users/AddUserPhoto';
import { useForm } from '../../hooks/useForm';
import { useUsers } from '../../hooks/useUsers';
import { Flex } from '../../common/Containers';
import { createGroup } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { useQueryClient } from 'react-query';

interface GroupsAddModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GroupsAddModal: React.FC<GroupsAddModalProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [userOption, setUserOptions] = useState<any[]>([]);
  const queryClient = useQueryClient();

  const { values, onChange, forceChange } = useForm<{
    image: string;
    hexColor: string;
    type: 'student' | 'parent' | 'staff' | null;
    name: string;
    members: string[];
  }>({
    image: '',
    hexColor: '',
    type: null,
    name: '',
    members: [],
  });

  const { users, tabs, setActiveTab } = useUsers(values.type);

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmit = async () => {
    if (Object.entries(values).filter((value) => !value).length > 0) {
      notifyError('You need to provide all information');
      return;
    }
    setLoading(true);
    try {
      const res = await createGroup(values);
      if (res.data.error) {
        notifyError(res.data.message);
      } else {
        queryClient.invalidateQueries(['groups'], {
          refetchActive: true,
        });
        notifySuccess(res.data.message);
        onClose();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form" ow>
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          ADD NEW GROUP
        </CustomText>

        <FlexInputContainer>
          <Flex>
            <AddUserPhoto onChange={(data: string) => forceChange(data, 'image')} />
          </Flex>

          <ColorPicker
            id="color"
            info="Group Color"
            name="color"
            value={values.hexColor}
            onChange={(e: any) => forceChange(e, 'hexColor')}
            placeholder="Select color"
          />
        </FlexInputContainer>

        <FlexInputContainer>
          <SelectField
            id="type"
            name="type"
            info="Group Type"
            value={values.type}
            onChange={(e: any) => {
              setActiveTab(tabs.indexOf(e.target.value));
              forceChange(e.target.value, 'type');
            }}
            placeholder="Select color"
          >
            {tabs.map((item: string, index: number) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </SelectField>
          <InputField
            type="text"
            id="groupName"
            info="Group Name"
            padding="4px 20px"
            placeholder="Group Name"
            name="name"
            value={values.name}
            onChange={onChange}
          />
        </FlexInputContainer>
        <Flex>
          <MultipleAutocompleteInput
            value={values.members}
            name="userId"
            id="name"
            placeholder="All Names"
            info="Name"
            options={userOption}
            disabled={!values.type}
            onChange={(e: any, obj: any) =>
              forceChange(
                obj.map((item: any) => item.uid),
                'members',
              )
            }
          />
        </Flex>

        <CustomButton
          content="SUBMIT"
          width="100%"
          type="submit"
          loading={loading}
          onClick={handleSubmit}
        />
      </AddModalContent>
    </ModalUser>
  );
};
