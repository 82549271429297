import { Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardCircle } from '../../common';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { AdminConsoleImg } from '../../svg/AdminConsoleImg';
import { HouseBlue } from '../../svg/House';
import { Store } from '../../svg/Store';
import { VendorsContainer, VendorsContent } from './Vendors.style';

export const Vendors = () => {
  const navigate = useNavigate();

  return (
    <>
      <RouteInfo />
      <VendorsContainer gap={44}>
        <VendorsContent>
          <DashboardCircle
            image={<AdminConsoleImg />}
            banner="Admin Console"
            descriptionText="Return back to the Admin Console"
            height="100%"
            onClick={() => navigate('/')}
          />
          <Divider orientation="vertical" />
          <DashboardCircle
            image={<HouseBlue />}
            banner="Permanent Vendors"
            descriptionText="Create and manage Permanent Vendors"
            invert
            height="100%"
            onClick={() => navigate(ROUTES.PERMANENT_VENDORS)}
          />
          <DashboardCircle
            image={<Store />}
            banner="Temporary Vendors"
            descriptionText="Create and manage Temporary Vendors"
            invert
            height="100%"
            onClick={() => navigate(ROUTES.TEMPORARY_VENDORS)}
          />
          {/* <DashboardCircle
            image={<VendorAnalytics />}
            banner="Vendor Analytics"
            descriptionText="View meaningful data for effective decision making"
            invert
            height="100%"
            onClick={() => navigate(ROUTES.ANALYTIC_VENDORS)}
          />
          <DashboardCircle
            image={<VendorAccounts />}
            banner="Vendor Accounts"
            descriptionText="View and download accounts for different vendors"
            invert
            height="100%"
          /> */}
        </VendorsContent>
      </VendorsContainer>
    </>
  );
};
