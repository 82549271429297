import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { FilterModal, FilterPropTypes } from '../FilterModal';
import { AutocompleteInput, DateInput, SelectField } from '../../common/Inputs';
import { Flex } from '../../common/Containers';
import { CustomButton } from '../../common';
import moment from 'moment';
import { useVendors } from '../../hooks/useVendors';
interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const SmallFillterWithVendors: React.FC<BigFilterPropTypes> = ({
  open,
  handleClose,
  onSave,
}) => {
  const { vendors } = useVendors();
  const { values, forceChange } = useForm<{
    from: number;
    to: number;
    vendor: {
      id: string | null;
      name: string | null;
    } | null;
  }>({
    from: moment().startOf('year').unix(),
    to: moment().startOf('second').unix(),
    vendor: {
      id: null,
      name: null,
    },
  });
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <DateInput
            value={moment.unix(values.from)}
            onChange={(e: any) => {
              forceChange(moment(e).unix(), 'from');
            }}
            name="from"
            id="from"
            info="From"
          />
          <DateInput
            value={moment.unix(values.to)}
            onChange={(e: any) => {
              forceChange(moment(e).unix(), 'to');
            }}
            name="to"
            id="to"
            info="To"
          />
        </Flex>
        <Flex>
          <AutocompleteInput
            value={values.vendor}
            name="vendor"
            id="vendor"
            placeholder="All vendor"
            onChange={(e: any, obj: any) => {
              if (obj?.id) {
                console.log(obj);
                forceChange(obj, 'vendor');
              } else {
                forceChange(null, 'vendor');
              }
            }}
            info="Vendor list"
            options={vendors}
          />
        </Flex>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
