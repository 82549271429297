import { Box, styled } from '@mui/material';

export const VendorsContainer = styled(Box)`
  height: 75%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VendorsContent = styled(Box)<{ height?: string; gap?: number }>`
  height: ${({ height }) => height || '50%'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
`;
