import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getPermisionGroups } from '../api';

export const usePermisionGroups = () => {
  const [groups, setGroups] = useState<any>(null);
  const { data, isLoading, refetch } = useQuery(['groups'], () => getPermisionGroups(), {
    cacheTime: 0,
  });

  useEffect(() => {
    if (data) {
      setGroups(data.data.data);
    }
  }, [data]);

  return {
    groups,
    isLoading,
    refetch,
  };
};
