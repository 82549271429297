import { useEffect, useState } from 'react';
import { Flex } from '../../common/Containers';
import moment from 'moment';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ListContainer } from '../Users/Users.style';
import { TransactionsControl } from './TransactionsControl';
import { TransactionsHeader } from './TransactionsHeader';
import { TransactionsItem, TransactionsItemSkeleton } from './TransactionsItem';
import { TransactionTotalPrice } from './TransactionTotalPrice';
import { useTransactions } from '../../hooks/useTransactions';
import { SmallFillter } from '../../components/Charts/SmallFilter';
import { FetchingNextPageButton } from '../../common';
import { CircularProgress, Stack } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const tabs: string[] = ['deposits', 'school bill', 'credits'];

const initialFilter = {
  from: 0,
  to: moment().unix(),
};
export const Transactions = () => {
  const [value, setValue] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const { ref, inView } = useInView();
  const [filter, setFilter] = useState(initialFilter);
  const { transactions, totalPrice, fetchNextPage, isFetchingNextPage, isLoading } =
    useTransactions({ type: tabs[value], ...filter });

  const onSave = (values: any) => {
    setFilter((prev) => ({
      ...prev,
      ...values,
    }));
    setFilterOpen(false);
  };

  const changeValue = (event: any, newValue: number) => setValue(newValue);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const clearFilter = () => setFilter(initialFilter);

  return (
    <>
      <RouteInfo />
      <TransactionsControl
        tabs={tabs}
        value={value}
        initialFilter={initialFilter}
        filter={filter}
        clearFilter={clearFilter}
        changeValue={changeValue}
        openFilter={() => setFilterOpen(true)}
      />
      <Flex column>
        <ListContainer>
          <TransactionsHeader />
          {isLoading &&
            [...Array(20)].map((item: any, index: number) => (
              <TransactionsItemSkeleton key={index} />
            ))}
          {transactions &&
            transactions.map((transactions: any) =>
              Object.values(transactions).map((transaction: any, index: number) => (
                <TransactionsItem key={index} {...transaction} />
              )),
            )}
          <FetchingNextPageButton onClick={() => fetchNextPage()} disabled ref={ref}>
            {isFetchingNextPage ? (
              <Stack alignItems="center" direction="row">
                Loading more... <CircularProgress size={16} />
              </Stack>
            ) : (
              'No more items'
            )}
          </FetchingNextPageButton>
        </ListContainer>
        <TransactionTotalPrice type={tabs[value]} totalPrice={totalPrice} />
      </Flex>
      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
