import { Box, styled } from '@mui/material';

export const DataMigrationContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  flex: 1;
`;

export const DataMigrationContent = styled(Box)`
  width: 35%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
