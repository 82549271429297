import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useInternalSupport } from '../../hooks/useInternalSupport';
import { ListContainer } from '../Users/Users.style';
import { InternalSupportContainer } from './InternalSupport.style';
import { InternalSupportControl } from './InternalSupportControl';
import { InternalSupportItem, InternalSupportItemSkeleton } from './InternalSupportItem';
import { InternalSupportItemHeaders } from './InternalSupportItemHeaders';
import { FetchButton } from '../../common/FetchButton';
import { InternalSuppotModal } from './InternalSuppotModal';

export const InternalSupport = () => {
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState({
    resolved: null,
    assigned: null,
  });
  const {
    tabs,
    activeTab,
    setActiveTab,
    tickets,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useInternalSupport(filter);

  const changeValue = (event: any, newValue: number) => setActiveTab(newValue);

  const onSave = (values: any) => {
    setFilter(values);
    setFilterOpen(false);
  };
  const clearFilter = () =>
    setFilter({
      resolved: null,
      assigned: null,
    });
  return (
    <>
      <RouteInfo banner="Internal Support" />
      <InternalSupportContainer>
        <InternalSupportControl
          tabs={tabs}
          value={activeTab}
          changeValue={changeValue}
          clearFilter={clearFilter}
          showModal={() => setFilterOpen(true)}
          filter={filter}
        />
        <ListContainer>
          <InternalSupportItemHeaders />
          {isLoading && [...Array(20)].map((item: any) => <InternalSupportItemSkeleton />)}
          {tickets &&
            tickets?.map((tickets: any) =>
              Object?.values(tickets).map((item: any, index: number) => (
                <InternalSupportItem
                  key={index}
                  onClick={() => navigate(`${ROUTES.INTERNAL_SUPPORT_TICKET}/${item.id}`)}
                  {...item}
                />
              )),
            )}
          {!hasNextPage && (
            <FetchButton onView={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          )}
        </ListContainer>
        <InternalSuppotModal
          open={filterOpen}
          handleClose={() => setFilterOpen(false)}
          onSave={onSave}
        />
      </InternalSupportContainer>
    </>
  );
};
