import { Box, styled } from '@mui/material';

export const UserDetailsContainer = styled(Box)<{ height?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const UserMainInfoContainer = styled(Box)<{
  width?: string;
  noResponsive?: boolean;
  margin?: string;
  noBorder?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: ${({ noBorder, theme }) => (noBorder ? 'none' : '1px solid ' + theme.color.borderColor)}};
  border-left: none;
  border-right: none;
  margin: ${({ margin }) => margin};

  @media (max-width: 768px) {
    flex-direction: ${({ noResponsive }) => (noResponsive ? 'row' : 'column')};
  }
`;

export const ElementaryDataContainer = styled(Box)<{
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  column?: boolean;
  margin?: string;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex: 0 0 ${({ width }) => width || '50%'};
  gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  margin: ${({ margin }) => margin || '0'};

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;

    button {
      flex: 0 0 44%;
    }
  }
`;

export const UserAllDataContainer = styled(Box)<{ gap?: number }>`
  width: 100%;
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  padding: 20px;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    height: auto;
    width: 90%;
    flex-direction: column;
  }
`;

export const UserDetailListContainer = styled(Box)<{ width?: string }>`
  height: 100%;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Flex = styled(Box)<{
  jcontent?: string;
  gap?: number;
  column?: boolean;
  margin?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ jcontent }) => jcontent || 'space-between'};
  flex-wrap: wrap;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  flex-direction: ${({ column }) => (column ? 'flex-column' : 'flex-row')};
  margin: ${({ margin }) => margin || '0'};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

export const StudentsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;
`;

export const ArrowBox = styled(Box)<{ rotate?: number }>`
  justify-self: flex-end;
  align-self: flex-end;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  transform: ${({ rotate }) => (rotate ? rotate : 0)}deg;
`;

export const RoleText = styled(Box)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 4px;
  text-transform: uppercase;
`;
