import React, { useEffect, useState } from 'react';
import { useUsers } from '../../hooks/useUsers';
import { AutocompleteInput } from './AutocompleteInput';

export const UserInput: React.FC<{
  value: any;
  onChange: any;
  info?: string;
  userType: 'student' | 'parent' | 'staff';
}> = ({ value, onChange, info, userType }) => {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const { users } = useUsers(userType, searchTerm);
  const [userOptions, setUserOptions] = useState<any[]>([]);

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length > 0) {
        setSearchTerm(inputValue);
      } else {
        setSearchTerm(null);
      }
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  return (
    <AutocompleteInput
      value={value}
      name="user"
      info={info || 'User Name'}
      padding="4px 20px"
      placeholder="Select"
      options={userOptions}
      onChange={onChange}
      onInputChange={(e: any) => setInputValue(e.target.value)}
    />
  );
};
