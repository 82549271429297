import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FetchButton } from '../../common/FetchButton';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useExternalSupport } from '../../hooks/useExternalSupport';
import { ListContainer } from '../Users/Users.style';

import { ExternalSupportHeaderInbox } from './ExternalSupportHeaderInbox';
import {
  ExternalSupportItemInbox,
  ExternalSupportItemInboxSkeleton,
} from './ExternalSupportItemInbox';
import { ExternalSupportSend } from './ExternalSupportSend';

export const ExternalSupport = () => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { tickets, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, isLoading } =
    useExternalSupport();

  const selectTicket = (item: any) => {
    navigate(`${ROUTES.EXTERNAL_SUPPORT_TICKET}/${item.id}`);
  };

  return (
    <>
      <RouteInfo onClick={() => setShowModal(true)} withButton />

      {showModal && (
        <ExternalSupportSend
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          refetch={refetch}
        />
      )}

      <ListContainer>
        <ExternalSupportHeaderInbox />
        {isLoading && [...Array(20)].map((item: any) => <ExternalSupportItemInboxSkeleton />)}
        {tickets &&
          tickets.map((tickets: any) =>
            Object.values(tickets).map((item: any, index: number) => (
              <ExternalSupportItemInbox key={index} {...item} onClick={() => selectTicket(item)} />
            )),
          )}
        <FetchButton
          onView={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          name="No more tickets"
        />
      </ListContainer>
    </>
  );
};
