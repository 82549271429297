import { Box, Stack, styled } from '@mui/material';

export const AnalyticLinksContainer = styled(Box)<{ gap?: string }>`
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap }) => gap || '0'};
  padding: 4px 20px;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    position: relative;
  }
`;

export const TabItem = styled(Box)<{ active: boolean; gap?: string }>`
  height: 100%;
  flex: 0 0 16%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '0'};
  padding: 14px;
  border: ${({ active }) => !active && '0.5px solid #C1C1C1'};
  border-radius: 10px;
  box-shadow: ${({ active }) => active && '0px 4px 10px rgba(0, 0, 0, 0.15)'};
  cursor: pointer;
  background-color: ${({ active, theme }) => active && theme.palette.common.white};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LinkCircle = styled(Box)<{ active: boolean }>`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active, theme }) => (active ? theme.color.darkerBlue : '#BDBDBD')};
  border-radius: 50%;

  svg {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }
`;

export const DataContainer = styled(Box)<{
  height: string;
  width: string;
  alignItems?: string;
  justifyContent?: string;
  column?: boolean;
  gap?: string;
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  gap: ${({ gap }) => gap || ''};
  padding: 18px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  margin: 2% 0;

  @media (max-width: 1024px) {
    width: 90vw;
    justify-content: space-between;

    p {
      font-size: 0.9rem;
    }
  }
`;

export const AnalyticGroupsHeader = styled(Box)`
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  position: relative;

  @media (max-width: 768px) {
    height: 35%;
    flex-direction: column;
    padding: 0;
  }
`;

export const SmCircle = styled(Box)<{ bgcolor: string }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({ bgcolor }) => bgcolor};
`;

export const GroupsLegendContainer = styled(Stack)`
  @media (max-width: 1024px) {
    flex-direction: column;

    & .MuiOutlinedInput-root {
      width: 90%;
    }
  }
`;
