import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getSchool } from '../api';

export const useSchoolProfile = () => {
  const [school, setSchool] = useState<any>(null);
  const { data, isLoading } = useQuery(['school'], () => getSchool(), { cacheTime: 0 });

  useEffect(() => {
    if (data) {
      setSchool(data.data.data);
    }
  }, [data]);

  return {
    school,
    isLoading,
  };
};
