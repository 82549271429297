import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomInput } from './Input';
import { InputFieldProps } from './Input';
import { InputWrapper } from './InputWrapper';
import Stack from '@mui/material/Stack';

interface AutocompletePropTypes extends InputFieldProps {
  isDisabledOption?: any;
  disabled?: boolean;
}

export const MultipleAutocompleteInput: React.FC<AutocompletePropTypes> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  options,
  isDisabledOption,
  disabled,
  ...rest
}) => {
  return (
    <InputWrapper width={width} alignitems={alignitems} info={info}>
      <Stack spacing={3} sx={{ width: '100%' }}>
        <Autocomplete
          disabled={disabled}
          disablePortal
          multiple
          options={options ? options : []}
          style={{ width: '100%' }}
          onChange={onChange}
          getOptionDisabled={(option) => isDisabledOption?.(option)}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <CustomInput {...params} {...rest} />}
        />
      </Stack>
    </InputWrapper>
  );
};
