import React from 'react';

import { CustomText } from '../Users/Users.style';
import {
  CheckMarkCircle,
  Circle,
  PossibleItemContainer,
  PermisionImage,
} from './Permissions.style';
import { Checkmark } from '../../svg/Checkmark';

interface PermissionPossibleProps {
  banner: string;
  selected: boolean;
  onClick: () => void;
  smImage?: string;
  image: any;
}

export const PermissionPossible: React.FC<PermissionPossibleProps> = ({
  banner,
  selected,
  onClick,
  smImage,
  image,
}) => (
  <PossibleItemContainer onClick={onClick}>
    <Circle cl={selected}>
      <PermisionImage>{React.cloneElement(image)}</PermisionImage>
      {selected && (
        <CheckMarkCircle>
          <Checkmark />
        </CheckMarkCircle>
      )}
    </Circle>
    <CustomText bold={700}>{banner}</CustomText>
  </PossibleItemContainer>
);
