import moment from 'moment';
import { Divider, Skeleton, useTheme } from '@mui/material';
import { CustomButton, InitialCircle } from '../../common';
import { Flex } from '../../common/Containers';
import { SmallCalendar } from '../../svg/SmallCalendar';
import { SmallClock } from '../../svg/SmallClock';
import { CustomText } from '../../pages/Users/Users.style';
import {
  ElementaryDataContainer,
  UserMainInfoContainer,
} from '../../pages/UserDetail/UserDetail.style';

export const VendorProfileHeader: React.FC<{
  isLoading: boolean;
  image: string;
  name: string;
  phoneNumber: number;
  email: string;
  date: number;
  openEdit: () => void;
  openDelete: () => void;
}> = ({ isLoading, image, name, date, phoneNumber, email, openEdit, openDelete }) => {
  const theme = useTheme();
  return (
    <>
      <UserMainInfoContainer noBorder>
        <ElementaryDataContainer>
          {!isLoading ? (
            <InitialCircle img={image} />
          ) : (
            <Skeleton height={123} width={123} variant="circular" />
          )}
          <Flex column noCenter gap={5}>
            {isLoading ? (
              <Skeleton height="2rem" width={200} />
            ) : (
              <CustomText fontSize={1.2} bold={700}>
                {name}
              </CustomText>
            )}
            {isLoading ? (
              <Skeleton height="2rem" width={123} />
            ) : (
              <>
                <Flex gap="9px">
                  <CustomText bold={700}>Phone:</CustomText>
                  <CustomText bold={300}>{phoneNumber}</CustomText>
                </Flex>
                <Flex gap="9px">
                  <CustomText bold={700}>Email:</CustomText>
                  <CustomText bold={300}>{email}</CustomText>
                </Flex>
              </>
            )}
          </Flex>
        </ElementaryDataContainer>
        <Flex flexEnd>
          <CustomButton content="EDIT VENDOR" width="29%" onClick={openEdit} />
          <CustomButton
            content="DELETE VENDOR"
            bgcolor={theme.color.schollBillRed}
            width="29%"
            onClick={() => openDelete()}
          />
        </Flex>
      </UserMainInfoContainer>
      <Divider />
    </>
  );
};
