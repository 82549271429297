import React from 'react';

export const House = () => (
  <svg width="63" height="55" viewBox="0 0 63 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.87488 7.25H55.1249C56.9811 7.25 58.4999 5.73125 58.4999 3.875C58.4999 2.01875 56.9811 0.5 55.1249 0.5H7.87488C6.01863 0.5 4.49988 2.01875 4.49988 3.875C4.49988 5.73125 6.01863 7.25 7.87488 7.25ZM59.0399 13.325C58.7361 11.7725 57.3524 10.625 55.7324 10.625H7.26738C5.64738 10.625 4.26363 11.7725 3.95988 13.325L0.584882 30.2C0.179882 32.2925 1.76613 34.25 3.89238 34.25H4.49988V51.125C4.49988 52.9813 6.01863 54.5 7.87488 54.5H34.8749C36.7311 54.5 38.2499 52.9813 38.2499 51.125V34.25H51.7499V51.125C51.7499 52.9813 53.2686 54.5 55.1249 54.5C56.9811 54.5 58.4999 52.9813 58.4999 51.125V34.25H59.1074C61.2336 34.25 62.8199 32.2925 62.4149 30.2L59.0399 13.325ZM31.4999 47.75H11.2499V34.25H31.4999V47.75Z"
      fill="white"
    />
  </svg>
);

export const HouseBlue = () => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71458 8.125H57.2854C59.2328 8.125 60.8262 6.29688 60.8262 4.0625C60.8262 1.82812 59.2328 0 57.2854 0H7.71458C5.76716 0 4.17381 1.82812 4.17381 4.0625C4.17381 6.29688 5.76716 8.125 7.71458 8.125ZM61.3927 15.4375C61.074 13.5688 59.6223 12.1875 57.9228 12.1875H7.07724C5.37767 12.1875 3.92596 13.5688 3.60729 15.4375L0.0665117 35.75C-0.358381 38.2688 1.30578 40.625 3.53647 40.625H4.17381V60.9375C4.17381 63.1719 5.76716 65 7.71458 65H36.0408C37.9882 65 39.5815 63.1719 39.5815 60.9375V40.625H53.7446V60.9375C53.7446 63.1719 55.338 65 57.2854 65C59.2328 65 60.8262 63.1719 60.8262 60.9375V40.625H61.4635C63.6942 40.625 65.3584 38.2688 64.9335 35.75L61.3927 15.4375ZM32.5 56.875H11.2554V40.625H32.5V56.875Z"
      fill="#0C84FC"
    />
  </svg>
);
