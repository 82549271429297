import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSchoolBillTransactions, updateSchoolBillAmount } from '../api';
import { useNotification } from './useNotification';

export const useParentSchoolBill = (id: string) => {
  const [parentSchoolBill, setParentSchoolBill] = useState<null | []>(null);
  const { notifySuccess, notifyError } = useNotification();
  const [totalAmount, setTotalAmount] = useState(0);
  const queryClient = useQueryClient();

  let { data, isLoading } = useQuery(['transactions', id], () => getSchoolBillTransactions(id));

  // Update School Bill Amount

  const updateAmount = useMutation(updateSchoolBillAmount, {
    onSuccess: (res: any) => {
      if (!res.data.error) {
        notifySuccess(res.data.message);
        queryClient.invalidateQueries(['transactions'], {
          refetchActive: true,
        });
      } else {
        notifyError(res.data.message);
        return;
      }
    },
  });

  useEffect(() => {
    if (data) {
      setParentSchoolBill(data.data.data);
      const sum: any = data?.data?.data?.reduce((accumulator: any, object: any) => {
        return accumulator + object?.schoolBill;
      }, 0);
      setTotalAmount(sum);
    }
  }, [data]);

  return {
    parentSchoolBill,
    isLoading,
    totalAmount,
    updateAmount,
  };
};
