import React from 'react';

export const WhitePenEdit = () => {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2545 2.35419L20.6669 6.76662C21.0977 7.19741 21.0977 7.89586 20.6669 8.32665L8.53274 20.4608C8.32586 20.6677 8.04528 20.7839 7.75272 20.7839H3.34029C2.73106 20.7839 2.23718 20.2901 2.23718 19.6808V15.2684C2.23718 14.9758 2.3534 14.6952 2.56028 14.4884L14.6945 2.35419C15.1253 1.9234 15.8237 1.9234 16.2545 2.35419ZM15.4745 4.69423L4.4434 15.7253V18.5777H7.2958L18.3269 7.54664L15.4745 4.69423ZM23.1962 26.2995H3.34029C2.73106 26.2995 2.23718 25.8056 2.23718 25.1964C2.23718 24.5871 2.73106 24.0933 3.34029 24.0933H23.1962C23.8055 24.0933 24.2993 24.5871 24.2993 25.1964C24.2993 25.8056 23.8055 26.2995 23.1962 26.2995Z"
        fill="white"
      />
      <mask id="mask0_5330_5818" maskUnits="userSpaceOnUse" x="2" y="2" width="23" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2545 2.35419L20.6669 6.76662C21.0977 7.19741 21.0977 7.89586 20.6669 8.32665L8.53274 20.4608C8.32586 20.6677 8.04528 20.7839 7.75272 20.7839H3.34029C2.73106 20.7839 2.23718 20.2901 2.23718 19.6808V15.2684C2.23718 14.9758 2.3534 14.6952 2.56028 14.4884L14.6945 2.35419C15.1253 1.9234 15.8237 1.9234 16.2545 2.35419ZM15.4745 4.69423L4.4434 15.7253V18.5777H7.2958L18.3269 7.54664L15.4745 4.69423ZM23.1962 26.2995H3.34029C2.73106 26.2995 2.23718 25.8056 2.23718 25.1964C2.23718 24.5871 2.73106 24.0933 3.34029 24.0933H23.1962C23.8055 24.0933 24.2993 24.5871 24.2993 25.1964C24.2993 25.8056 23.8055 26.2995 23.1962 26.2995Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5330_5818)"></g>
    </svg>
  );
};
