import { Divider, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { addVendorMember } from '../../../api';
import { CustomButton } from '../../../common';
import { Flex } from '../../../common/Containers';
import { AutocompleteInput } from '../../../common/Inputs';
import { useNotification } from '../../../hooks/useNotification';
import { useUsers } from '../../../hooks/useUsers';
import { AddModalContent, CustomText, ModalUser } from '../../Users/Users.style';

export const AddVendorStaffModal: React.FC<{
  isOpen: boolean;
  vendorId: string;
  refetch: any;
  onClose: () => void;
}> = ({ isOpen, onClose, vendorId, refetch }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const { users } = useUsers('staff');
  const { notifyError, notifySuccess } = useNotification();

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmit = async () => {
    if (!userId) {
      notifyError('Select member you want to add!');
    } else {
      setLoading(true);
      const res = await addVendorMember({ userId, vendorId });
      if (res.status === 200) {
        refetch();
        notifySuccess(res.data.message);
        setLoading(false);
        onClose();
      } else {
        notifyError(res.data.message);
        setLoading(false);
      }
    }
  };

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <Flex column gap="0px" noCenter>
          <CustomText
            fontSize={1.4}
            bold={700}
            uppercase
            color={theme.color.darkerBlue}
            margin="0 0 20px 0"
          >
            ADD NEW STAFF
          </CustomText>

          <Divider flexItem />
        </Flex>
        <AutocompleteInput
          value={userId}
          name="userId"
          id="Parent Name"
          placeholder="Staff Name"
          info="Staff Name"
          options={userOptions}
          onChange={(event: any, obj: { uid: string }, reason: any) => {
            if (obj?.uid) {
              setUserId(obj?.uid);
            } else {
              setUserId('');
            }
          }}
        />
        <CustomButton content="confirm" loading={loading} onClick={handleSubmit} />
      </AddModalContent>
    </ModalUser>
  );
};
