import { useEffect } from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { OverviewItemType, overviewSections } from './Overview.constants';
import { Left, Right, OverviewContainer, OverviewInnerContainer } from './Overview.style';
import { OverviewSectionAnalytic } from './OverviewSectionAnalytic';
import { OverviewSectionItem } from './OverviewSectionItem';
import { FilterModal } from '../../components/FilterModal';
import { useState } from 'react';
import { MonthInput, YearInput } from '../../common/Inputs';
import { FlexInputContainer } from '../Users/Users.style';
import { CustomButton } from '../../common';
import { getAnalytics } from '../../api';
import { useForm } from '../../hooks/useForm';
import moment from 'moment';
import { MailSectionItem } from './MailSectionItem';

export const Overview = () => {
  const [loadingDeposits, setLoadingDeposits] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [depositsData, setDepositsData] = useState([]);
  const [value, setValue] = useState<any>({});
  const { values, forceChange } = useForm<{
    year: number;
    month: number;
  }>({
    year: '',
    month: '',
  });
  const [filter, setFilter] = useState({
    year: '',
    month: '',
  });
  const openFilter = () => {
    setFilterOpen(true);
  };
  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const getData = async () => {
    setLoadingData(true);
    const data = await getAnalytics('overview', {});
    setValue(data.data.data);
    setLoadingData(false);
  };

  const getDeposits = async () => {
    setLoadingDeposits(true);
    const data = await getAnalytics('overview/deposits', filter);
    setDepositsData(data.data.data);
    setLoadingDeposits(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getDeposits();
  }, [filter]);

  return (
    <OverviewContainer>
      <RouteInfo />

      <OverviewInnerContainer>
        <Left gap={30}>
          {overviewSections.map((item: OverviewItemType, index: number) =>
            item.dataFromApiName !== 'mailsData' ? (
              <OverviewSectionItem
                key={index}
                {...item}
                value={item.dataFromApiName && value && value?.[item.dataFromApiName]}
              />
            ) : (
              <MailSectionItem
                key={index}
                {...item}
                totalNumber={value?.mailsData?.mailsCount}
                totalKeyName={value?.mailsData?.mailsCount}
                invert
                secondValue={value?.mailsData?.latestMail?.from?.name}
                thirdValue={value?.mailsData?.latestMail?.subject}
              />
            ),
          )}
        </Left>

        <Right>
          <OverviewSectionAnalytic
            openFilter={openFilter}
            depositsData={value?.depositsData}
            graphData={depositsData}
            loadingDeposits={loadingDeposits}
            filter={filter}
          />
        </Right>
      </OverviewInnerContainer>
      <FilterModal open={filterOpen} handleClose={() => setFilterOpen(false)}>
        <FlexInputContainer>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
            placeholder="All Years"
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            placeholder="All Month"
            info="Month"
          />
        </FlexInputContainer>
        <CustomButton content="Confirm" width="100%" onClick={() => onSave(values)} />
      </FilterModal>
    </OverviewContainer>
  );
};
