import React, { useState, useEffect } from 'react';
import { Divider, MenuItem, useTheme } from '@mui/material';
import moment from 'moment';
import { CustomButton } from '../../common';
import { isValidPhoneNumber } from 'react-phone-number-input';

import {
  InputField,
  SelectField,
  PhoneNumberInput,
  DateInput,
  AutocompleteInput,
} from '../../common/Inputs';
import { useForm } from '../../hooks/useForm';
import { UserAddType } from '../../types';
import { AddUserPhoto } from './AddUserPhoto';
import { CustomText, FlexInputContainer } from './Users.style';
import { createUser } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { Flex } from '../../common/Containers';
import { useAllParents } from '../../hooks/useAllParents';

export const AddStudent: React.FC<{
  onClose: () => void;
  refetch: any;
  loading: boolean;
  setLoading: any;
}> = ({ onClose, refetch, loading, setLoading }) => {
  const theme = useTheme();
  const [searchParent, setSearchParent] = useState(null);
  const { users } = useAllParents(searchParent);
  const [userOption, setUserOptions] = useState<any[]>([]);
  const { notifyError, notifySuccess } = useNotification();
  const { values, onChange, forceChange } = useForm<UserAddType>({
    role: 'student',
    image: null,
    firstName: '',
    lastName: '',
    schoolYear: null,
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    parentId: '',
    city: '',
    address: '',
  });

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmit = async () => {
    if (!moment(values.dateOfBirth).isValid()) {
      notifyError('Please provide valid date!');
      return;
    }
    if (
      !values.firstName ||
      !values.lastName ||
      !values.schoolYear ||
      !values.address ||
      !values.phoneNumber ||
      !values.email ||
      !values.image ||
      !values.city
    ) {
      notifyError('Please fill in all details!');
      return;
    } else {
      setLoading(true);
      if (!isValidPhoneNumber(values.phoneNumber)) {
        notifyError('Please provide valid phone number!');
        setLoading(false);
        return;
      }
      const data = { ...values };
      data.dateOfBirth = moment(values.dateOfBirth).unix();
      !data.parentId && delete data.parentId;
      try {
        const res = await createUser(data);
        if (res.data.error) {
          notifyError(res.data.message);
          setLoading(false);
          return;
        } else {
          notifySuccess(res.data.message);
        }
        setLoading(false);
        refetch();
        onClose();
      } catch (err: any) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
        Add Student
      </CustomText>
      <Divider />
      <FlexInputContainer>
        <AddUserPhoto size={7} onChange={(data: string) => forceChange(data, 'image')} />

        <SelectField
          name="schoolYear"
          value={values.schoolYear}
          info="Year"
          placeholder="Select year"
          onChange={onChange}
        >
          {[9, 10, 11, 12].map((item: number) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </SelectField>
      </FlexInputContainer>
      <FlexInputContainer>
        <AutocompleteInput
          value={values.parentId}
          name="userId"
          id="students"
          placeholder="Select Parent"
          info="Parents"
          options={userOption}
          onInputChange={(e: any) => {
            const delayDebounceFn = setTimeout(() => {
              if (e.target.value) {
                setSearchParent(e.target.value);
              } else {
                setSearchParent(null);
              }
            }, 400);

            return () => clearTimeout(delayDebounceFn);
          }}
          onChange={(event: any, obj: { uid: string }) =>
            obj?.uid && forceChange(obj.uid, 'parentId')
          }
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <InputField
          type="text"
          name="firstName"
          info="First Name"
          placeholder="First Name"
          onChange={(e: any) => onChange(e)}
          value={values.firstName}
        />
        <InputField
          type="text"
          name="lastName"
          info="Last Name"
          placeholder="Last Name"
          onChange={onChange}
          value={values.lastName}
        />
      </FlexInputContainer>
      <InputField
        type="email"
        name="email"
        info="Email"
        placeholder="Email"
        onChange={onChange}
        value={values.email}
      />

      <FlexInputContainer>
        <InputField
          type="text"
          name="city"
          info="City"
          placeholder="City Name"
          onChange={(e: any) => onChange(e)}
          value={values.city}
        />
        <PhoneNumberInput
          type="text"
          name="phoneNumber"
          info="PhoneNumber"
          placeholder="+1 992 9922"
          onChange={(value: string) => forceChange(value, 'phoneNumber')}
          value={values.phoneNumber}
        />
      </FlexInputContainer>

      <InputField
        id="address"
        info="Address"
        name="address"
        value={values.address}
        onChange={onChange}
      />

      <Flex alignEnd>
        <DateInput
          id="dateOfBirth"
          name="dateOfBirth"
          info="Date of Birth"
          onChange={(value: any) => {
            forceChange(value, 'dateOfBirth');
          }}
          value={values.dateOfBirth}
        />
        <CustomButton
          content="SUBMIT"
          onClick={handleSubmit}
          width="100%"
          height="56px"
          type="button"
          loading={loading}
        />
      </Flex>
    </>
  );
};
