import { useEffect, useState } from 'react';
import moment from 'moment';
import { EditUserWhiteContainer } from './EditUser.style';
import { Flex } from '../../common/Containers';
import { EditUserPhoto } from './EditUserPhoto';
import {
  DateInput,
  InputField,
  PhoneNumberInput,
  SelectField,
  AutocompleteInput,
} from '../../common/Inputs';
import { Divider, useTheme, MenuItem } from '@mui/material';
import { CustomButton } from '../../common';
import { useForm } from '../../hooks/useForm';
import { UserAddType } from '../../types';
import { useGroups } from '../../hooks/useGroups';
import { editUser } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { useQueryClient } from 'react-query';

export const EditStaff: React.FC<{ user: UserAddType; onClose?: () => void }> = ({
  user,
  onClose,
}) => {
  const theme = useTheme();
  const { groups } = useGroups('staff');
  const [defaultGroup, setDefaultGroup] = useState<any>();
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const { values, onChange, forceChange, changeAllValues } = useForm<UserAddType>({
    uid: '',
    image: null,
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    groupId: '',
    phoneNumber: '',
    dateOfBirth: '',
    city: '',
    address: '',
    notes: '',
    schoolYear: '',
    parentId: '',
  });

  useEffect(() => {
    const arr: any[] = [];
    groups.map((groups: any) =>
      Object.values(groups).map((group: any, index: number) => arr.push(group)),
    );
    setGroupOptions(arr);
  }, [groups]);

  useEffect(() => {
    if (user) {
      setDefaultGroup({ id: user.groups?.[0]?.id, name: user.groups?.[0]?.name });
      changeAllValues({
        uid: user.uid,
        image: { data: user.imageUrl, type: '' },
        firstName: user.name?.split(' ')[0],
        lastName: user.name?.split(' ')[1],
        role: user.role,
        email: user.email,
        groupId: user.groups?.[0]?.id,
        phoneNumber: user.phoneNumber,
        dateOfBirth: user.dateOfBirth,
        address: user.address,
        city: user.city,
        notes: user.notes,
      });
    }
  }, [user]);

  const submitEdit = async () => {
    if (
      !user.uid ||
      !values.firstName ||
      !values.lastName ||
      !values.dateOfBirth ||
      !values.phoneNumber ||
      !values.city
    ) {
      console.log(Object.values(values).filter((item: any) => !item));
      notifyError('Please fill all fields!');
      return;
    }

    setLoading(true);
    try {
      const res = await editUser({
        uid: user.uid,
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
        phoneNumber: values.phoneNumber,
        address: values.address,
        city: values.city,
        ...(values?.image?.type && {
          image: {
            type: values.image?.type,
            data: values.image?.data,
          },
        }),
        ...(values?.notes && { notes: values.notes }),
      });
      if (res.data.error) {
        notifyError(res.data.message);
        setLoading(false);
        return;
      }
      if (res) {
        queryClient.invalidateQueries(['user'], {
          refetchActive: true,
        });
        queryClient.invalidateQueries(['users', 'student'], {
          refetchActive: true,
        });
        onClose?.();
        notifySuccess('User successfully edited.');
        setLoading(false);
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <EditUserWhiteContainer>
      <Flex>
        <Flex>
          <EditUserPhoto
            onChange={(data: string) => forceChange(data, 'image')}
            userImage={values.image?.data}
          />
        </Flex>
        <Flex>
          <InputField
            type="text"
            name="firstName"
            info="First Name"
            placeholder="First Name"
            onChange={(e: any) => onChange(e)}
            value={values.firstName}
          />
        </Flex>
        <Flex>
          <InputField
            type="text"
            name="lastName"
            info="Last Name"
            placeholder="First Name"
            onChange={(e: any) => onChange(e)}
            value={values.lastName}
          />
        </Flex>
        <Flex>
          <SelectField
            id="role"
            info="Type"
            name="role"
            disabled
            value={values.role}
            onChange={onChange}
            placeholder="Select Type"
          >
            <MenuItem value={values.role}>{values.role.toUpperCase()}</MenuItem>
          </SelectField>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" flexItem />
      <Flex>
        <InputField
          type="text"
          name="email"
          info="Email"
          placeholder="First Email"
          onChange={(e: any) => onChange(e)}
          value={values.email}
          disabled
        />
        {defaultGroup && (
          <AutocompleteInput
            value={values.groupId}
            name="groupId"
            id="groupId"
            placeholder="All Groups"
            info="Groups"
            defaultValue={defaultGroup?.name && { id: defaultGroup?.id, name: defaultGroup?.name }}
            options={groupOptions}
            onChange={(event: any, obj: { id: string }) => {
              if (obj?.id) {
                forceChange(obj.id, 'groupId');
              } else {
                forceChange(null, 'groupId');
              }
            }}
          />
        )}
      </Flex>
      <Flex>
        <Flex>
          <PhoneNumberInput
            type="text"
            name="phoneNumber"
            info="Phone Number"
            placeholder="+44 1242 1242"
            onChange={(e: any) => onChange(e)}
            value={values.phoneNumber}
          />
          <InputField
            type="text"
            name="city"
            info="City"
            placeholder="Enter name..."
            onChange={(e: any) => onChange(e)}
            value={values.city}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex column noCenter>
          <Flex>
            <InputField
              type="text"
              name="address"
              info="Address"
              placeholder="First Address"
              onChange={(e: any) => onChange(e)}
              value={values.address}
            />
          </Flex>
          <Flex>
            <DateInput
              type="text"
              name="dateOfBirth"
              info="Date of Birth"
              onChange={(value: any) => forceChange(moment(value).unix(), 'dateOfBirth')}
              value={values.dateOfBirth && moment.unix(values.dateOfBirth)}
            />
          </Flex>
        </Flex>
        <Flex column noCenter>
          <InputField
            type="text"
            name="notes"
            info="Notes"
            placeholder="Enter Notes"
            multiline
            rows={5}
            onChange={(e: any) => onChange(e)}
            value={values.notes}
          />
        </Flex>
      </Flex>
      <Flex alignEnd>
        <Flex>
          <Flex></Flex>
          <Flex flex={4} flexEnd>
            <CustomButton
              content="cancel"
              bgcolor={theme.color.buttonGrey}
              textcolor={theme.palette.common.black}
              width="300px"
              height="56px"
              onClick={onClose}
            />
            <CustomButton
              content="save"
              loading={loading}
              width="300px"
              height="56px"
              onClick={submitEdit}
            />
          </Flex>
        </Flex>
      </Flex>
    </EditUserWhiteContainer>
  );
};
