import { Avatar, Box, Skeleton, useTheme } from '@mui/material';
import React from 'react';

import { CustomButton, SmallCustomAvatar, StyledAvatar } from '../../common';
import { CustomText } from '../Users/Users.style';
import { ElementaryDataContainer, UserMainInfoContainer, RoleText } from './UserDetail.style';
import { InitialCircle } from '../../common';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

export interface UserMainDetailProps {
  name: string;
  role: string;
  uid: string;
  membersAmount: number;
  groupId: string;
  onChangeSearch: (e: any) => void;
  searchMember: string;
  imageUrl?: string;
  hexColor?: string;
  onDelete?: () => void;
  isLoading?: boolean;
  onDeleteClick?: any;
  email: string;
  setEditUser: () => void;
}

export const UserMainDetail: React.FC<UserMainDetailProps> = ({
  name,
  role,
  email,
  imageUrl,
  isLoading,
  uid,
  onDeleteClick,
  setEditUser,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <UserMainInfoContainer>
      <ElementaryDataContainer gap="44px">
        {!isLoading ? (
          <Avatar alt={name} src={imageUrl} sx={{ width: 123, height: 123 }} />
        ) : (
          <Skeleton height={123} width={123} variant="circular" />
        )}
        <Box>
          {isLoading ? (
            <Skeleton height="2rem" width={200} />
          ) : (
            <CustomText fontSize={1.2} bold={700}>
              {name}
            </CustomText>
          )}
          {isLoading ? <Skeleton height="2rem" width={123} /> : <RoleText>{role}</RoleText>}
        </Box>
      </ElementaryDataContainer>
      <ElementaryDataContainer justifyContent="flex-end" gap="44px">
        <CustomButton
          content="EDIT"
          textcolor={theme.palette.common.black}
          bgcolor={theme.color.buttonGrey}
          width="29%"
          onClick={() => navigate(`../edit`)}
        />
        <CustomButton
          content="DELETE PROFILE"
          bgcolor={theme.color.schollBillRed}
          width="29%"
          onClick={() => onDeleteClick({ uid, email, name, imageUrl })}
        />
      </ElementaryDataContainer>
    </UserMainInfoContainer>
  );
};
