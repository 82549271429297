import { Box, styled, Switch, Typography } from '@mui/material';

export const MessagesAndFormContainer = styled(Box)`
  height: 90%;
  width: 100%;
  display: flex;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MessagesList = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const MessagesContainer = styled(Box)<{ bottom?: string }>`
  width: 100%;
  padding: 16px 0;
  border-bottom: ${({ bottom, theme }) => bottom && `1px solid ${theme.color.borderColor}`};
`;

export const PersonInfoContainer = styled(Box)`
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
`;

export const PersonInfoContent = styled(Box)<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
`;

export const IOSSwitch = styled(Switch)`
  height: 26px;
  width: 42px;
  padding: 0;
  border-radius: 16px;
  margin: 0 0 0 20px;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 3px 0;
    transition-duration: 300;

    &.Mui-checked {
      transform: translateX(18px);
      color: #fff;
      &.MuiSwitch-track {
        background-color: red;
        opacity: 1;
        border: 0;
      }
      & .mui-disabled + .muiswitch-track: {
        opacity: 0.5;
        background-color: red;
      }
    }
  }
`;

export const ReplyContainer = styled(Box)`
  height: 50vh;
  flex: 0 0 45%;
  padding: 35px 0 0 44px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    margin: 2% 0;
  }
`;

export const DateText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 2px;
`;
