import React from "react";

import { FaqNumber } from "../../svg/FaqNumber";
import { CustomText } from "../Users/Users.style";
import { FaqItemContainer, FaqItemHeader, PartBorder } from "./Faq.style";

interface FaqItemProps {
  questionNumber: number;
  question: string;
  answer: string;
  last: boolean;
}

export const FaqItem: React.FC<FaqItemProps> = ({
  questionNumber,
  question,
  answer,
  last,
}) => (
  <FaqItemContainer last={last}>
    <FaqItemHeader gap="16px">
      <FaqNumber num={questionNumber} />
      <CustomText fontSize={1.2} bold={700}>{question}</CustomText>
    </FaqItemHeader>
    <CustomText margin="1% 0">
      {answer}
    </CustomText>
    {last && <PartBorder />}
  </FaqItemContainer>
)
