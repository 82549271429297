import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { MuiIconContainer } from '../../components/AdminConsole/AdminConsole.style';
import { Bill } from '../../svg/Bill';
import { Deposit } from '../../svg/Deposit';
import { House } from '../../svg/House';
import { Notation } from '../../svg/Notation';
import { Profile } from '../../svg/Profile';

export interface AnalyticsLink {
  name: string;
  link: string;
  image?: React.ReactNode;
}

export const analyticLinks: AnalyticsLink[] = [
  {
    name: 'DEPOSITS',
    link: '/analytics',
    image: <Deposit />,
  },
  {
    name: 'SCHOOL BILL',
    link: '/analytics/school-bill',
    image: <Bill />,
  },
  {
    name: 'CREDITS',
    link: '/analytics/credits',
    image: <Notation />,
  },
  {
    name: 'VENDOR',
    link: '/analytics/vendor',
    image: <House />,
  },
  {
    name: 'USERS',
    link: '/analytics/users',
    image: <Profile />,
  },
  {
    name: 'GROUPS',
    link: '/analytics/groups',
    image: (
      <MuiIconContainer height={23} width={23}>
        <PeopleAltIcon />
      </MuiIconContainer>
    ),
  },
];
