import { Star, StarBorder } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setMailItem } from '../../store/slice';
import { ROUTES } from '../../constants';
import { CustomText } from '../Users/Users.style';
import { MailListItem, MailListItemContent, Marker, MuiIcon } from './Mail.style';
import moment from 'moment';
import { Flex } from '../../common/Containers';
import { CircularProgress, Skeleton } from '@mui/material';
import { favoriteMail } from '../../api';
import { useNotification } from '../../hooks/useNotification';

interface MailItemProps {
  user: any;
  subject: string;
  date: number;
  unread: boolean;
  isFavorite: boolean;
  sentType: string;
  id: string;
  body: string;
  type: string;
  invoiceId?: string;
  fileUrl?: string;
  offerResponse?: string;
  paymentOffer?: number;
}

export const MailItem: React.FC<MailItemProps> = ({
  user,
  subject,
  date,
  isFavorite,
  sentType,
  id,
  body,
  type,
  invoiceId,
  fileUrl,
  offerResponse,
  paymentOffer,
}) => {
  const [fav, setFav] = useState(!!isFavorite);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifySuccess } = useNotification();
  const [favLoading, setFavLoading] = useState(false);

  const setMailFavorite = async (e: any) => {
    e.stopPropagation();
    setFavLoading(true);
    const res = await favoriteMail(id, !fav);
    if (res.data.message) {
      notifySuccess(res.data.message);
    }
    setFavLoading(false);
    setFav((prevState) => !prevState);
  };

  return (
    <MailListItem
      cursor="pointer"
      onClick={() => {
        dispatch(
          setMailItem({
            user,
            subject,
            date,
            isFavorite,
            sentType,
            id,
            body,
            type,
            invoiceId,
            fileUrl,
            offerResponse,
            paymentOffer,
          }),
        );
        navigate(`${ROUTES.MAIL_PAGE}/${id}`);
      }}
    >
      <MailListItemContent flex={15}>
        <Flex>
          {!favLoading ? (
            <MuiIcon onClick={setMailFavorite}>{fav ? <Star /> : <StarBorder />}</MuiIcon>
          ) : (
            <CircularProgress size={20} />
          )}
          <CustomText
            onClick={() => {
              dispatch(
                setMailItem({
                  user,
                  subject,
                  date,
                  isFavorite,
                  sentType,
                  id,
                  body,
                  type,
                  invoiceId,
                  fileUrl,
                }),
              );
              navigate(`${ROUTES.MAIL_PAGE}/${id}`);
            }}
          >
            {user.name}
          </CustomText>
        </Flex>
      </MailListItemContent>
      <MailListItemContent flex={20}>
        <CustomText>{subject}</CustomText>
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <CustomText>{moment.unix(date).format('DD/MM/YYYY')}</CustomText>
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <CustomText>{moment.unix(date).format('HH:MM')}</CustomText>
      </MailListItemContent>
    </MailListItem>
  );
};

export const MailItemSkeleton = () => {
  return (
    <MailListItem cursor="pointer">
      <MailListItemContent flex={15}>
        <Flex>
          <Skeleton height={24} width={24} variant="circular" />
          <Skeleton height="1.2rem" width={300} />
        </Flex>
      </MailListItemContent>
      <MailListItemContent flex={20}>
        <Skeleton height="1.2rem" width={200} />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton height="1.2rem" width={70} />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton height="1.2rem" width={50} />
      </MailListItemContent>
    </MailListItem>
  );
};
