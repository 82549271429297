import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomText } from '../Users/Users.style';
import { analyticLinks, AnalyticsLink } from './Analytic.constants';
import { AnalyticLinksContainer, LinkCircle, TabItem } from './Analytics.style';

export const AnalyticTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <AnalyticLinksContainer gap="9px">
      {analyticLinks.map(({ name, link, image }: AnalyticsLink) => (
        <TabItem key={name} active={link === pathname} gap="9px" onClick={() => navigate(link)}>
          <LinkCircle active={link === pathname}>{image}</LinkCircle>
          <CustomText bold={700}>{name}</CustomText>
        </TabItem>
      ))}
    </AnalyticLinksContainer>
  );
};
