import { useState, useEffect } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import { getUsers } from '../api';

export const useUsers = (
  type: 'student' | 'parent' | 'staff' | null,
  searchTerm?: null | string,
  filter?: { groupId: null | string; schoolYear: null | number },
) => {
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const tabs: string[] = ['student', 'parent', 'staff'];

  const {
    data,
    error,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['users', activeTab, searchTerm, filter, selectedGroupId, type],
    async ({ pageParam = 1 }) => {
      const res = await getUsers({
        pageCount: 20,
        page: pageParam,
        role: type,
        name: searchTerm,
        ...filter,
      });
      return res;
    },
    {
      cacheTime: 0,
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.users }));
      setUsers(Object.values(arr));
    }
  }, [data]);

  return {
    users,
    isLoading,
    tabs,
    activeTab,
    setActiveTab,
    setSelectedGroupId,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
