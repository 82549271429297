import { Box, styled } from '@mui/material';
import { UserListItemContainer } from '../Users/Users.style';

export const OverviewContainer = styled(Box)`
  width: 100%;
  @media (max-width: 768px) {
    height: 150vh;
  }
`;

export const OverviewInnerContainer = styled(Box)`
  width: 100%;
  padding: 6px 20px;
  padding-bottom: 6px;
  display: grid;
  grid-template-columns: 5fr 4fr;
  overflow-y: auto;
  gap: 30px;

  @media (max-width: 768px) {
    height: 150vh;
    flex-direction: column;
    justify-content: space-between;
    gap: 100px;
  }
`;

export const Left = styled(Box)<{ gap?: number }>`
  height: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ gap }) => `${gap}px` || ''};
  position: relative;

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

export const Right = styled(Box)<{ gap?: number }>`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

export const OverviewSectionItemContainer = styled(UserListItemContainer)<{
  width?: string;
  jcontent?: string;
  gap?: number;
  padding?: string;
  margin?: string;
  blue?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  height: 100%;
  flex-direction: column;
  position: relative;
  margin: 0;
  flex: 1;
  background-color: ${({ theme, blue }) =>
    blue ? theme.color.darkerBlue : theme.palette.common.white};
  color: ${({ blue, theme }) => (blue ? theme.palette.common.white : 'black')};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const OverviewSectionItemMain = styled(Box)<{
  justifyContent?: string;
  gap?: number;
  noBorder?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${({ gap }) => `${gap}px` || ''};
  padding: 1rem 1.5rem;
  border-bottom: ${({ noBorder, theme }) =>
    noBorder ? 'none' : `1px solid ${theme.color.borderColor}`};
  position: relative;
  color: inherit;
  cursor: pointer;
`;

export const OverviewSectionItemSecondary = styled(Box)`
  width: 100%;
  padding: 24px;
`;

export const OverviewSectionItemFlex = styled(OverviewSectionItemSecondary)`
  display: flex;
  justify-content: space-between;
  padding-top: 9px;
  flex: 1;
  border-bottom: none;
`;

export const PieContainer = styled(Box)`
  position: relative;
`;

export const SmallFlex = styled(Box)<{ alignItems?: string; align?: string }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: space-between;
  flex: 0 0 46%;
  flex-direction: column;
  padding: 24px;

  p {
    text-align: ${({ align }) => align};
  }
`;

export const DepositsInfo = styled(Box)`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 1;
`;

export const Divider = styled(Box)`
  width: 1px;
  background-color: ${({ theme }) => theme.color.borderColor};
  position: relative;
`;
