import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getInternalSupport } from '../api';

export const useInternalSupport = (filter: any) => {
  const [tickets, setTickets] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const tabs: string[] = ['student', 'parent', 'vendor'];

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['tickets', activeTab, filter],
      async ({ pageParam = 1 }) => {
        const res = await getInternalSupport({
          page: pageParam,
          from: tabs[activeTab],
          ...filter,
        });
        return res;
      },
      {
        cacheTime: 0,
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.tickets }));
      setTickets(Object.values(arr));
    }
  }, [data]);

  return {
    tickets,
    isLoading,
    tabs,
    activeTab,
    setActiveTab,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
