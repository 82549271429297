import { Menu } from '@mui/icons-material';
import { Box, IconButton, Stack, Switch } from '@mui/material';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { randomIntFromInterval } from '../../../../helpers';

import { CustomText } from '../../../Users/Users.style';
import { DataContainer } from '../../Analytics.style';
import { TotalTransactionsLineHeader } from '../AnalyticTransactions/AnalyticTransactions.style';
import { data } from '../AnalyticUsers/AnalyticTotalUsers';

const itemsData = data.map((item) => ({
  ...item,
  staff: randomIntFromInterval(10, 40),
  parents: randomIntFromInterval(10, 44),
  student: randomIntFromInterval(10, 50),
}));

export const ResolvedTickets = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>
          Resolved tickets: 75
        </CustomText>
        <CustomText>2021 / All Months / All Students / All Groups</CustomText>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Switch />
        <IconButton size="large">
          <Menu />
        </IconButton>
      </Stack>
    </TotalTransactionsLineHeader>
    <ResponsiveContainer height="83%" width="100%">
      <LineChart height={401} width={771} data={itemsData}>
        <CartesianGrid vertical={false} />
        <Tooltip />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis dataKey="name" tickLine={false} tickMargin={16} />
        <Line
          type="linear"
          dataKey="student"
          stroke="#0C84FC"
          dot={{ r: 6, fill: '#0C84FC' }}
          activeDot={false}
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="parents"
          stroke="#EDBB3C"
          dot={{ r: 6, fill: '#EDBB3C' }}
          activeDot={false}
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="staff"
          stroke="#ED3C3C"
          dot={{ r: 6, fill: '#ED3C3C' }}
          activeDot={false}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  </DataContainer>
);
