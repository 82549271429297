import React from "react";
import { PaginationComponent } from "../../components/Pagination/Pagination";

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { faqs } from "./Faq.constants";
import { FaqListContainer } from "./Faq.style";
import { FaqItem } from "./FaqItem";

export const Faq = () => (
  <>
    <RouteInfo
      banner="FAQ"
      description="Frequently asked questions"
      smImage="/faq-sm.png"
      margin="1% 0"
    />
    <FaqListContainer>
      {faqs.slice(0, 4).map((faq, index, array) => (
        <FaqItem {...faq} questionNumber={index + 1} last={index !== (array.length - 1)} />
      ))}
      <PaginationComponent showItems={4} count={faqs.length} />
    </FaqListContainer>
  </>
)
