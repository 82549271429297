import { Tab } from "@mui/material";
import React from "react";
import { FiltersContainer, TabsContainer } from "../../Users/Users.style";
import { a11yProps } from "../../Users/UsersControl";
import { tabs } from './TemporaryVendors';

interface TemporaryVendorsTabsProps {
  value: number;
  changeValue: any;
}

export const TemporaryVendorsTabs: React.FC<TemporaryVendorsTabsProps> = ({
  value,
  changeValue,
}) => (
  <FiltersContainer>
    <TabsContainer width="100%" value={value} onChange={changeValue}>
      {tabs.map((item: string, index: number) => (
        <Tab key={index} label={item} {...a11yProps(index)} />
      ))}
    </TabsContainer>
  </FiltersContainer>
)
