import React, { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { getAnalytics } from '../../api';
import { useTheme } from '@mui/material';
import { SmallFillter } from './SmallFilter';
import moment from 'moment';
import { fillDates } from '../../utils/dateSeparate';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};

export const NumberOfSchoolBillAdditions = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState<{}[]>([]);
  const [totalValue, setTotalValue] = useState(0);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('school-bill/count', {
      from: filter.from,
      to: filter.to,
    });
    const value: {}[] = fillDates(filter.from, [], filter, data?.data.data.transactionsData);
    setValue(value);
    setTotalValue(data?.data.data.total);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);
  return (
    <>
      <LineCharts
        data={value}
        title={`Number of School Bill Additions: ${totalValue ? totalValue : 0}`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
        }}
        loading={loading}
        color={theme.color.schollBillRed}
        noPrefix
        noValueFormat
      />

      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
