import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack, Switch } from '@mui/material';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomText } from '../../../Users/Users.style';

import { DataContainer } from '../../Analytics.style';
import { ChartBannerContainer, TotalTransactionsLineHeader } from './AnalyticTransactions.style';

export const data = [
  {
    name: 'Jan',
    student: 90,
  },
  {
    name: 'Feb',
    student: 90,
  },
  {
    name: 'Mar',
    student: 100,
  },
  {
    name: 'Apr',
    student: 110,
  },
  {
    name: 'May',
    student: 90,
  },
  {
    name: 'Jun',
    student: 90,
  },
  {
    name: 'Jul',
    student: 90,
  },
  {
    name: 'Aug',
    student: 75,
  },
  {
    name: 'Sep',
    student: 90,
  },
  {
    name: 'Oct',
    student: 90,
  },
  {
    name: 'Nov',
    student: 90,
  },
  {
    name: 'Dec',
    student: 90,
  },
];

export const TotalTransactionsLineChart = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <ChartBannerContainer>
        <CustomText bold={700} fontSize={1.4}>
          Total transactions: 368
        </CustomText>
        <CustomText>2021 / All Months / All Students / All Groups</CustomText>
      </ChartBannerContainer>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Switch />
        <IconButton size="large">
          <MenuIcon />
        </IconButton>
      </Stack>
    </TotalTransactionsLineHeader>
    <ResponsiveContainer height="83%" width="100%">
      <LineChart height={401} width={771} data={data}>
        <CartesianGrid vertical={false} />
        <Tooltip />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis dataKey="name" tickLine={false} tickMargin={16} />
        <Line
          type="linear"
          dataKey="student"
          stroke="#0C84FC"
          dot={{ r: 6, fill: '#0C84FC' }}
          activeDot={false}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  </DataContainer>
);
