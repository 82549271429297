import { Box, Icon, styled } from '@mui/material';

export const MailListItem = styled(Box)<{ padding?: string; cursor?: string }>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: ${({ padding }) => padding || '20px 10px'};
  border-radius: 10px;
  cursor: ${({ cursor }) => cursor};
  position: relative;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Marker = styled(Box)<{ bgcolor?: boolean }>`
  height: 75%;
  width: 6px;
  border-radius: 0 6px 6px 0;
  background-color: ${({ theme, bgcolor }) => bgcolor && theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const MailListItemContent = styled(Box)<{ flex: number; margin?: string; end?: boolean }>`
  height: 100%;
  flex: ${({ flex }) => flex};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  justify-content: ${({ end }) => (!end ? 'flex-start' : 'flex-end')};

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const MuiIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.darkerBlue};
  }
`;
