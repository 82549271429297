import React from 'react';
import { CustomText, UserListItemContainer } from '../../pages/Users/Users.style';
import { Flex } from '../Containers';
import { Circle } from '../DashboardCircle/DashboardCircle.style';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { MuiIconContainer } from '../../components/AdminConsole/AdminConsole.style';
import { SmallCircle } from '../../components/RouteInfo/RouteInfo.style';
import { useTheme } from '@mui/material';

export const GroupChartTooltip: React.FC<any> = ({ active, payload, label }) => {
  const theme = useTheme();

  const getColor = (type: string) => {
    switch (type) {
      case 'student':
        return theme.color.darkerBlue;
      case 'parent':
        return theme.color.parentYellow;
      case 'staff':
        return theme.color.schollBillRed;
    }
  };
  return (
    <UserListItemContainer>
      <Flex column>
        <SmallCircle color={getColor(payload[0]?.payload.type)}>
          <MuiIconContainer height={20} width={20}>
            <PeopleAltIcon />
          </MuiIconContainer>
        </SmallCircle>
        <CustomText bold={700} uppercase>
          {payload[0]?.payload.type}
        </CustomText>
        <CustomText>{payload[0]?.payload.name}</CustomText>
      </Flex>
    </UserListItemContainer>
  );
};
