import React from 'react';

import { CustomText } from '../../pages/Users/Users.style';
import { ChartInfoContainer, DescContainer, StyledDot } from './ChartInfo.style';
import CountUp from 'react-countup';
import { Flex } from '../Containers';
import moment from 'moment';

interface ChartInfoProps {
  banner: string;
  bgcolor: string;
  amount: number;
  percentage: number;
  noBorder?: boolean;
  height?: string;
  width?: string;
  margin?: string;
  lastAdded?: number;
}

export const UserChartInfo: React.FC<ChartInfoProps> = ({
  banner,
  amount,
  percentage,
  bgcolor,
  height,
  width,
  lastAdded,
}) => (
  <ChartInfoContainer margin="2% 0">
    <DescContainer gap={20}>
      <Flex column gap="0px" noCenter>
        <Flex>
          <StyledDot height={height} width={width} bgcolor={bgcolor} />
          <CustomText fontSize={1.1} bold={700}>
            {banner}
          </CustomText>
        </Flex>
        <CustomText>
          Last Added: {lastAdded ? moment.unix(lastAdded).format('M/D/YYYY') : 'loading..'}
        </CustomText>
      </Flex>
    </DescContainer>
    <DescContainer>
      <CustomText fontSize={2} bold={700}>
        {percentage ? <CountUp end={percentage} duration={2} /> : '0'}%
      </CustomText>
    </DescContainer>
  </ChartInfoContainer>
);
