import { Divider, MenuItem, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { AttachFile, CustomButton } from '../../common';
import { Flex } from '../../common/Containers';
import { AutocompleteInput } from '../../common/Inputs';
import { InputField, SelectField } from '../../common/Inputs/Input';
import { useForm } from '../../hooks/useForm';
import { useGroups } from '../../hooks/useGroups';
import { useUsers } from '../../hooks/useUsers';
import { useVendors } from '../../hooks/useVendors';
import { AddModalContent, CustomText, FlexInputContainer, ModalUser } from '../Users/Users.style';
import { MailComposeType } from './MailType';
import { removeEmpty } from '../../utils';
import { composeMail } from '../../api';
import { ConstructionOutlined } from '@mui/icons-material';
import { useNotification } from '../../hooks/useNotification';

interface MailCreateProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MailCreate: React.FC<MailCreateProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const [loadingCompose, setLoadingCompose] = useState(false);
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [userOption, setUserOptions] = useState<any[]>([]);
  const { notifyError, notifySuccess } = useNotification();
  const [type, setType] = useState(null);
  const { users, tabs, setActiveTab } = useUsers(type);
  const { vendors } = useVendors();
  const { groups } = useGroups();

  const types: any = [
    { name: 'Student', value: 'student' },
    { name: 'Parent', value: 'parent' },
  ];

  const { values, forceChange } = useForm<MailComposeType>({
    groupId: '',
    subject: '',
    body: '',
    userId: null,
    vendorId: null,
    paymentOffer: null,
    file: null,
  });

  useEffect(() => {
    const arr: any[] = [];
    groups.map((groups: any) =>
      Object.values(groups).map((group: any, index: number) => arr.push(group)),
    );
    setGroupOptions(arr);
  }, [groups]);

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if ((!values.userId && !values.groupId) || !values.subject || !values.body) {
      notifyError('Please fill all required fields!');
      return;
    } else {
      setLoadingCompose(true);
      try {
        const data = removeEmpty(values);
        if (!!values.paymentOffer) {
          data.type = 'payRequest';
        } else {
          data.type = 'normal';
        }
        const res = await composeMail(data);
        if (res.status === 200) {
          notifySuccess(res.data.message);
          setLoadingCompose(false);
          onClose();
        } else {
          notifyError(res.data.message);
          setLoadingCompose(false);
        }
      } catch (err) {
        setLoadingCompose(false);
      }
    }
  };

  return (
    <ModalUser
      open={isOpen}
      onClose={() => {
        if (!loadingCompose) {
          onClose();
        }
      }}
    >
      <AddModalContent component="form" onSubmit={handleSubmit}>
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          COMPOSE
        </CustomText>
        <Divider />

        <FlexInputContainer>
          <SelectField
            value={type}
            name="type"
            id="type"
            placeholder="Choose type"
            onChange={(e: any) => {
              setType(e.target.value);
              setActiveTab(tabs.indexOf(e.target.value));
            }}
            info="Type"
            disabled={values.userId || values.groupId}
            options={types}
          />

          <AutocompleteInput
            value={values.groupId}
            name="groupId"
            id="group"
            placeholder="All Groups"
            info="Groups"
            disabled={!!values.userId || !type}
            options={groupOptions}
            onChange={(event: any, obj: { id: string }) => {
              if (obj?.id) {
                forceChange(obj.id, 'groupId');
              } else {
                forceChange(null, 'groupId');
              }
            }}
          />
        </FlexInputContainer>

        <FlexInputContainer>
          <AutocompleteInput
            value={values.userId}
            name="studentId"
            id="studentId"
            placeholder="Select"
            info="To *"
            options={userOption}
            disabled={!type || !!values.groupId}
            onChange={(event: any, obj: { uid: string }) => {
              if (obj?.uid) {
                forceChange(obj.uid, 'userId');
              } else {
                forceChange(null, 'userId');
              }
            }}
          />

          <AttachFile
            id="attachFile"
            info="Attach File"
            onChange={(obj: any) => {
              forceChange(obj, 'file');
            }}
          >
            <img src="/attach.png" alt="" />
            <CustomText margin="0 0 0 2%">Choose</CustomText>
          </AttachFile>
        </FlexInputContainer>

        <InputField
          id="subject"
          info="Subject *"
          placeholder="Enter here ..."
          value={values.subject}
          onChange={(e: any) => {
            forceChange(e.target.value, 'subject');
          }}
        />

        <InputField
          id="message"
          info="Message *"
          placeholder="Enter here ..."
          multiline
          rows={6}
          value={values.body}
          onChange={(e: any) => {
            forceChange(e.target.value, 'body');
          }}
        />

        <FlexInputContainer>
          <AutocompleteInput
            value={values.vendorId}
            name="vendorId"
            id="vendorId"
            placeholder="Select"
            info="Vendor"
            options={vendors}
            onChange={(event: any, obj: { id: string }) => {
              if (obj?.id) {
                forceChange(obj.id, 'vendorId');
              } else {
                forceChange(null, 'vendorId');
              }
            }}
          />

          <InputField
            id="charge"
            info="Charge"
            placeholder="Enter Price"
            type="number"
            disabled={!values.vendorId}
            value={values.paymentOffer && values.paymentOffer / 100}
            onChange={(e: any) => {
              if (e.target.value <= 0) {
                forceChange(null, 'paymentOffer');
              } else {
                forceChange(e.target.value * 100, 'paymentOffer');
              }
            }}
          />
        </FlexInputContainer>
        <Flex>
          <Flex>
            <CustomText>* Required field.</CustomText>
          </Flex>
          <Flex>
            <CustomButton loading={loadingCompose} content="SEND" type="submit" width="100%" />
          </Flex>
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
