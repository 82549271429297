import { IconButton, styled, useTheme, Box } from '@mui/material';
import React, { useState } from 'react';
import { useParentSchoolBill } from '../hooks/useParentSchoolBill';

import { CustomText, UserListItemContainer } from '../pages/Users/Users.style';
import CountUp from 'react-countup';
import { ReqPaymentModal } from '../pages/UserDetail/ReqPaymentModal';
import { Arrow } from '../svg/Arrow';
import { Flex } from './Containers';
import { reqPayment } from '../api';
import { AddStudent } from '../svg/AddStudent';
import UpdateSvg from '../svg/UpdateSvg';
import UpdateSchoolBillModal from '../pages/UserDetail/UpdateSchoolBillModal';
import { currencyFormat } from '../utils';

const UserList = styled(UserListItemContainer)`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% + 1rem);
  right: 0;
  padding: 0;
  width: fit-content;
`;

const UserItem = styled(Box)<{ first: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 4rem;

  border-top: 1px solid ${({ theme }) => theme.color.borderGrey};
  ${({ first }) => first && 'border: none'}
`;

const SchoolBillStyle = styled(UserListItemContainer)<{
  alignItems?: string;
}>`
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  position: relative;
`;

interface SchoolBillAmount {
  desc?: string;
  uid: string;
  reqPaymentModal: boolean;
  setReqPaymentModal: any;
}

export const ParentSchoolBill: React.FC<SchoolBillAmount> = ({
  desc,
  uid,
  reqPaymentModal,
  setReqPaymentModal,
}) => {
  const theme = useTheme();
  const { parentSchoolBill, totalAmount, isLoading, updateAmount } = useParentSchoolBill(uid);
  const [showUsers, setShowUsers] = useState(false);
  const [updateSchoolBillShow, setUpdateSchoolBillShow] = useState(false);

  return (
    <SchoolBillStyle padding="20px">
      <Flex column noCenter>
        <Flex spaceBetween>
          <CustomText fontSize={1.1} bold={700}>
            {desc}
          </CustomText>
          <Flex flexEnd gap="5px" pointer onClick={() => setUpdateSchoolBillShow(true)}>
            <UpdateSvg />
            <CustomText color={theme.color.darkerBlue}>Update amount</CustomText>
          </Flex>
        </Flex>
        <Flex alignEnd>
          <Flex>
            <CustomText
              fontSize={3.3}
              bold={700}
              color={totalAmount ? theme.color.schollBillRed : theme.color.depositGreen}
            >
              {totalAmount ? currencyFormat(totalAmount / 100) : 0}
            </CustomText>
          </Flex>
          <Flex noFlex flexEnd alignEnd>
            <IconButton onClick={() => setShowUsers((prev: boolean) => !prev)}>
              <Arrow />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      {showUsers && parentSchoolBill && (
        <UserList>
          {parentSchoolBill.map((item: any, index: number) => {
            console.log(item);
            return (
              <UserItem first={index === 0}>
                <CustomText>{item.studentName}</CustomText>
                <CustomText bold={700} color={theme.color.darkerBlue}>
                  £ {item.schoolBill / 100}
                </CustomText>
              </UserItem>
            );
          })}
        </UserList>
      )}
      {reqPaymentModal && (
        <ReqPaymentModal
          amount={totalAmount}
          isOpen={reqPaymentModal}
          onClose={() => setReqPaymentModal(false)}
          parentId={uid}
        />
      )}
      {updateSchoolBillShow && (
        <UpdateSchoolBillModal
          onClose={() => setUpdateSchoolBillShow(false)}
          isOpen={updateSchoolBillShow}
          amount={totalAmount}
          updateAmount={updateAmount}
          parentId={uid}
        />
      )}
    </SchoolBillStyle>
  );
};
