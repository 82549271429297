import React, { useState } from 'react';
import { getBase64 } from '../../helpers/imageToBase64';

import { AddPhotoContainer, AddImageCircle } from './Users.style';
import { Flex } from '../../common/Containers';
import { WhitePenEdit } from '../../svg/WhitePenEdit';
import { EditPhotoPenContainer } from './EditUser.style';

export const EditUserPhoto: React.FC<{ onChange?: any; userImage?: string; size?: number }> = ({
  onChange,
  userImage,
  size,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const handleChange = async (e: any) => {
    const url: any = await getBase64(e.target.files[0]);
    const formattedBinary = url.split(',')[1];
    const type = url.split(';')[0].split(':')[1];
    setImageUrl(url);
    onChange?.({
      type,
      data: formattedBinary,
    });
  };
  return (
    <Flex>
      <Flex noFlex>
        <AddPhotoContainer htmlFor="photo" gap={20}>
          <AddImageCircle size={size} userImage={imageUrl || userImage}>
            {!imageUrl && !userImage && <img src="/add.png" alt="add_photo" />}
          </AddImageCircle>
          <EditPhotoPenContainer>
            <WhitePenEdit />
          </EditPhotoPenContainer>
        </AddPhotoContainer>
      </Flex>
      <Flex>
        <input
          type="file"
          id="photo"
          style={{ display: 'none', width: '100%' }}
          onChange={handleChange}
        />
      </Flex>
    </Flex>
  );
};
