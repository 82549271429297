import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { CustomPieChart } from './PieChart';
import moment from 'moment';
import { getAnalytics } from '../../api';
import { SmallFillterWithVendors } from './SmallFilterWithVendors';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
  vendor: {
    id: null,
    name: null,
  },
};

export const VendorGrossRevenue = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    creditsCount: 0,
    schoolBillCount: 0,
  });

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('vendors/gross-revenue', {
      from: filter.from,
      to: filter.to,
      vendorId: filter.vendor.id,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);

  return (
    <>
      <CustomPieChart
        title={`Gross Revenue: ${value.creditsCount + value.schoolBillCount}`}
        loading={loading}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        percentageTotal={value.creditsCount + value.schoolBillCount}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
          vendor: filter.vendor.name,
        }}
        chartInfo={[
          {
            bgcolor: theme.color.schollBillRed,
            banner: 'SCHOOL BILL REVENUE',
            amount: value?.schoolBillCount,
          },
          {
            bgcolor: theme.color.depositDarkGreen,
            banner: 'Credits REVENUE',
            amount: value.creditsCount,
          },
        ]}
        data={[
          {
            name: 'SCHOOL BILL REVENUE',
            value: value?.schoolBillCount,
            color: theme.color.schollBillRed,
          },
          {
            name: 'Credits REVENUE',
            value: value.creditsCount,
            color: theme.color.depositDarkGreen,
          },
        ]}
      />
      <SmallFillterWithVendors
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        onSave={onSave}
      />
    </>
  );
};
