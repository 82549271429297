import React from 'react';

export const LittleGroup = () => (
  <svg width="31" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 12.0625C14.6574 12.0625 16.4062 10.3136 16.4062 8.15625C16.4062 5.99889 14.6574 4.25 12.5 4.25C10.3426 4.25 8.59375 5.99889 8.59375 8.15625C8.59375 10.3136 10.3426 12.0625 12.5 12.0625Z"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9688 19.875C17.9688 18.4246 17.3926 17.0336 16.367 16.008C15.3414 14.9824 13.9504 14.4063 12.5 14.4062C11.0496 14.4063 9.6586 14.9824 8.63301 16.008C7.60742 17.0336 7.03125 18.4246 7.03125 19.875H17.9688Z"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 8.9375C19.4158 8.9375 20.0706 8.7673 20.6522 8.44305C21.2338 8.11881 21.7228 7.65129 22.0729 7.08489C22.4229 6.51849 22.6224 5.872 22.6523 5.20683C22.6822 4.54166 22.5416 3.87987 22.2439 3.28432C21.9461 2.68877 21.501 2.17922 20.9509 1.80406C20.4008 1.4289 19.764 1.20058 19.1008 1.14079C18.4376 1.08099 17.7702 1.1917 17.1619 1.4624C16.5535 1.73311 16.0245 2.15482 15.625 2.6875"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5312 16.75H24.2188C24.2188 15.2996 23.6426 13.9086 22.617 12.883C21.5914 11.8574 20.2004 11.2813 18.75 11.2812"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 2.6875C8.97549 2.15482 8.44645 1.73311 7.83812 1.4624C7.22979 1.1917 6.56235 1.08099 5.8992 1.14079C5.23604 1.20058 4.59918 1.4289 4.04908 1.80406C3.49899 2.17922 3.05392 2.68877 2.75614 3.28432C2.45837 3.87987 2.31777 4.54166 2.3477 5.20683C2.37763 5.872 2.57709 6.51849 2.92715 7.08489C3.2772 7.65129 3.76623 8.11881 4.3478 8.44305C4.92936 8.7673 5.58415 8.9375 6.25 8.9375"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 11.2812C4.7996 11.2812 3.4086 11.8574 2.38301 12.883C1.35742 13.9086 0.78125 15.2996 0.78125 16.75H5.46875"
      stroke="#0C84FC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
