import { Divider, useTheme, Skeleton, CircularProgress } from '@mui/material';
import moment from 'moment';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Tooltip,
  YAxis,
  XAxis,
  Line,
} from 'recharts';
import { ChartInfo } from '../../common';
import { Deposit } from '../../svg/Deposit';
import { ChartTitle } from '../../common/ChartTitle';
import { Right, OverviewSectionItemContainer, OverviewSectionItemFlex } from './Overview.style';
import { Flex } from '../../common/Containers';
import { CustomText } from '../Users/Users.style';

export const OverviewSectionAnalytic: React.FC<{
  openFilter: () => void;
  depositsData: {
    todayEarnings: number;
    totalEarnings: number;
    weekEarnings: number;
    yesterdayEarnings: number;
  };
  loadingDeposits: boolean;
  graphData: any[];
  filter?: {};
}> = ({ openFilter, depositsData, loadingDeposits, graphData, filter }) => {
  const theme = useTheme();

  const data = [
    { name: 'Today', value: depositsData?.todayEarnings, color: theme.color.depositGreen },
    {
      name: 'Yesterday',
      value: depositsData?.yesterdayEarnings,
      color: theme.color.depositDarkGreen,
    },
    { name: 'Week', value: depositsData?.weekEarnings, color: theme.color.depositLightGreen },
  ];

  const calculatePes = (number: number) => {
    const total =
      depositsData?.todayEarnings + depositsData?.yesterdayEarnings + depositsData?.weekEarnings;
    return Math.floor((number * 100) / total);
  };

  return (
    <OverviewSectionItemContainer width="100%" padding="0">
      <ChartTitle
        title="Deposits"
        icon={<Deposit />}
        color={theme.color.depositGreen}
        date={null}
        onClick={openFilter}
        filter={filter}
      />

      <OverviewSectionItemFlex>
        {loadingDeposits ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.color.darkerBlue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <>
            <ResponsiveContainer width="50%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="100%"
                  dataKey="value"
                >
                  {data.map((item: any, index: number) => (
                    <Cell key={index} fill={item.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Right>
              <ChartInfo
                bgcolor={theme.color.depositGreen}
                banner="TODAY"
                amount={depositsData?.todayEarnings / 100}
                percentage={calculatePes(depositsData?.todayEarnings)}
              />
              <ChartInfo
                bgcolor={theme.color.depositDarkGreen}
                banner="YESTERDAY"
                amount={depositsData?.yesterdayEarnings / 100}
                percentage={calculatePes(depositsData?.yesterdayEarnings)}
              />
              <ChartInfo
                bgcolor={theme.color.depositLightGreen}
                banner="WEEK"
                amount={depositsData?.weekEarnings / 100}
                percentage={calculatePes(depositsData?.weekEarnings)}
                noBorder
              />
            </Right>
          </>
        )}
      </OverviewSectionItemFlex>

      <Divider flexItem variant="middle" />

      <OverviewSectionItemFlex>
        <div style={{ width: '100%', height: '100%', padding: '40px 20px 10px 0px' }}>
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={graphData}>
              <CartesianGrid vertical={false} />
              <Tooltip />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={5}
                dataKey="total"
                padding={{ bottom: 20 }}
                tickFormatter={(value) => `€${value / 100}`}
              />
              <XAxis
                dataKey="monthStartDate"
                tickLine={false}
                tickMargin={10}
                tickFormatter={(value) => `${moment.unix(value).format('MMM')}`}
                padding={{ left: 20 }}
              />
              <Line
                type="linear"
                dataKey="total"
                stroke={theme.color.depositGreen}
                dot={{ r: 6, fill: theme.color.depositGreen }}
                activeDot={false}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </OverviewSectionItemFlex>
    </OverviewSectionItemContainer>
  );
};
