import { useState, useEffect } from 'react';
import { ChartTitle } from '../../../../common/ChartTitle';
import { ChartContainer } from '../../../../components/Charts/Chart.style';
import { GroupCard, CardImage, CardIndexNumber, CardContainer } from './AnalyticGroups.style';
import { Flex } from '../../../../common/Containers';
import { MuiIconContainer } from '../../../../components/AdminConsole/AdminConsole.style';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { CustomText } from '../../../Users/Users.style';
import { getAnalytics } from '../../../../api';
import { Skeleton, useTheme } from '@mui/material';

export const TopFiveGroups = () => {
  const theme = useTheme();
  const [topFive, setTopFive] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('groups/top-five', {});
    setTopFive(data.data.data.groups);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const getColor = (type: string) => {
    switch (type) {
      case 'student':
        return theme.color.darkerBlue;
      case 'parent':
        return theme.color.parentYellow;
      case 'staff':
        return theme.color.schollBillRed;
    }
  };
  return (
    <ChartContainer>
      <Flex fullHeight column>
        <ChartTitle
          title="Top 5 Groups Ranked by Size"
          withoutButton
          description="Number of Members"
        />
        <CardContainer>
          {!loading &&
            topFive.map((item: any, index) => (
              <GroupCard>
                <CardIndexNumber>
                  <CustomText bold={700}>{index + 1}</CustomText>
                </CardIndexNumber>
                <Flex column p="30px" fullHeight gap="16px" spaceBetween>
                  <Flex column contectCenter noFlex>
                    <CardImage color={getColor(item.type)}>
                      <MuiIconContainer>
                        <PeopleAltIcon />
                      </MuiIconContainer>
                    </CardImage>
                  </Flex>
                  <Flex column p="0px" contectCenter>
                    <CustomText textCenter>{item.name}</CustomText>
                    <CustomText fontSize={1.7} bold={700}>
                      {item.membersAmount}
                    </CustomText>
                  </Flex>
                </Flex>
              </GroupCard>
            ))}
          {loading &&
            [...Array(5)].map((item, index) => (
              <GroupCard>
                <CardIndexNumber></CardIndexNumber>
                <Flex column p="30px" fullHeight gap="16px" spaceBetween>
                  <Flex column contectCenter noFlex>
                    <Skeleton variant="circular" height="120px" width="120px" />
                  </Flex>
                  <Flex column p="0px" contectCenter>
                    <Skeleton height="24px" width="120px" />
                    <Skeleton variant="circular" height="36px" width="36px" />
                  </Flex>
                </Flex>
              </GroupCard>
            ))}
        </CardContainer>
      </Flex>
    </ChartContainer>
  );
};
