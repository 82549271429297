import React from 'react';
import { CustomButton } from '../../common';
import { Flex } from '../../common/Containers';
import { SelectField } from '../../common/Inputs';
import { FilterModal } from '../../components/FilterModal';
import { FilterPropTypes } from '../../components/FilterModal';
import { useForm } from '../../hooks/useForm';
import { IOSSwitch } from '../InternalSupportTicket/InternalSupportTicket.style';

interface SupportFilter extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const InternalSuppotModal: React.FC<SupportFilter> = ({ open, handleClose, onSave }) => {
  const resolvedOptions = [
    { name: 'All Tickets', value: 'all' },
    { name: 'Unresolved', value: 'unresolved' },
    { name: 'Resolved', value: 'resolved' },
  ];
  const assignedOptions = [
    { name: 'All Tickets', value: 'all' },
    { name: 'Unassigned', value: 'unassigned' },
    { name: 'Assigned', value: 'assigned' },
  ];
  const { values, onChange } = useForm<{
    resolved: null | string;
    assigned: null | string;
  }>({
    resolved: 'all',
    assigned: 'all',
  });
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex noFlex column>
          <SelectField
            value={values.resolved}
            name="resolved"
            id="resolved"
            onChange={onChange}
            info="Status"
            options={resolvedOptions}
          />
          <SelectField
            value={values.assigned}
            name="assigned"
            id="assigned"
            onChange={onChange}
            info="Agent"
            options={assignedOptions}
          />
        </Flex>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() =>
              onSave({
                resolved: values.resolved === 'all' ? null : values.resolved === 'resolved',
                assigned: values.assigned === 'all' ? null : values.assigned === 'assigned',
              })
            }
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
