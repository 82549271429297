import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { CustomPieChart } from './PieChart';
import moment from 'moment';
import { SmallFillter } from './SmallFilter';
import { getAnalytics } from '../../api';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};

export const TemporaryVendors = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    temporaryVendorCount: 0,
    expiredVendorCount: 0,
    activeVendorCount: 0,
  });

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('vendors/temporary', {
      from: filter.from,
      to: filter.to,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);

  return (
    <>
      <CustomPieChart
        title={`Temporary Vendors: ${value.temporaryVendorCount}`}
        loading={loading}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        percentageTotal={value.temporaryVendorCount}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
        }}
        chartInfo={[
          {
            bgcolor: theme.color.schollBillRed,
            banner: 'Expired',
            amount: value?.expiredVendorCount,
          },
          {
            bgcolor: theme.color.parentYellow,
            banner: 'Expiring',
            amount: value.activeVendorCount,
          },
        ]}
        data={[
          {
            name: 'Expired',
            value: value?.expiredVendorCount,
            color: theme.color.schollBillRed,
          },
          {
            name: 'Expiring',
            value: value.activeVendorCount,
            color: theme.color.parentYellow,
          },
        ]}
      />
      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
