import { useTheme, Skeleton, CircularProgress } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Tooltip,
  YAxis,
  XAxis,
  Line,
} from 'recharts';
import { ChartTitle } from '../../common/ChartTitle';
import { ChartContainer, ChartContainerContent } from './Chart.style';
import { thousandToK } from '../../helpers';
import { Flex } from '../../common/Containers';
import { CustomText, UserListItemContainer } from '../../pages/Users/Users.style';

interface LineChartPropTypes {
  data: any[];
  color?: string;
  title: string;
  openFilter?: () => void;
  filter?: any;
  loading: boolean;
  XLineName?: string;
  YLineName?: string;
  noFormat?: boolean;
  noPrefix?: boolean;
  initialFilter?: any;
  realFilter?: any;
  noValueFormat?: boolean;
  clearFilter?: () => void;
  secondLine?: {
    name: string;
    color: string;
  };
}

export const LineCharts: React.FC<LineChartPropTypes> = ({
  data,
  color,
  title,
  openFilter,
  filter,
  loading,
  XLineName,
  YLineName,
  secondLine,
  noFormat,
  noPrefix,
  initialFilter,
  realFilter,
  clearFilter,
  noValueFormat,
}) => {
  const theme = useTheme();
  return (
    <ChartContainer>
      <ChartTitle
        title={title}
        onClick={openFilter}
        filter={filter}
        initialFilter={initialFilter}
        realFilter={realFilter}
        clearFilter={clearFilter}
      />
      <ChartContainerContent>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.color.darkerBlue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={data}>
              <CartesianGrid vertical={false} />
              <Tooltip
                content={({ active, payload, label }: any) => {
                  secondLine && console.log(payload[0]?.payload);
                  return (
                    <UserListItemContainer>
                      <Flex column>
                        <CustomText>
                          {moment.unix(payload[0]?.payload.monthStartDate).format('MMMM')}
                        </CustomText>
                        {!secondLine && (
                          <CustomText color={color ? color : theme.color.darkerBlue}>
                            {noValueFormat
                              ? payload[0]?.payload.total
                              : payload[0]?.payload.total / 100}
                          </CustomText>
                        )}
                        {secondLine && (
                          <>
                            <CustomText color={color ? color : theme.color.darkerBlue}>
                              Student: {payload[0]?.payload.studentTotal / 100}
                            </CustomText>
                            <CustomText
                              color={secondLine.color ? secondLine.color : theme.color.darkerBlue}
                            >
                              Parent: {payload[0]?.payload.parentTotal / 100}
                            </CustomText>
                          </>
                        )}
                      </Flex>
                    </UserListItemContainer>
                  );
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={5}
                dataKey={YLineName ? YLineName : 'total'}
                padding={{ bottom: 20 }}
                tickFormatter={(value) =>
                  `${!noPrefix ? '€' : ''}${noValueFormat ? value : thousandToK(value / 100)}`
                }
              />
              <XAxis
                dataKey={XLineName ? XLineName : 'monthStartDate'}
                tickLine={false}
                tickMargin={10}
                padding={{ left: 20 }}
                tickFormatter={(value) => `${noFormat ? value : moment.unix(value).format('MMM')}`}
              />
              <Line
                type="linear"
                dataKey={YLineName ? YLineName : 'total'}
                stroke={color ? color : theme.color.darkerBlue}
                dot={{ r: 6, fill: color ? color : theme.color.darkerBlue }}
                activeDot={false}
                strokeWidth={2}
              />
              {secondLine && (
                <Line
                  type="linear"
                  dataKey={secondLine.name ? secondLine.name : 'total'}
                  stroke={secondLine.color ? secondLine.color : theme.color.darkerBlue}
                  dot={{ r: 6, fill: secondLine.color ? secondLine.color : theme.color.darkerBlue }}
                  activeDot={false}
                  strokeWidth={2}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </ChartContainerContent>
    </ChartContainer>
  );
};
