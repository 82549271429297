import { Modal, Paper, styled } from '@mui/material';

export const LogoutModal = styled(Modal)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoutContent = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
  gap: 1rem;
  padding: 2rem;
`;
