import { styled } from '@mui/material';

type property = string | number;

export const CustomLinkButton = styled('a')<{
  height?: property;
  width?: property;
  bgcolor?: string;
  invert?: boolean;
  margin?: string;
  textcolor?: string;
}>`
  text-decoration: none;
  height: ${({ height }) => height && height};
  width: ${({ width }) => width && width};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  color: ${({ theme, invert, textcolor }) =>
    textcolor ? textcolor : invert ? theme.color.lightBlue : theme.palette.common.white};
  background: ${({ bgcolor, theme }) => (bgcolor ? bgcolor : theme.color.darkerBlue)};
  box-shadow: ${({ invert }) => invert && '0px 4px 10px rgba(0, 0, 0, 0.15)'};
  margin: ${({ margin }) => margin || '0'};

  &:hover {
    background: ${({ bgcolor, theme }) => (bgcolor ? bgcolor : theme.color.darkerBlue)};
  }
`;
