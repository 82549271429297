import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryWrapper } from './components/ReactQueryWrapper';
import { AppRoutes } from './routes';
import AuthContextProvider from './contexts/authContext';
import { Notification } from './components/Notification/Notification';

const App = () => (
  <ReactQueryWrapper>
    <BrowserRouter>
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </BrowserRouter>
    <Notification />
  </ReactQueryWrapper>
);

export default App;
