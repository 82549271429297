import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from 'react-query';
import { getUserTransactions } from '../api';

export const useUserTransactions = (params: any) => {
  const [transactions, setTransactions] = useState<{}[]>([{}]);
  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['transactions', params],
      async ({ pageParam = 1 }) => {
        const res = await getUserTransactions({
          pageCount: 20,
          page: pageParam,
          ...params,
        });
        return res;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.transactions }));
      setTransactions(Object.values(arr));
    }
  }, [data]);

  return {
    transactions,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
