import React from 'react';

export const PermisionPlus = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33301 14.0007C2.33301 20.444 7.55635 25.6673 13.9997 25.6673C20.443 25.6673 25.6663 20.444 25.6663 14.0007C25.6663 7.55733 20.443 2.33398 13.9997 2.33398C7.55635 2.33398 2.33301 7.55733 2.33301 14.0007ZM24.4997 14.0007C24.4997 19.7996 19.7987 24.5007 13.9997 24.5007C8.20068 24.5007 3.49967 19.7996 3.49967 14.0007C3.49967 8.20166 8.20068 3.50065 13.9997 3.50065C19.7987 3.50065 24.4997 8.20166 24.4997 14.0007ZM12.833 18.6673C12.833 19.3117 13.3553 19.834 13.9997 19.834C14.644 19.834 15.1663 19.3117 15.1663 18.6673V15.1673H18.6663C19.3107 15.1673 19.833 14.645 19.833 14.0007C19.833 13.3563 19.3107 12.834 18.6663 12.834H15.1663V9.33399C15.1663 8.68965 14.644 8.16732 13.9997 8.16732C13.3553 8.16732 12.833 8.68965 12.833 9.33399V12.834H9.33301C8.68868 12.834 8.16634 13.3563 8.16634 14.0007C8.16634 14.645 8.68868 15.1673 9.33301 15.1673H12.833V18.6673Z"
        fill="#0C84FC"
      />
      <mask id="mask0_1525_3484" maskUnits="userSpaceOnUse" x="2" y="2" width="24" height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33301 14.0007C2.33301 20.444 7.55635 25.6673 13.9997 25.6673C20.443 25.6673 25.6663 20.444 25.6663 14.0007C25.6663 7.55733 20.443 2.33398 13.9997 2.33398C7.55635 2.33398 2.33301 7.55733 2.33301 14.0007ZM24.4997 14.0007C24.4997 19.7996 19.7987 24.5007 13.9997 24.5007C8.20068 24.5007 3.49967 19.7996 3.49967 14.0007C3.49967 8.20166 8.20068 3.50065 13.9997 3.50065C19.7987 3.50065 24.4997 8.20166 24.4997 14.0007ZM12.833 18.6673C12.833 19.3117 13.3553 19.834 13.9997 19.834C14.644 19.834 15.1663 19.3117 15.1663 18.6673V15.1673H18.6663C19.3107 15.1673 19.833 14.645 19.833 14.0007C19.833 13.3563 19.3107 12.834 18.6663 12.834H15.1663V9.33399C15.1663 8.68965 14.644 8.16732 13.9997 8.16732C13.3553 8.16732 12.833 8.68965 12.833 9.33399V12.834H9.33301C8.68868 12.834 8.16634 13.3563 8.16634 14.0007C8.16634 14.645 8.68868 15.1673 9.33301 15.1673H12.833V18.6673Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1525_3484)"></g>
    </svg>
  );
};
