import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';

import { CustomText, UserHeaderContainer, UserListItemContent } from '../Users/Users.style';

export const TransactionsHeader = () => (
  <UserHeaderContainer>
    <UserListItemContent flex={15} gap={2} sx={{ cursor: 'pointer' }}>
      <CustomText bold={700}>Name</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={15}>
      <CustomText bold={700}>Type</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <CustomText bold={700}>Date</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <CustomText bold={700}>Time</CustomText>
    </UserListItemContent>
    <UserListItemContent flex={3} jcontent="flex-end">
      <CustomText bold={700}>Amount</CustomText>
    </UserListItemContent>
  </UserHeaderContainer>
);
