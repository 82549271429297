import { Box, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton, InitialCircle } from '../../common';
import { links } from '../../components/AdminConsole/AdminConsole.constants';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';
import { PermissionPossible } from './PermissionPossible';
import { GroupPermissionsInfo, PossiblePermissionsContainer } from './Permissions.style';
import { setPermissions } from '../../api';
import { useNotification } from '../../hooks/useNotification';

interface AddPermissionProps {
  isOpen: boolean;
  onClose: () => void;
  group: any;
  refetch: any;
}

export const AddPermission: React.FC<AddPermissionProps> = ({
  isOpen,
  onClose,
  group,
  refetch,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();

  const [items, setItems] = useState(
    links.map((link: any) => ({
      ...link,
      selected: group?.permissions?.some((permission: any) => permission.id === link.id),
    })),
  );

  const addPermission = (unit: number) => {
    setItems(
      items.map((item: any, index: number) => {
        if (index === unit) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      }),
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const permissionIds: any[] = [];
    items
      .filter((item: any) => item.selected)
      .map((selecterItem: any) => permissionIds.push(selecterItem.id));
    const res = await setPermissions({ groupId: group.id, permissionIds });
    if (res.status === 200) {
      notifySuccess(res.data.message);
      refetch();
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
      notifyError(res.data.message);
    }
  };

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          ADD PERMISSSIONS
        </CustomText>
        <GroupPermissionsInfo gap="20px">
          <InitialCircle img={group.image} />
          <Box>
            <CustomText fontSize={1.6} bold={700}>
              {group.name}
            </CustomText>
            <CustomText bold={300}>PERMISSION NUMBER: {group?.permissions?.length}</CustomText>
          </Box>
        </GroupPermissionsInfo>
        <PossiblePermissionsContainer>
          {items.map((item: any, index: number) => (
            <PermissionPossible key={index} onClick={() => addPermission(index)} {...item} />
          ))}
        </PossiblePermissionsContainer>
        <CustomButton
          content="SAVE"
          width="100%"
          loading={loading}
          type="submit"
          onClick={handleSubmit}
        />
      </AddModalContent>
    </ModalUser>
  );
};
