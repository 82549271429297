import { styled, Box } from '@mui/material';

export const AnalyticVendorContainer = styled(Box)`
  display: grid;
  height: 120vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
`;

export const MiddleRow = styled(Box)`
  grid-column: 1 / 3;
`;
