import React, { useState } from 'react';
import { Divider, useTheme } from '@mui/material';
import { useNotification } from '../../hooks/useNotification';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';
import { CustomButton, SmallCustomAvatar } from '../../common';
import { Flex } from '../../common/Containers';
import { removeGroupMember } from '../../api';
import { useQueryClient } from 'react-query';

export const RemoveGroupMember: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  staff: any;
  groupId: string;
}> = ({ isOpen, onClose, staff, groupId }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotification();
  const handleDeleteVendor = async () => {
    setLoading(true);
    const res = await removeGroupMember({ userId: staff.uid, groupId });
    if (res.status === 200) {
      queryClient.invalidateQueries(['groups'], {
        refetchActive: true,
      });
      onClose();
      notifySuccess('Member removed!');
      setLoading(false);
    } else {
      notifyError(res.data.message);
      setLoading(false);
    }
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
          Remove Member
        </CustomText>
        <Divider />
        <Flex column noCenter gap={20}>
          <CustomText bold={700} fontSize={1.2}>
            Are you sure you want to remove this member?
          </CustomText>
          <Flex>
            <SmallCustomAvatar image={staff?.imageUrl} />
            <Flex column noCenter gap="0px">
              <CustomText bold={700}>{staff?.name}</CustomText>
              <CustomText>{staff?.email}</CustomText>
            </Flex>
          </Flex>
          <Flex>
            <CustomButton
              content="No"
              width="100%"
              bgcolor={theme.color.buttonGrey}
              textcolor={theme.palette.common.black}
              onClick={onClose}
            />
            <CustomButton
              content="Yes"
              width="100%"
              loading={loading}
              onClick={handleDeleteVendor}
            />
          </Flex>
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
