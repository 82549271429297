import React from 'react';
import { styled, useTheme, Box } from '@mui/material';
import { Flex } from '../../common/Containers';
import { CustomText, UserListItemContainer, UserListItemContent } from '../Users/Users.style';

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
  padding: 0 20px 0 20px;
  /* background-color: ${({ theme }) => theme.color.lightGrey}; */
  background-color: transparent;
`;

const TransactionTotalPriceContainer = styled(UserListItemContainer)<{
  bgcolor: string;
  color: string;
}>`
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
  transition: all 0.5s ease;
  padding-bottom: 2rem;
  margin-bottom: -1rem;
  :hover {
    padding-bottom: 3rem;
  }
`;

export const TransactionTotalPrice: React.FC<{ type: string; totalPrice: number }> = ({
  type,
  totalPrice,
}) => {
  const theme = useTheme();
  const getColor = (type: string) => {
    switch (type) {
      case 'deposits':
        return theme.color.depositGreen;
      case 'school bill':
        return theme.color.schollBillRed;
      case 'schoolBill':
        return theme.color.schollBillRed;
      default:
        return theme.color.darkerBlue;
    }
  };
  return (
    <Container>
      <TransactionTotalPriceContainer bgcolor={getColor(type)} color={theme.palette.common.white}>
        <Flex>
          <Flex>
            <CustomText bold={700} uppercase color={theme.palette.common.white}>
              {type}
            </CustomText>
          </Flex>
          <Flex flexEnd>
            <CustomText color={theme.palette.common.white}>Total Amount:</CustomText>
            <CustomText bold={700} color={theme.palette.common.white}>
              £{totalPrice ? totalPrice / 100 : 0}
            </CustomText>
          </Flex>
        </Flex>
      </TransactionTotalPriceContainer>
    </Container>
  );
};
