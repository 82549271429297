import { Star, StarBorder } from '@mui/icons-material';
import { Box, FormControlLabel, useTheme } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';

import { StyledAvatar } from '../../common';
import { Flex } from '../../common/Containers';
import { IOSSwitch } from '../../common/CustomSwitch';
import { DownloadLink } from '../../common/DownloadLink';
import { SUPPORT_DATE } from '../../constants';
import { MailDownload } from '../../svg/MailDownload';
import {
  MessagesContainer,
  PersonInfoContainer,
  PersonInfoContent,
  DateText,
} from '../InternalSupportTicket/InternalSupportTicket.style';
import { MuiIcon } from '../Mail/Mail.style';
import { CustomText } from '../Users/Users.style';

interface MailMessageProps {
  user: any;
  email: string;
  subject: string;
  date: number;
  isFavorite: boolean;
  sentType: string;
  body?: string;
  fileUrl?: string;
}

export const MailMessage: React.FC<MailMessageProps> = ({
  user,
  email,
  subject,
  date,
  sentType,
  isFavorite,
  body,
  fileUrl,
}) => {
  const theme = useTheme();
  return (
    <MessagesContainer bottom="ok">
      <PersonInfoContainer>
        <PersonInfoContent gap={19}>
          <MuiIcon>{isFavorite ? <Star /> : <StarBorder />}</MuiIcon>
          <StyledAvatar height={82} width={82} />
          <Box>
            <CustomText fontSize={1.2} bold={700}>
              {sentType === 'inbox' ? 'FROM' : 'TO'}: {user.name}
            </CustomText>
            <CustomText>{email}</CustomText>
          </Box>
        </PersonInfoContent>

        <Flex column alignEnd>
          {fileUrl && (
            <DownloadLink link={fileUrl}>
              <Flex flexEnd gap="5px" pointer noFull>
                <MailDownload />
                <CustomText color={theme.color.darkerBlue}>Download Attachment</CustomText>
              </Flex>
            </DownloadLink>
          )}
          <DateText>{moment.unix(date).format('DD.MM.YYYY, HH:MM')}</DateText>
        </Flex>
      </PersonInfoContainer>

      <CustomText bold={700} margin="2% 0">
        {subject}
      </CustomText>

      <CustomText bold={300}>{body}</CustomText>
    </MessagesContainer>
  );
};
