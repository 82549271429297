import React from 'react';

export const UserDetailMail = () => (
  <svg width="31" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.02148 0.414062H20.9785C22.0954 0.414062 23 1.32427 23 2.43555V14.5645C23 15.6823 22.0888 16.5859 20.9785 16.5859H2.02148C0.913936 16.5859 0 15.6862 0 14.5645V2.43555C0 1.32625 0.900998 0.414062 2.02148 0.414062ZM2.30458 1.76172C2.71759 2.17253 9.82518 9.24269 10.0706 9.4868C10.4524 9.86864 10.96 10.0789 11.5 10.0789C12.04 10.0789 12.5476 9.86859 12.9307 9.48554C13.0957 9.32135 20.1249 2.32926 20.6954 1.76172H2.30458ZM21.6523 14.2902V2.71069L15.8288 8.50359L21.6523 14.2902ZM20.6946 15.2383L14.8733 9.45401L13.8823 10.4397C13.246 11.0761 12.3999 11.4265 11.5 11.4265C10.6001 11.4265 9.75402 11.0761 9.11892 10.441L8.12673 9.45401L2.30544 15.2383H20.6946ZM1.34766 14.2902L7.17124 8.50359L1.34766 2.71069V14.2902Z"
      fill="#0C84FC"
    />
  </svg>
);
