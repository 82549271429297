import { useState, useEffect } from 'react';
import { Flex } from '../../common/Containers';
import { ChartContainer, ChartContainerContent } from './Chart.style';
import { ChartTitle } from '../../common/ChartTitle';
import { getAnalytics } from '../../api';
import {
  AvatarCard,
  AvatarCardContainer,
} from '../../pages/Analytics/pages/AnalyticSchoolBill/AnalyticSchoolBill.style';
import { Skeleton } from '@mui/material';
import { SmallFillter } from './SmallFilter';
import { CustomText } from '../../pages/Users/Users.style';
import { SmallCustomAvatar } from '../../common';
import moment from 'moment';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};
export const SchoolBillMostAdded = () => {
  const [value, setValue] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const getData = async () => {
    const data = await getAnalytics('school-bill/most-added', filter);
    setValue(data.data.data);
    // setValue(data);
  };
  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);
  return (
    <Flex column>
      <Flex noFlex>
        <ChartContainer>
          <ChartTitle
            title="Most Added to School Bill"
            initialFilter={initialFilter}
            realFilter={filter}
            clearFilter={clearFilter}
            filter={{
              from: moment.unix(filter.from).format('MMM/DD/YYYY'),
              to: moment.unix(filter.to).format('MMM/DD/YYYY'),
            }}
            onClick={() => setFilterOpen(true)}
          />
          <ChartContainerContent>
            <AvatarCardContainer>
              {value?.length > 1
                ? value.map((item: any) => (
                    <AvatarCard>
                      <Flex>
                        <SmallCustomAvatar image={item.user.imageUrl} />
                        <Flex>
                          <Flex column noCenter gap="0px">
                            <CustomText>{item.user.name}</CustomText>
                            <CustomText bold={700}>
                              {item.totalCount} / Total: £{item.totalPrice / 100}
                            </CustomText>
                          </Flex>
                        </Flex>
                      </Flex>
                    </AvatarCard>
                  ))
                : [...Array(5)].map((item) => (
                    <AvatarCard>
                      <Flex>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Flex>
                          <Flex column noCenter gap="1px">
                            <Skeleton width="40%" height="24px" />
                            <Skeleton width="80%" height="24px" />
                          </Flex>
                        </Flex>
                      </Flex>
                    </AvatarCard>
                  ))}
            </AvatarCardContainer>
            <SmallFillter
              open={filterOpen}
              handleClose={() => setFilterOpen(false)}
              onSave={onSave}
            />
          </ChartContainerContent>
        </ChartContainer>
      </Flex>
      <Flex></Flex>
    </Flex>
  );
};
