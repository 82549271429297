import { Menu } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { ChartInfo, StyledAvatar } from '../../../../common';
import { CustomText } from '../../../Users/Users.style';
import { DataContainer } from '../../Analytics.style';
import { ChartBannerContainer, TotalTransactionsLineHeader } from './AnalyticTransactions.style';

const data = [
  {
    name: 'Credit Transactions',
    value: 43,
    color: '#4BFE2D',
  },
  {
    name: 'School Transactions',
    value: 67,
    color: '#FF7676',
  },
];

export const TotalTransactionsPie = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <ChartBannerContainer>
        <CustomText bold={700} fontSize={1.4}>
          Total transactions: 368
        </CustomText>
        <CustomText>2021 / All Months / All Students / All Groups</CustomText>
      </ChartBannerContainer>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <IconButton size="large">
          <Menu />
        </IconButton>
      </Stack>
    </TotalTransactionsLineHeader>

    <Stack height="81%" width="100%" direction="row" alignItems="center">
      <ResponsiveContainer height="81%" width="50%">
        <PieChart height={400} width={771}>
          <Pie data={data} cx="44%" cy="50%" dataKey="value" outerRadius="95%" labelLine={false}>
            {data.map((item, index) => (
              <Cell key={index} fill={item.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Stack spacing={4}>
        <Stack direction="row" spacing={2}>
          <StyledAvatar height={55} width={55} />
          <ChartBannerContainer>
            <CustomText fontSize={1.4} bold={700}>
              Mark Smith
            </CustomText>
            <CustomText bold={300}>STUDENT</CustomText>
          </ChartBannerContainer>
        </Stack>
        {data.map((item, index) => (
          <ChartInfo
            bgcolor={item.color}
            banner={item.name}
            amount={item.value}
            percentage={44}
            noBorder={data.length - 1 === index}
          />
        ))}
      </Stack>
    </Stack>

    <CustomText fontSize={1.2} bold={700} sx={{ textAlign: 'center' }}>
      September 2021
    </CustomText>
  </DataContainer>
);
