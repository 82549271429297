import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useMails } from '../../hooks/useMails';
import { CustomText, ListContainer, UsersContainer } from '../Users/Users.style';
import { MailControl } from './MailControl';
import { MailCreate } from './MailCreate';
import { MailItem, MailItemSkeleton } from './MailItem';
import { MailListHeader } from './MailListHeader';

export const Mail = () => {
  const [showModal, setShowModal] = useState(false);
  const params: any = useParams();
  const navigate = useNavigate();
  const { mails, setActiveTab, tabs, activeTab, isLoading } = useMails(params.type);

  const changeValue = (event: any, newValue: number) => {
    navigate(`/mail/${tabs[newValue]}`);
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (!tabs.includes(params.type)) {
      navigate('/mail/inbox');
    }
  }, []);

  useEffect(() => {
    setActiveTab(tabs.indexOf(params.type));
  }, [params]);

  return (
    <UsersContainer>
      <RouteInfo margin="1% 0" onClick={() => setShowModal(true)} withButton />
      {showModal && <MailCreate isOpen={showModal} onClose={() => setShowModal(false)} />}
      <MailControl changeValue={changeValue} tabs={tabs} activeTab={activeTab} />
      <ListContainer>
        <MailListHeader />
        {isLoading && [...Array(20)].map((item: any) => <MailItemSkeleton />)}
        {mails.length < 1 && <CustomText>Currently there are no mails</CustomText>}
        {mails &&
          mails.map((mails: any) =>
            Object.values(mails).map((mail: any, index: number) => (
              <MailItem
                key={mail.id}
                {...mail}
                user={mail.sentType === 'inbox' ? mail.from : mail.to}
              />
            )),
          )}
      </ListContainer>
    </UsersContainer>
  );
};
