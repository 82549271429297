import { useState, useEffect } from 'react';
import { ChartContainer } from '../../../../components/Charts/Chart.style';
import { AnalyticGroupsContainer } from './AnalyticGroups.style';
import { Flex } from '../../../../common/Containers';
import { CustomText } from '../../../Users/Users.style';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Right } from '../../../Overview/Overview.style';
import { useTheme } from '@mui/material';
import { TopFiveGroups } from './TopFiveGroups';
import { getAnalytics } from '../../../../api';
import { groupByType } from '../../../../utils';

export const AnalyticGroups = () => {
  const theme = useTheme();
  const [totalGroupCount, setTotalGroupCount] = useState(0);
  const [value, setValue] = useState({
    staff: [],
    parent: [],
    student: [],
  });

  const getData = async () => {
    // setLoading(true);
    const data = await getAnalytics('groups', {
      order: 'ASC',
    });
    setTotalGroupCount(data.data.data.groups.length);
    setValue(groupByType(data.data.data.groups));
    // setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const staff = [
    { name: 'STAFF', value: value?.staff?.length, color: theme.color.schollBillRed },
    {
      name: 'TOTAL COUNT',
      value: totalGroupCount - value?.staff?.length,
      color: theme.color.borderGrey,
    },
  ];
  const parent = [
    { name: 'PARENT', value: value?.parent?.length, color: theme.color.parentYellow },
    {
      name: 'TOTAL COUNT',
      value: totalGroupCount - value?.parent?.length,
      color: theme.color.borderGrey,
    },
  ];
  const student = [
    { name: 'STUDENT', value: value?.student?.length || 0, color: theme.color.darkerBlue },
    {
      name: 'TOTAL COUNT',
      value: totalGroupCount - value?.student?.length || 0,
      color: theme.color.borderGrey,
    },
  ];

  const calculatePes = (number: number) => {
    const total = totalGroupCount;
    return Math.floor((number * 100) / total);
  };
  return (
    <AnalyticGroupsContainer>
      <Flex flex={3}>
        <TopFiveGroups />
      </Flex>
      <Flex flex={3}>
        <ChartContainer>
          <ResponsiveContainer height="100%" width="100%">
            <Flex fullHeight>
              <Flex p={20} fullHeight>
                <ResponsiveContainer width="50%" height="100%">
                  <PieChart>
                    <Pie
                      data={student}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="100%"
                      dataKey="value"
                    >
                      {student.map((item: any, index: number) => (
                        <Cell key={index} fill={item.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Right>
                  <Flex column fullHeight contectCenter p={20} noCenter>
                    <CustomText bold={700} uppercase>
                      Student Groups
                    </CustomText>
                    <Flex noFlex gap="0px">
                      <CustomText fontSize={2}>{value?.student?.length}/ </CustomText>
                      <CustomText fontSize={2} bold={700}>
                        {calculatePes(value?.student?.length)}%
                      </CustomText>
                    </Flex>
                    <CustomText bold={700}>Total: {totalGroupCount}</CustomText>
                  </Flex>
                </Right>
              </Flex>
            </Flex>
          </ResponsiveContainer>
        </ChartContainer>
        <ChartContainer>
          <ResponsiveContainer height="100%" width="100%">
            <Flex fullHeight>
              <Flex p={20} fullHeight>
                <ResponsiveContainer width="50%" height="100%">
                  <PieChart>
                    <Pie
                      data={parent}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="100%"
                      dataKey="value"
                    >
                      {parent.map((item: any, index: number) => (
                        <Cell key={index} fill={item.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Right>
                  <Flex column fullHeight contectCenter p={20} noCenter>
                    <CustomText bold={700} uppercase>
                      Parent Groups
                    </CustomText>
                    <Flex noFlex gap="0px">
                      <CustomText fontSize={2}>{value?.parent?.length}/ </CustomText>
                      <CustomText fontSize={2} bold={700}>
                        {calculatePes(value?.parent?.length)}%
                      </CustomText>
                    </Flex>
                    <CustomText bold={700}>Total: {totalGroupCount}</CustomText>
                  </Flex>
                </Right>
              </Flex>
            </Flex>
          </ResponsiveContainer>
        </ChartContainer>
        <ChartContainer>
          <ResponsiveContainer height="100%" width="100%">
            <Flex fullHeight>
              <Flex p={20} fullHeight>
                <ResponsiveContainer width="50%" height="100%">
                  <PieChart>
                    <Pie
                      data={staff}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="100%"
                      dataKey="value"
                    >
                      {staff.map((item: any, index: number) => (
                        <Cell key={index} fill={item.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Right>
                  <Flex column fullHeight contectCenter p={20} noCenter>
                    <CustomText bold={700} uppercase>
                      Staff Groups
                    </CustomText>
                    <Flex noFlex gap="0px">
                      <CustomText fontSize={2}>{value?.staff?.length}/ </CustomText>
                      <CustomText fontSize={2} bold={700}>
                        {calculatePes(value?.staff?.length)}%
                      </CustomText>
                    </Flex>
                    <CustomText bold={700}>Total: {totalGroupCount}</CustomText>
                  </Flex>
                </Right>
              </Flex>
            </Flex>
          </ResponsiveContainer>
        </ChartContainer>
      </Flex>
      <Flex></Flex>
    </AnalyticGroupsContainer>
  );
};
