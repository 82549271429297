import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SmallCustomAvatar } from '../../common';
import { DATE_FORMAT, ROUTES } from '../../constants';
import { UserDel, UserPen } from '../../svg/UserPen';
import {
  CustomText,
  UserListButtons,
  UserListItemContainer,
  UserListItemContent,
} from '../Users/Users.style';

interface GroupListItemDetailProps {
  id: number | string;
  name: string;
  imageUrl: string;
  dateOfBirth: Date | number;
  email: string;
  year: number;
  group: string;
  image?: string;
  uid?: string;
  onDeleteClick: any;
  type: string;
}

export const GroupListItemDetail: React.FC<GroupListItemDetailProps> = ({
  id,
  imageUrl,
  name,
  email,
  uid,
  onDeleteClick,
  type,
}) => {
  const theme = useTheme();
  const [hoveredDel, setHoveredDel] = useState(false);
  const navigate = useNavigate();

  return (
    <UserListItemContainer>
      <UserListItemContent
        flex={15}
        gap={2}
        onClick={() => navigate(`/users/${type}/${uid}`)}
        sx={{ cursor: 'pointer' }}
      >
        <SmallCustomAvatar image={imageUrl} />
        <CustomText fontSize={1.1} bold={700}>
          {name}
        </CustomText>
      </UserListItemContent>

      <UserListItemContent flex={10}>
        <CustomText>{email}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <UserListButtons>
          <UserDel
            fill={hoveredDel ? theme.color.schollBillRed : ''}
            onMouseOver={() => setHoveredDel(true)}
            onMouseLeave={() => setHoveredDel(false)}
            onClick={() => onDeleteClick({ name, imageUrl, email, uid })}
          />
        </UserListButtons>
      </UserListItemContent>
    </UserListItemContainer>
  );
};
