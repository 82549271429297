import React from 'react';

export const FullScreen: React.FC<{ fullScreen: boolean }> = ({ fullScreen }) =>
  fullScreen ? (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.79102 12.647V11.4595H6.94727C7.2752 11.4595 7.54102 11.7253 7.54102 12.0532L7.54102 16.2095H6.35352L6.35352 13.4842L0.837578 19.0001L0.000390738 18.1629L5.51633 12.647H2.79102Z"
        fill="white"
      />
      <path
        d="M12.0531 11.4595H16.2094V12.647H13.4841L19 18.1629L18.1628 19.0001L12.6469 13.4842V16.2095H11.4594V12.0532C11.4594 11.7253 11.7252 11.4595 12.0531 11.4595Z"
        fill="white"
      />
      <path
        d="M12.6469 5.51594L18.1628 0L19 0.837188L13.4841 6.35313L16.2094 6.35313V7.54063L12.0531 7.54063C11.7252 7.54063 11.4594 7.27482 11.4594 6.94688V2.79063H12.6469V5.51594Z"
        fill="white"
      />
      <path
        d="M0.837578 0L6.35352 5.52187L6.35352 2.79656H7.54102L7.54102 6.95281C7.54102 7.28075 7.2752 7.54656 6.94727 7.54656L2.79102 7.54656V6.35906L5.51633 6.35906L0.000390738 0.843124L0.837578 0Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_782_1496)">
        <path
          d="M4.75 17.8125V19H0.59375C0.265814 19 0 18.7342 0 18.4062V14.25H1.1875V16.9753L6.70344 11.4594L7.54063 12.2966L2.02469 17.8125H4.75Z"
          fill="white"
          rotate="90"
        />
        <path
          d="M18.4059 19H14.2496V17.8125H16.9749L11.459 12.2966L12.2962 11.4594L17.8121 16.9753V14.25H18.9996V18.4062C18.9996 18.7342 18.7338 19 18.4059 19Z"
          fill="white"
        />
        <path
          d="M17.8121 2.02468L12.2962 7.54062L11.459 6.70343L16.9749 1.18749H14.2496V-1.09673e-05H18.4059C18.7338 -1.09673e-05 18.9996 0.265803 18.9996 0.593739V4.74999H17.8121V2.02468Z"
          fill="white"
        />
        <path
          d="M6.70344 7.5466L1.1875 2.02473V4.75004H0V0.593788C0 0.265853 0.265814 3.8147e-05 0.59375 3.8147e-05H4.75V1.18754H2.02469L7.54063 6.70348L6.70344 7.5466Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_782_1496">
          <rect width="19" height="19" fill="white" transform="matrix(1 0 0 -1 0 19)" />
        </clipPath>
      </defs>
    </svg>
  );
