import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { DashboardCircleProps } from '../../common/index';
import { Arrows } from '../../svg/Arrows';
import { Bill } from '../../svg/Bill';
import { Chart } from '../../svg/Chart';
import { Mail } from '../../svg/Mail';
import { Notation } from '../../svg/Notation';
import { Overview } from '../../svg/Overview';
import { Profile } from '../../svg/Profile';
import { School } from '../../svg/School';
import { House } from '../../svg/House';
import { Headset } from '../../svg/Headset';
import { MuiIconContainer } from './AdminConsole.style';
import { ROUTES } from '../../constants';

export interface Links extends DashboardCircleProps {
  link?: string;
  smImage?: string;
  noDesc?: boolean;
  btnOption?: string;
  id: string;
}

export const links: Links[] = [
  {
    image: <Overview />,
    banner: 'Overview',
    descriptionText: 'See relevant sites inside organisation',
    link: '/overview',
    smImage: '/overview-sm.png',
    id: '1',
  },
  {
    image: <Profile />,
    banner: 'Users',
    descriptionText: 'Manage users in your organisation',
    link: '/users/student',
    smImage: '/user-sm.png',
    btnOption: 'Add User',
    id: '2',
  },
  {
    image: (
      <MuiIconContainer>
        <PeopleAltIcon />
      </MuiIconContainer>
    ),
    banner: 'Groups',
    descriptionText: 'Create different groups to help manage users',
    link: '/groups',
    smImage: '/groups-sm.png',
    btnOption: 'Add New Group',
    id: '3',
  },
  {
    image: <Chart />,
    banner: 'Analytics',
    descriptionText: 'View meaningful data for effective decision making',
    link: ROUTES.ANALYTICS,
    smImage: '/analytics-sm.png',
    id: '4',
  },
  {
    image: <Mail />,
    banner: 'Mail',
    descriptionText: 'Connect instantly with your organisation',
    smImage: '/mail-sm.png',
    link: '/mail/inbox',
    btnOption: 'Compose',
    id: '5',
  },
  {
    image: <Bill />,
    banner: 'Transactions',
    descriptionText: 'Manage your transactions',
    smImage: '/transactions-sm.png',
    link: '/transactions',
    id: '6',
  },
  {
    image: <Arrows />,
    banner: 'Data Migration',
    descriptionText: 'Manage migration',
    link: '/data-migration',
    smImage: '/data-migration-sm.png',
    id: '7',
  },
  {
    image: <School />,
    banner: 'School Profile',
    descriptionText: 'Manage information about your organisation',
    link: '/school-profile',
    btnOption: 'Edit',
    smImage: '/school-sm.png',
    id: '8',
  },
  {
    image: <Notation />,
    banner: 'Internal Support',
    descriptionText: 'Manage internal support tickets',
    link: '/internal-support',
    smImage: '/internal-support-sm.png',
    id: '9',
  },
  {
    image: (
      <MuiIconContainer>
        <AdminPanelSettingsIcon />
      </MuiIconContainer>
    ),
    banner: 'Permissions',
    descriptionText: 'Manage administrative roles',
    link: '/permissions',
    smImage: '/permissions-sm.png',
    id: '10',
  },
  {
    image: <House />,
    banner: 'Vendor Console',
    descriptionText: 'Create, manage and access your vendor groups',
    link: '/vendors',
    btnOption: 'Add New',
    id: '11',
  },
  {
    image: <Headset />,
    banner: 'External Support',
    descriptionText: 'Create and view external support tickets',
    link: '/external-support',
    smImage: '/headset-sm.png',
    btnOption: 'Compose',
    id: '12',
  },
];
