import React, { useEffect, useState } from 'react';
import { useAgents } from '../../hooks/useAgents';
import { AutocompleteInput } from './AutocompleteInput';

export const AgentInput: React.FC<{ value: any; onChange: any; info?: string }> = ({
  value,
  onChange,
  info,
}) => {
  const { agents } = useAgents();

  return (
    <AutocompleteInput
      value={value}
      name="user"
      info={info || 'User Name'}
      padding="4px 20px"
      placeholder="Select"
      options={agents}
      onChange={onChange}
    />
  );
};
