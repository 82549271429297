import React from 'react';

interface DownloadProps {
  fill?: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

export const Download: React.FC<DownloadProps> = ({ fill, onMouseOver, onMouseLeave }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.875 15.625V19.7917C21.875 20.3442 21.6555 20.8741 21.2648 21.2648C20.8741 21.6555 20.3442 21.875 19.7917 21.875H5.20833C4.6558 21.875 4.12589 21.6555 3.73519 21.2648C3.34449 20.8741 3.125 20.3442 3.125 19.7917V15.625"
      stroke={fill || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2915 10.4167L12.4998 15.6251L17.7082 10.4167"
      stroke={fill || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15.625V3.125"
      stroke={fill || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownloadSm = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.875 15.625V19.7917C21.875 20.3442 21.6555 20.8741 21.2648 21.2648C20.8741 21.6555 20.3442 21.875 19.7917 21.875H5.20833C4.6558 21.875 4.12589 21.6555 3.73519 21.2648C3.34449 20.8741 3.125 20.3442 3.125 19.7917V15.625"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.29199 10.4165L12.5003 15.6248L17.7087 10.4165"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15.625V3.125"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
