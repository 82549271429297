import { styled, Box } from '@mui/material';

export const LastCard = styled(Box)`
  background-color: ${({ theme }) => theme.color.darkerBlue};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  position: relative;
`;
