import React from 'react';

export const SmallClock = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1.71875C5.4375 1.71875 1.71875 5.4375 1.71875 10C1.71875 14.5625 5.4375 18.2812 10 18.2812C14.5625 18.2812 18.2812 14.5625 18.2812 10C18.2812 5.4375 14.5625 1.71875 10 1.71875ZM10 2.65625C14.0469 2.65625 17.3438 5.95312 17.3438 10C17.3438 14.0469 14.0469 17.3438 10 17.3438C5.95312 17.3438 2.65625 14.0469 2.65625 10C2.65625 5.95312 5.95312 2.65625 10 2.65625ZM10 4.0625C9.73438 4.0625 9.53125 4.26562 9.53125 4.53125V8.51562C8.89062 8.71875 8.4375 9.3125 8.4375 10C8.4375 10.8594 9.14062 11.5625 10 11.5625C10.7031 11.5625 11.2969 11.1094 11.4844 10.4688H13.9062C14.1719 10.4688 14.375 10.2656 14.375 10C14.375 9.73438 14.1719 9.53125 13.9062 9.53125H11.4844C11.3281 9.04688 10.9531 8.65625 10.4688 8.51562V4.53125C10.4688 4.26562 10.2656 4.0625 10 4.0625ZM10 9.375C10.3438 9.375 10.625 9.65625 10.625 10C10.625 10.3438 10.3438 10.625 10 10.625C9.65625 10.625 9.375 10.3438 9.375 10C9.375 9.65625 9.65625 9.375 10 9.375Z"
        fill="#0C84FC"
      />
    </svg>
  );
};
