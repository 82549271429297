import React from 'react';
import { styled, Box, useTheme } from '@mui/material';
import { CustomText } from '../pages/Users/Users.style';

export const InfoTitleContainer = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
  justify-self: flex-start;
  padding: 20px 30px;
`;
export const InfoTitle: React.FC<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  return (
    <InfoTitleContainer>
      <CustomText bold={700} color={theme.color.darkerBlue} fontSize={1.4}>
        {title}
      </CustomText>
    </InfoTitleContainer>
  );
};
