import React from 'react';

export const BlueEye = () => (
  <svg width="31" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4999 18.403C5.13427 18.403 0.913965 10.1358 0.740527 9.78889C0.624902 9.55764 0.624902 9.32639 0.740527 9.09514C0.913965 8.74826 5.13427 0.481079 14.4999 0.481079C23.8655 0.481079 28.0858 8.74826 28.2593 9.09514C28.3749 9.32639 28.3749 9.55764 28.2593 9.78889C28.0858 10.1936 23.8655 18.403 14.4999 18.403ZM2.30145 9.49983C3.16864 11.003 7.15771 16.8998 14.4999 16.8998C21.8421 16.8998 25.8312 11.003 26.6983 9.49983C25.8312 7.99671 21.8421 2.09984 14.4999 2.09984C7.15771 2.09984 3.16864 7.99671 2.30145 9.49983Z"
      fill="#0C84FC"
    />
    <path
      d="M14.5 16.7842C10.4531 16.7842 7.21558 13.4889 7.21558 9.49983C7.21558 5.51077 10.5109 2.21545 14.5 2.21545C18.489 2.21545 21.7843 5.51077 21.7843 9.49983C21.7843 13.4889 18.5468 16.7842 14.5 16.7842ZM14.5 3.71858C11.3203 3.71858 8.7187 6.32015 8.7187 9.49983C8.7187 12.6795 11.3203 15.2811 14.5 15.2811C17.6796 15.2811 20.2812 12.6795 20.2812 9.49983C20.2812 6.32015 17.6796 3.71858 14.5 3.71858Z"
      fill="#0C84FC"
    />
    <path
      d="M14.5 13.5467C12.3031 13.5467 10.4531 11.7545 10.4531 9.4998C10.4531 9.09511 10.8 8.74824 11.2047 8.74824C11.6094 8.74824 11.9563 9.09511 11.9563 9.4998C11.9563 10.8873 13.0547 11.9857 14.4422 11.9857C15.8297 11.9857 16.9281 10.8873 16.9281 9.4998C16.9281 8.1123 15.8297 7.01387 14.4422 7.01387C14.0375 7.01387 13.6906 6.66698 13.6906 6.2623C13.6906 5.85761 14.0375 5.51074 14.4422 5.51074C16.6391 5.51074 18.4891 7.30293 18.4891 9.55762C18.4891 11.8123 16.6969 13.5467 14.5 13.5467Z"
      fill="#0C84FC"
    />
  </svg>
);
