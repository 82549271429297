import { Flex } from '../../../../common/Containers';
import { AnalyticSchoolBillContainer } from './AnalyticSchoolBill.style';
import { GrossSchoolBill } from '../../../../components/Charts/GrossSchoolBill';
import { NumberOfSchoolBillAdditions } from '../../../../components/Charts/NumberOfSchoolBillAdditions';
import { SchoolBillMostAdded } from '../../../../components/Charts/SchoolBillMostAdded';

export const AnalyticSchoolBill = () => {
  return (
    <AnalyticSchoolBillContainer>
      <Flex fullHeight gap="10px">
        <Flex fullHeight>
          <GrossSchoolBill />
        </Flex>
        <Flex fullHeight>
          <NumberOfSchoolBillAdditions />
        </Flex>
      </Flex>
      <SchoolBillMostAdded />
    </AnalyticSchoolBillContainer>
  );
};
