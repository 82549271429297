import { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { ChartContainer } from './Chart.style';
import { ChartTitle } from '../../common/ChartTitle';
import { Flex } from '../../common/Containers';
import { SelectField } from '../../common/Inputs';
import { useTheme } from '@mui/material';
import { getAnalytics } from '../../api';
import { useForm } from '../../hooks/useForm';
import { GroupChartTooltip } from '../../common/ChartInfo/GroupChartTooltip';

export const GroupBarChart = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [order, setOrder] = useState('DESC');
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState<any[]>([]);
  const [totalValue, setTotalValue] = useState(0);
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('groups', {
      order,
    });
    setValue(data?.data.data.groups);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [order]);
  const getColor = (type: string) => {
    switch (type) {
      case 'student':
        return theme.color.darkerBlue;
      case 'parent':
        return theme.color.parentYellow;
      case 'staff':
        return theme.color.schollBillRed;
    }
  };
  return (
    <ChartContainer>
      <ChartTitle title="Groups Ranked by Size" description="Number of Members" withoutButton>
        <Flex noFlex>
          <SelectField
            value={order}
            name="order"
            id="order"
            onChange={(e: any) => {
              setOrder(e.target.value);
            }}
            options={[
              { name: 'High to Low', value: 'DESC' },
              { name: 'Low to High', value: 'ASC' },
            ]}
          />
        </Flex>
      </ChartTitle>
      {loading ? (
        <Flex fullHeight>Loading...</Flex>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={500} height={300} data={value}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="membersAmount" />
            <YAxis />
            <Tooltip content={<GroupChartTooltip type="type" name="name" image="image" />} />
            <Bar dataKey="membersAmount" barSize={20}>
              {value.map((item, index) => (
                <Cell key={index} fill={getColor(item?.type)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartContainer>
  );
};
