import React, { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { getAnalytics } from '../../api';
import { useTheme } from '@mui/material';
import { SmallFillter } from './SmallFilter';
import moment from 'moment';
import { fillDates } from '../../utils/dateSeparate';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};

export const BoughtCredit = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState<{}[]>([]);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('credits', {
      from: filter.from,
      to: filter.to,
      type: 'bought',
    });
    const value: {}[] = fillDates(filter.from, [], filter, data?.data.data);
    setValue(value);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const sum: any = value.reduce((accumulator, object: any) => {
    return accumulator + object.total;
  }, 0);

  const clearFilter = () => setFilter(initialFilter);
  return (
    <>
      <LineCharts
        data={value}
        title={`Credits Bought: ${sum ? sum / 100 : 0}`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
        }}
        loading={loading}
        color={theme.color.depositGreen}
        noPrefix
      />

      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// 'deposits/total-transfered-to-bank-account',
