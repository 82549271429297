import React from 'react';

export const ExportTicket: React.FC<{ color?: string | false }> = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_853_2550)">
        <path
          d="M17.588 16.431C18.0531 16.8909 18.3096 17.5034 18.3105 18.1557C18.3115 18.808 18.0567 19.421 17.593 19.8822L15.7764 21.6885C15.5859 21.8781 15.337 21.9727 15.0879 21.9727C14.8371 21.9727 14.5863 21.8765 14.3953 21.6846C14.015 21.3022 14.0169 20.6841 14.3993 20.3037L15.6168 19.0929H10.0824C8.99239 19.0929 8.10547 19.9799 8.10547 21.0699C8.10547 22.16 8.9817 23.0469 10.0586 23.0469C10.598 23.0469 11.0352 23.484 11.0352 24.0234C11.0352 24.5628 10.598 25 10.0586 25C9.00822 25 8.02422 24.5899 7.2876 23.8451C6.55556 23.1047 6.15234 22.1191 6.15234 21.0699C6.15234 18.9028 7.91531 17.1398 10.0824 17.1398H15.5272L14.4012 16.0265C14.0177 15.6471 14.0142 15.0288 14.3934 14.6454C14.7726 14.2618 15.391 14.2584 15.7745 14.6378L17.588 16.431ZM25 3.90625V10.3027C25 10.8421 24.5628 11.2793 24.0234 11.2793C23.484 11.2793 23.0469 10.8421 23.0469 10.3027V4.18148L14.5628 9.45702C13.9265 9.8526 13.2133 10.0504 12.5 10.0504C11.7867 10.0504 11.0735 9.8526 10.4374 9.45702L1.95312 4.18148V15.1855C1.95312 16.2624 2.82936 17.1387 3.90625 17.1387C4.44565 17.1387 4.88281 17.5758 4.88281 18.1152C4.88281 18.6546 4.44565 19.0918 3.90625 19.0918C1.75228 19.0918 0 17.3395 0 15.1855V3.90625C0 1.75228 1.75228 0 3.90625 0H21.0938C23.2477 0 25 1.75228 25 3.90625ZM22.2815 2.35729C21.9521 2.10419 21.5405 1.95312 21.0938 1.95312H3.90625C3.45955 1.95312 3.04775 2.10419 2.71854 2.35729L11.4687 7.79839C12.1048 8.19397 12.8952 8.19397 13.5315 7.79839L22.2815 2.35729ZM24.0234 14.209C23.484 14.209 23.0469 14.6461 23.0469 15.1855C23.0469 16.2624 22.1706 17.1387 21.0938 17.1387C20.5544 17.1387 20.1172 17.5758 20.1172 18.1152C20.1172 18.6546 20.5544 19.0918 21.0938 19.0918C23.2477 19.0918 25 17.3395 25 15.1855C25 14.6461 24.5628 14.209 24.0234 14.209Z"
          fill={color ? color : '#DADADA'}
        />
      </g>
      <defs>
        <clipPath id="clip0_853_2550">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
