import { Box, styled, Typography } from "@mui/material";
import React, { useState } from "react";

import { FooterShape } from "../../common";
import { DashboardLayout } from "../../components/Layout/DashboardLayout";
import { Logout } from "../../components/Logout/Logout";
import { Nav } from "../../components/Nav/Nav";

const Center = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 9%;
`;

export const NotFound = () => {
  const [showLogout, setShowLogout] = useState(false);

  return (
    <DashboardLayout>
      <Nav logout={() => setShowLogout(true)} />
      {showLogout && <Logout isOpen={showLogout} onClose={() => setShowLogout(false)} />}
      <Center>
        <Typography variant="h1" color="primary">404</Typography>
        <Typography variant="h3">Page not found</Typography>
      </Center>
      <FooterShape />
    </DashboardLayout>
  )
}
