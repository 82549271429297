import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteGroup, getGroupId } from '../api';
import { ROUTES } from '../constants';

export const useGroup = (id: string) => {
  const [group, setGroup] = useState<any>(null);
  const { data, isLoading, refetch } = useQuery(['group', id], () => getGroupId(id));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const delGroup = useMutation(deleteGroup, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('group');
      navigate(ROUTES.GROUPS);
    },
  });

  useEffect(() => {
    if (data) {
      setGroup(data.data.data);
    }
  }, [data]);

  return {
    group,
    isLoading,
    delGroup,
    refetch,
  };
};
