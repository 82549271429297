import { styled, Box } from '@mui/material';
import React from 'react';
import { Flex } from '../../common/Containers';

export const LastsCreditBoughtContainer = styled(Box)`
  display: flex;
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.borderGrey};
`;
