import { MenuItem, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../common';
import { SelectField } from '../../common/Inputs/Input';
import { AddModalContent, CustomText, FlexInputContainer, ModalUser } from '../Users/Users.style';

interface GroupsAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: null | 'student' | 'parent' | 'staff') => void;
}

export const GroupFilterModal: React.FC<GroupsAddModalProps> = ({ isOpen, onClose, onSave }) => {
  const theme = useTheme();
  const [type, setType] = useState<null | 'student' | 'parent' | 'staff'>(null);

  const types: string[] = ['student', 'parent', 'staff'];

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
          group filter
        </CustomText>

        <FlexInputContainer>
          <SelectField
            value={type}
            name="role"
            id="user"
            placeholder="All Users"
            info="Users"
            onChange={(e: any) => {
              setType(e.target.value);
            }}
          >
            {types.map((tab) => (
              <MenuItem value={tab}>{tab}</MenuItem>
            ))}
          </SelectField>
        </FlexInputContainer>

        <CustomButton content="FILTERING" width="100%" type="button" onClick={() => onSave(type)} />
      </AddModalContent>
    </ModalUser>
  );
};
