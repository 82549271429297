import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getAgents } from '../api';

export const useAgents = () => {
  const [agents, setAgents] = useState<any>(null);
  const { data, isLoading } = useQuery(['agents'], () => getAgents());

  useEffect(() => {
    if (data) {
      setAgents(data.data.data);
    }
  }, [data]);

  return {
    agents,
    isLoading,
  };
};
