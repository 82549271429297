import { Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Switch } from "@mui/material";
import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { CustomText } from "../../../Users/Users.style";
import { DataContainer } from "../../Analytics.style";
import { TotalTransactionsLineHeader } from "./AnalyticTransactions.style";
import { data } from "./TotalTransactionsLineChart";

const arrData = data.map((item) => ({
  ...item,
  student: Math.floor(Math.random() * 99),
}))

export const CreditTransactions = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>Credits transactions: 368</CustomText>
        <CustomText>2021 / All Months / All Students / All Groups</CustomText>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Switch />
        <IconButton size="large">
          <Menu />
        </IconButton>
      </Stack>
    </TotalTransactionsLineHeader>
    <ResponsiveContainer height="81%" width="100%">
      <BarChart
        height={400}
        width={771}
        data={arrData}
      >
        <CartesianGrid vertical={false} />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis dataKey="name" tickLine={false} />
        <Bar dataKey="student" radius={[10,10,0,0]} barSize={35} fill="#4BFE2D" />
      </BarChart>
    </ResponsiveContainer>
  </DataContainer>
);
