import { Box, styled } from '@mui/material';

export const GroupPermissionsInfo = styled(Box)<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || 0};
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.color.borderGrey};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    padding: 9px 0;
  }
`;

export const PossiblePermissionsContainer = styled(Box)<{ gap?: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: ${({ gap }) => gap || 0};
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 9px;
    overflow-y: auto;
  }
`;

export const PossibleItemContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  cursor: pointer;
`;

export const Circle = styled(Box)<{ height?: string; width?: string; cl?: boolean }>`
  height: ${({ height }) => height || '75px'};
  width: ${({ width }) => width || '75px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ cl, theme }) => (cl ? theme.color.darkerBlue : theme.palette.grey[400])};
  position: relative;
`;

export const CheckMarkCircle = styled(Box)`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 6%;
  right: 0;
`;

export const PermisionImage = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 37px;
  svg {
    width: 37px;
    height: 37px;
  }
`;
