import {
  FormControl,
  InputLabel,
  TextField,
  Stack,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import { CustomButton, FetchingNextPageButton } from '../../common';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import { Filter } from '../../svg/Filter';
import { GroupFilterModal } from './GroupFilterModal';
import { GroupItem, GroupItemProps, GroupItemSkeleton } from './GroupItem';
import {
  GroupFilterContainer,
  GroupItemsContainer,
  GroupPageContainer,
  GroupPageContent,
  SkeletonContainer,
} from './Groups.style';
import { GroupsAddModal } from './GroupsAddModal';
import { hasValue } from '../../helpers';
import CloseFilter from '../../common/CloseFilter';

export const Groups = () => {
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [groupType, setGroupType] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { groups, isFetchingNextPage, fetchNextPage, isLoading } = useGroups(groupType, searchTerm);
  const { ref, inView } = useInView();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setSearchTerm(search);
      } else {
        setSearchTerm(null);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <GroupPageContainer>
      <RouteInfo onClick={() => setShowAddModal(true)} withButton />
      {showAddModal && (
        <GroupsAddModal isOpen={showAddModal} onClose={() => setShowAddModal(false)} />
      )}
      {showFilterModal && (
        <GroupFilterModal
          isOpen={showFilterModal}
          onSave={(value: null | string) => {
            setGroupType(value);
            setShowFilterModal(false);
          }}
          onClose={() => setShowFilterModal(false)}
        />
      )}
      <GroupFilterContainer gap={27}>
        <FormControl sx={{ flex: '0 0 20%' }}>
          <TextField
            value={search}
            placeholder="Search group name..."
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </FormControl>
        {!groupType ? (
          <CustomButton content={<Filter />} onClick={() => setShowFilterModal(true)} />
        ) : (
          <CloseFilter clearFilter={() => setGroupType(null)} />
        )}
      </GroupFilterContainer>
      <GroupPageContent>
        <GroupItemsContainer>
          {isLoading &&
            [...Array(20)].map((item: any, index: number) => <GroupItemSkeleton key={index} />)}
          {groups.map((groups: GroupItemProps[], index: number) =>
            Object.values(groups).map((group: GroupItemProps, index: number) => (
              <GroupItem
                key={index}
                {...group}
                onClick={() => navigate(`${ROUTES.GROUP_DETAIL}/${group.id}`)}
              />
            )),
          )}
        </GroupItemsContainer>
        {groups.length > 19 && (
          <FetchingNextPageButton onClick={() => fetchNextPage()} disabled ref={ref}>
            {isFetchingNextPage && (
              <Stack alignItems="center" direction="row">
                Loading more... <CircularProgress size={16} />
              </Stack>
            )}
          </FetchingNextPageButton>
        )}
      </GroupPageContent>
    </GroupPageContainer>
  );
};
