import { IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { CustomButton, UserProfileLitem } from '../../common';
import { CustomText } from '../Users/Users.style';
import { UserDetailListContainer, ButtonContainer, StudentsContainer } from './UserDetail.style';
import { Flex } from '../../common/Containers';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { AddStudent } from '../../svg/AddStudent';
import { AddStudentModal } from './AddStudentModal';
import { useNotification } from '../../hooks/useNotification';

export const UserAllRelatedProfile: React.FC<{
  relatedProfiles: any[];
  uid: string;
  removeStudent: any;
  addStudent: any;
  setReqPaymentModal: any;
}> = ({ relatedProfiles, uid, removeStudent, addStudent, setReqPaymentModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { notifyError } = useNotification();
  const [addStudentModal, setAddStudentModal] = useState(false);

  const handleSubmit = async (studentId: string) => {
    if (!studentId) {
      notifyError('Student must be selected!');
      return;
    }
    await addStudent.mutateAsync({ parentId: uid, studentId });
    setAddStudentModal(false);
  };
  return (
    <UserDetailListContainer>
      <Flex column>
        <StudentsContainer>
          {relatedProfiles.length > 0 ? (
            <>
              <Flex spaceBetween>
                <CustomText fontSize={1.1} color={theme.color.darkerBlue} bold={700}>
                  STUDENT NAME:
                </CustomText>
                <IconButton size="small" onClick={() => setAddStudentModal(true)}>
                  <AddStudent />
                </IconButton>
              </Flex>
              <Flex column>
                {relatedProfiles.map((item) => (
                  <UserProfileLitem
                    name={item.name}
                    second={item.email}
                    third={item.phoneNumber}
                    imageUrl={item.imageUrl}
                    uid={item.uid}
                    parentId={uid}
                    to="student"
                    removeStudent={removeStudent}
                  />
                ))}
              </Flex>
            </>
          ) : (
            <CustomButton bgcolor="transparent" onClick={() => setAddStudentModal(true)}>
              <CustomText bold={700} color={theme.color.darkerBlue}>
                Add first student{' '}
              </CustomText>
              <IconButton size="small">
                <AddStudent color={theme.color.darkerBlue} />
              </IconButton>
            </CustomButton>
          )}
        </StudentsContainer>
        <ButtonContainer>
          <CustomButton content="Request Payment" onClick={() => setReqPaymentModal(true)} />
          <CustomButton
            content="View Transactions"
            invert
            bgcolor={theme.palette.common.white}
            onClick={() => navigate(ROUTES.USER_TRANSACTIONS + '/' + uid)}
          />
        </ButtonContainer>
      </Flex>
      <AddStudentModal
        loading={addStudent.isLoading}
        isOpen={addStudentModal}
        onClose={() => setAddStudentModal(false)}
        onSubmit={handleSubmit}
      />
    </UserDetailListContainer>
  );
};
