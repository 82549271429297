import React from 'react';

export const GraduationHat = () => (
  <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0211 11.618V7.15526C29.0211 6.72722 28.6741 6.38026 28.2461 6.38026H14.9647C14.5367 6.38026 14.1897 6.72722 14.1897 7.15526C14.1897 7.58329 14.5367 7.93026 14.9647 7.93026H24.4812L14.9647 12.0216L3.64559 7.15526L14.9647 2.28891L20.0454 4.47321C20.4387 4.64224 20.8945 4.46054 21.0635 4.06731C21.2325 3.67411 21.0508 3.21826 20.6576 3.04923L15.2708 0.733296C15.0754 0.649286 14.854 0.649286 14.6586 0.733296L1.37724 6.44326C1.09274 6.5656 0.908325 6.84557 0.908325 7.15526C0.908325 7.46495 1.09274 7.74495 1.37724 7.86725L14.6586 13.5771C14.7563 13.6191 14.8605 13.6401 14.9647 13.6401C15.0689 13.6401 15.1731 13.6191 15.2708 13.5771L22.5806 10.4345L22.5807 15.7973C18.3151 19.7438 11.6141 19.7438 7.34865 15.7972L7.34869 14.4293C7.34869 14.0013 7.00172 13.6543 6.57369 13.6543C6.1457 13.6543 5.79869 14.0013 5.79869 14.4292L5.79865 16.1293C5.79865 16.3375 5.88247 16.537 6.03119 16.6828C8.43067 19.0345 11.6033 20.3296 14.9647 20.3296C18.3261 20.3296 21.4987 19.0345 23.8982 16.6828C24.0469 16.5371 24.1307 16.3376 24.1307 16.1293L24.1306 9.76813L27.471 8.33198V11.6179C26.8397 11.9114 26.4006 12.5513 26.4006 13.2921C26.4006 14.0524 26.9681 16.0526 28.246 16.0526C29.524 16.0526 30.0913 14.0524 30.0913 13.2921C30.0914 12.5513 29.6524 11.9114 29.0211 11.618ZM28.2461 14.2931C28.0955 13.9973 27.9507 13.5403 27.9507 13.2921C27.9507 13.1293 28.0832 12.9967 28.2461 12.9967C28.4089 12.9967 28.5414 13.1293 28.5414 13.2921C28.5414 13.5403 28.3965 13.9973 28.2461 14.2931Z"
      fill="#0C84FC"
    />
  </svg>
);
