import React, { ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { WhiteContainer } from '../../common/Containers';
import { InitialCircle } from '../../common';

export const SchoolInfoContainer = styled(Box)`
  flex: 1;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

export const SchoolInfoCardContent = styled(Box)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CardImageContainer = styled(Box)<{
  size?: number;
}>`
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: aliceblue;
  border: 3px solid ${({ theme }) => theme.color.darkerBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const ImageCard = styled(WhiteContainer)`
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
`;

export const CardImage: React.FC<{ size?: number; image?: string }> = ({ size, image }) => (
  <CardImageContainer size={size}>
    <ImageCard>
      <InitialCircle img={image} />
    </ImageCard>
  </CardImageContainer>
);
