import React from 'react';

import { InitialCircle } from '../../common';
import { PermisionPlus } from '../../svg/PermisionPlus';
import { GroupdItemContainer, GroupItemMain, GroupItemSecondary } from '../Groups/Groups.style';
import { CustomText } from '../Users/Users.style';

export interface PermissionItemProps {
  image: string;
  name: string;
  permissions: any[];
  hexColor?: string;
  onClick?: () => void;
}

export const PermissionItem: React.FC<PermissionItemProps> = ({
  image,
  name,
  permissions,
  hexColor,
  onClick,
}) => (
  <GroupdItemContainer>
    <GroupItemMain>
      <InitialCircle img={image} bgcolor={hexColor} margin="4% 0 0 0" />
      <CustomText bold={700} margin="9% 0 0 0">
        {name}
      </CustomText>
      <CustomText bold={300}>PERMISSION NUMBER: {permissions?.length}</CustomText>
    </GroupItemMain>

    <GroupItemSecondary
      justifyContent="center"
      gap="9px"
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <PermisionPlus />
      <CustomText bold={700}>Add Permissions</CustomText>
    </GroupItemSecondary>
  </GroupdItemContainer>
);
