import { terms } from './dateConstants';

export enum ROUTES {
  LOGIN = '/login',
  REGISTER = '/register',
  DASHBOARD = '/',
  ADMIN = '',
  FAQ = '/faq',
  OVERVIEW = '/overview',
  USERS = '/users/:tab',
  USER_DETAIL = '/details',
  USER_EDIT = '/users/edit',
  USER_TRANSACTIONS = '/transactions/users',
  GROUPS = '/groups',
  GROUP_DETAIL = '/groups',
  ANALYTICS = '/analytics',
  ANALYTICS_GROUPS = '/analytics/groups',
  ANALYTICS_SCHOOL_BILL = '/analytics/school-bill',
  ANALYTICS_CREDITS = '/analytics/credits',
  ANALYTICS_TRANSACTIONS = '/analytics/transactions',
  ANALYTICS_SUPPORT = '/analytics/support',
  ANALYTIC_VENDOR = '/analytics/vendor',
  ANALYTIC_DEPOSITS = '/analytics/deposits',
  ANALYTIC_USERS = '/analytics/users',
  DATA_MIGRATION = '/data-migration',
  SCHOOL_PROFILE = '/school-profile',
  MAIL = '/mail',
  MAIL_PAGE = '/mail/detail',
  INTERNAL_SUPPORT = '/internal-support',
  INTERNAL_SUPPORT_TICKET = '/internal-support/ticket',
  EXTERNAL_SUPPORT = '/external-support',
  EXTERNAL_SUPPORT_TICKET = '/external-support/ticket',
  PERMISSIONS = '/permissions',
  VENDORS_CONSOLE = '/vendors-console',
  VENDORS = '/vendors',
  ANALYTIC_VENDORS = '/vendors/analytic-vendors',
  PERMANENT_VENDORS = '/vendors/permanent-vendors',
  TEMPORARY_VENDORS = '/vendors/temporary-vendors',
  VENDOR_PROFILE = '/vendors',
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/details',
  NOTFOUND = '*',
}

export const DATE_FORMAT = 'LLLL dd, yyyy';
export const MAIL_DATE = 'DD/MM/yyyy';
export const MAIL_TIME = 'HH:mm';
export const userTypes = [
  { name: 'Students', value: 'students' },
  { name: 'Parents', value: 'parents' },
  { name: 'Stuff', value: 'stuff' },
];

export const SUPPORT_DATE = `MM.dd.yyyy. ${MAIL_TIME}`;
export { terms };
