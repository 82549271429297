import React, { useEffect, useState } from 'react';
import { AutocompleteInput, SelectField } from './';
import { getUserId } from '../../api';
import { useAllParents } from '../../hooks/useAllParents';

export const AllParentAutocomplete: React.FC<{ value: any; onChange: any; parentId?: string }> = ({
  value,
  onChange,
  parentId,
}) => {
  const [searchParent, setSearchParent] = useState(null);
  const { users } = useAllParents(searchParent);
  const [userOption, setUserOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);

  const getParent = async () => {
    if (parentId) {
      setLoading(true);
      const res = await getUserId(parentId);
      if (res.data.data.user) {
        setUser({ id: res.data.data.user.uid, name: res.data.data.user.name });
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  useEffect(() => {
    getParent();
  }, [parentId]);

  if (!parentId) {
    return (
      <AutocompleteInput
        value={value}
        name="userId"
        id="Parent Name"
        placeholder="Parent Name"
        info="Parent"
        options={userOption}
        onChange={(event: any, obj: { uid: string }) => onChange(obj.uid)}
      />
    );
  }

  return loading ? (
    <SelectField id="role" info="Parent Name" name="role" disabled placeholder="Loading..." />
  ) : (
    <AutocompleteInput
      value={value}
      name="userId"
      id="Parent Name"
      defaultValue={user && { id: user.id, name: user.name }}
      onInputChange={(e: any) => {
        const delayDebounceFn = setTimeout(() => {
          if (e.target.value) {
            setSearchParent(e.target.value);
          } else {
            setSearchParent(null);
          }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
      }}
      placeholder="Parent Name"
      info="Parent Name"
      options={userOption}
      onChange={(event: any, obj: { uid: string }) => onChange(obj.uid)}
    />
  );
};
