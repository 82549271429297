import React from 'react';

const UpdateSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 6.74666C17.2502 6.83932 17.2334 6.93211 17.1993 7.01992L17.1956 7.02935C17.0844 7.30435 16.8149 7.49837 16.5 7.49837H12C11.5858 7.49837 11.25 7.16258 11.25 6.74837C11.25 6.33415 11.5858 5.99837 12 5.99837H14.6066L12.5064 4.02493C11.0332 2.55146 8.93107 1.94343 6.92173 2.39807C4.91238 2.85271 3.27679 4.30644 2.58953 6.24856C2.45135 6.63905 2.02279 6.84358 1.6323 6.7054C1.24181 6.56722 1.03728 6.13865 1.17546 5.74817C2.03453 3.32051 4.07902 1.50335 6.5907 0.935052C9.10238 0.366752 11.73 1.12679 13.5505 2.94817L15.75 5.01465V2.24837C15.75 1.83415 16.0858 1.49837 16.5 1.49837C16.9142 1.49837 17.25 1.83415 17.25 2.24837V6.74666ZM0 11.2517C-0.000228882 11.159 0.0166111 11.0663 0.0506744 10.9784L0.054409 10.969C0.165604 10.694 0.435144 10.5 0.75 10.5H5.25C5.66421 10.5 6 10.8358 6 11.25C6 11.6642 5.66421 12 5.25 12H2.64341L4.74358 13.9734C6.21684 15.4469 8.31892 16.0549 10.3283 15.6003C12.3376 15.1457 13.9732 13.6919 14.6605 11.7498C14.7986 11.3593 15.2272 11.1548 15.6177 11.293C16.0082 11.4311 16.2127 11.8597 16.0745 12.2502C15.2155 14.6779 13.171 16.495 10.6593 17.0633C8.14761 17.6316 5.52 16.8716 3.69954 15.0502L1.5 12.9837V15.75C1.5 16.1642 1.16421 16.5 0.75 16.5C0.335785 16.5 0 16.1642 0 15.75V11.2517Z"
        fill="#0C84FC"
      />
      <mask id="mask0_6555_6483" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.25 6.74666C17.2502 6.83932 17.2334 6.93211 17.1993 7.01992L17.1956 7.02935C17.0844 7.30435 16.8149 7.49837 16.5 7.49837H12C11.5858 7.49837 11.25 7.16258 11.25 6.74837C11.25 6.33415 11.5858 5.99837 12 5.99837H14.6066L12.5064 4.02493C11.0332 2.55146 8.93107 1.94343 6.92173 2.39807C4.91238 2.85271 3.27679 4.30644 2.58953 6.24856C2.45135 6.63905 2.02279 6.84358 1.6323 6.7054C1.24181 6.56722 1.03728 6.13865 1.17546 5.74817C2.03453 3.32051 4.07902 1.50335 6.5907 0.935052C9.10238 0.366752 11.73 1.12679 13.5505 2.94817L15.75 5.01465V2.24837C15.75 1.83415 16.0858 1.49837 16.5 1.49837C16.9142 1.49837 17.25 1.83415 17.25 2.24837V6.74666ZM0 11.2517C-0.000228882 11.159 0.0166111 11.0663 0.0506744 10.9784L0.054409 10.969C0.165604 10.694 0.435144 10.5 0.75 10.5H5.25C5.66421 10.5 6 10.8358 6 11.25C6 11.6642 5.66421 12 5.25 12H2.64341L4.74358 13.9734C6.21684 15.4469 8.31892 16.0549 10.3283 15.6003C12.3376 15.1457 13.9732 13.6919 14.6605 11.7498C14.7986 11.3593 15.2272 11.1548 15.6177 11.293C16.0082 11.4311 16.2127 11.8597 16.0745 12.2502C15.2155 14.6779 13.171 16.495 10.6593 17.0633C8.14761 17.6316 5.52 16.8716 3.69954 15.0502L1.5 12.9837V15.75C1.5 16.1642 1.16421 16.5 0.75 16.5C0.335785 16.5 0 16.1642 0 15.75V11.2517Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6555_6483)"></g>
    </svg>
  );
};

export default UpdateSvg;
