import { Box } from '@mui/material';
import React from 'react';
import {
  Line,
  LineChart,
  YAxis,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import { CustomText } from '../../../Users/Users.style';
import { DataContainer } from '../../Analytics.style';

export const data = [
  {
    name: 'Jan',
    staff: 35,
    student: 41,
    parents: 81,
  },
  {
    name: 'Feb',
    staff: 35,
    student: 80,
    parents: 101,
  },
  {
    name: 'Mar',
    staff: 41,
    student: 80,
    parents: 101,
  },
  {
    name: 'Apr',
    staff: 35,
    student: 41,
    parents: 81,
  },
  {
    name: 'May',
    staff: 35,
    student: 80,
    parents: 101,
  },
  {
    name: 'Jun',
    staff: 41,
    student: 80,
    parents: 101,
  },
  {
    name: 'Jul',
    staff: 35,
    student: 41,
    parents: 81,
  },
  {
    name: 'Aug',
    staff: 35,
    student: 120,
    parents: 101,
  },
  {
    name: 'Sep',
    staff: 41,
    student: 80,
    parents: 101,
  },
  {
    name: 'Oct',
    staff: 35,
    student: 41,
    parents: 81,
  },
  {
    name: 'Nov',
    staff: 35,
    student: 80,
    parents: 101,
  },
  {
    name: 'Dec',
    staff: 41,
    student: 80,
    parents: 101,
  },
];

export const AnalyticTotalUsers = () => {
  return (
    <DataContainer height="50vh" width="97vw" column>
      <Box sx={{ px: 5 }}>
        <CustomText fontSize={1.4} bold={700}>
          Total Users: 212
        </CustomText>
        <CustomText fontSize={0.9} bold={300}>
          All Months / 2021
        </CustomText>
      </Box>
      <ResponsiveContainer height="87%" width="90%">
        <LineChart height={444} width={1444} data={data}>
          <CartesianGrid vertical={false} horizontal />
          <YAxis axisLine={false} tickLine={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <Tooltip />
          <Line
            type="linear"
            dataKey="student"
            stroke="#0C84FC"
            dot={{ r: 6, fill: '#0C84FC' }}
            activeDot={false}
            strokeWidth={2}
          />

          <Line
            type="linear"
            dataKey="parents"
            stroke="#EDBB3C"
            dot={{ r: 6, fill: '#EDBB3C' }}
            activeDot={false}
            strokeWidth={2}
          />

          <Line
            type="linear"
            dataKey="staff"
            stroke="#ED3C3C"
            dot={{ r: 6, fill: '#ED3C3C' }}
            activeDot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </DataContainer>
  );
};
