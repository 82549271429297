import React, { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { BigFilter } from './BigFilter';
import { useAnalytics } from '../../hooks/useAnalytics';

const initialFilter = {
  year: '',
  term: '',
  month: '',
  user: '',
  group: {
    id: null,
    name: null,
  },
  name: {
    uid: null,
    name: null,
  },
};

export const GrossRevenue = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const { value, isLoading, totalValue } = useAnalytics('deposits/gross-revenue', {
    year: filter.year,
    term: filter.term,
    month: filter.month,
    role: filter.user,
    groupId: filter.group.id,
    userId: filter.name.uid,
  });
  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const clearFilter = () => setFilter(initialFilter);
  return (
    <>
      <LineCharts
        data={value}
        title={`Gross Revenue: ${totalValue ? totalValue / 100 : 0}$`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          year: filter.year,
          term: filter.term,
          month: filter.month,
          role: filter.user,
          group: filter.group.name,
          user: filter.name.name,
        }}
        loading={isLoading}
      />

      <BigFilter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
