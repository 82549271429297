import { useTheme } from '@mui/material';
import React from 'react';

import { useUser } from '../../hooks/useUser';

import { CustomButton, UserProfileLitem, UserProfileLitemSkeleton } from '../../common';
import { CustomText } from '../Users/Users.style';
import { UserDetailListContainer, ButtonContainer, StudentsContainer } from './UserDetail.style';
import { Flex } from '../../common/Containers';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

export const StudentRelatedProfiles: React.FC<{
  parentId: string;
  relatedProfiles?: any;
  uid: string;
}> = ({ parentId, relatedProfiles, uid }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <UserDetailListContainer>
      <Flex column>
        <StudentsContainer>
          <CustomText fontSize={1.1} color={theme.color.darkerBlue} bold={700}>
            PARENT NAME:
          </CustomText>
          {relatedProfiles && (
            <UserProfileLitem
              name={relatedProfiles.name}
              second={relatedProfiles.email}
              third={relatedProfiles.phoneNumber}
              imageUrl={relatedProfiles.imageUrl}
              uid={relatedProfiles.uid}
              to="parent"
              noDelete
            />
          )}
        </StudentsContainer>
        <ButtonContainer>
          <CustomButton
            content="View Transactions"
            textcolor={theme.palette.common.black}
            bgcolor={theme.color.buttonGrey}
            onClick={() => navigate(ROUTES.USER_TRANSACTIONS + '/' + uid)}
          />
        </ButtonContainer>
      </Flex>
    </UserDetailListContainer>
  );
};
