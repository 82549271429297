import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { decodeUrl } from '../../helpers';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { useTicket } from '../../hooks/useTicket';
import { UserDetailsContainer } from '../UserDetail/UserDetail.style';
import { AssignAgentModal } from './AssignAgentModal';
import { CreateReply } from './CreateReply';
import { MessagesAndFormContainer, MessagesList } from './InternalSupportTicket.style';

import { InternalSupportTicketDetail } from './InternalSupportTicketDetail';
import { InternalSupportTicketMessages } from './InternalSupportTicketMessages';

type Reply = { reply: string };

export const InternalSupportTicket = () => {
  const { notifySuccess, notifyError } = useNotification();
  const [assignAgentModal, setAssignAgentModal] = useState(false);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const id = decodeUrl(pathname);
  const { ticket, reply, exportTicket, agentAssign, ticketResolve, isLoading } = useTicket(id);
  const { values, onChange, forceChange } = useForm<Reply>({
    reply: '',
  });
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!values.reply) {
      notifyError('Message cannot be empty!');
      return;
    }
    setLoading(true);
    try {
      const res = await reply(values.reply);
      if (!res?.data.error) {
        notifySuccess(res?.data.message);
        forceChange('', 'reply');
        setLoading(false);
      } else {
        notifyError(res?.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      const res = await exportTicket();
      if (!res?.data.error) {
        notifySuccess(res?.data.message);
        setLoading(false);
      } else {
        notifyError(res?.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResolve = async () => {
    await ticketResolve.mutateAsync({ ticketId: ticket?.id, enabled: !ticket?.resolved });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <RouteInfo
        banner="Internal Support"
        description="Manage internal support tickets"
        smImage="/internal-support-sm.png"
        noLastRoute
      />
      <UserDetailsContainer>
        <InternalSupportTicketDetail
          onClick={() => navigate(-1)}
          agent={ticket?.agent?.name}
          setAssignAgentModal={setAssignAgentModal}
        />
        <MessagesAndFormContainer>
          <MessagesList>
            {ticket?.messages.map(
              (
                message: {
                  body: string;
                  date: number;
                  from: { photo: string; name: string; email: string };
                  id: string;
                  isFromExternalSupport: boolean;
                  subject: string;
                },
                index: number,
              ) => (
                <InternalSupportTicketMessages
                  resolve={handleResolve}
                  resolved={ticket.resolved}
                  main={index === 0}
                  {...message}
                />
              ),
            )}
          </MessagesList>

          {ticket && !ticket.resolved && (
            <CreateReply
              value={values.reply}
              onChange={onChange}
              handleSubmit={handleSubmit}
              loading={loading}
              handleExport={handleExport}
              exportExternal={ticket?.exportExternal}
            />
          )}
          <AssignAgentModal
            open={assignAgentModal}
            handleClose={() => {
              setAssignAgentModal(false);
            }}
            agentAssign={agentAssign}
          />
        </MessagesAndFormContainer>
      </UserDetailsContainer>
    </>
  );
};
