import { useState, useEffect, useMemo } from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useUsers } from '../../hooks/useUsers';
import { StudentFilterModal } from './StudentFilterModal';
import { UserAdd } from './UserAdd';
import { UserListHeader } from './UserListHeader';
import { UserListItem, UserListItemSkeleton } from './UserListItem';
import { DeleteUserModal } from './DeleteUserModal';

import { ListContainer, UsersContainer } from './Users.style';
import { UsersControl } from './UsersControl';
import { FetchButton } from '../../common/FetchButton';
import { useNavigate, useParams } from 'react-router-dom';

export const Users = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [filter, setFilter] = useState<{ groupId: null | string; schoolYear: null | number }>({
    groupId: null,
    schoolYear: null,
  });

  const {
    users,
    isLoading,
    tabs,
    activeTab,
    setActiveTab,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useUsers(params.tab, searchTerm, filter);

  const changeValue = (event: any, newValue: number) => {
    navigate(`/users/${tabs[newValue]}`);
    setActiveTab(newValue);
  };

  const changeSearch = (event: any) => setSearch(event.target.value);

  const onSave = (values: any) => {
    setFilter(values);
    setShowFilterModal(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setSearchTerm(search);
      } else {
        setSearchTerm(null);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    setFilter({
      groupId: null,
      schoolYear: null,
    });
  }, [activeTab]);

  const onDeleteClick = (user: any) => {
    setUserToDelete(user);
  };

  const clearFilter = () => {
    setFilter({
      groupId: null,
      schoolYear: null,
    });
  };

  useEffect(() => {
    if (!tabs.includes(params.tab)) {
      navigate('/users/student}');
    }
  }, []);

  useEffect(() => {
    setActiveTab(tabs.indexOf(params.tab));
  }, [params]);

  return (
    <UsersContainer>
      <RouteInfo onClick={() => setShowAddUserModal(true)} withButton />
      {showAddUserModal && (
        <UserAdd
          isOpen={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          tabs={tabs}
          activeTab={activeTab}
          refetch={refetch}
        />
      )}
      {showFilterModal && (
        <StudentFilterModal
          onSave={onSave}
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(false)}
          openTab={tabs[activeTab]}
        />
      )}
      <UsersControl
        changeValue={changeValue}
        search={search}
        changeSearch={changeSearch}
        showModal={() => setShowFilterModal(true)}
        filter={filter}
        clearFilter={clearFilter}
        tabs={tabs}
        activeTab={activeTab}
      />
      <ListContainer>
        <UserListHeader activeTab={activeTab} />
        {isLoading &&
          [...Array(20)].map((item: any, index: number) => (
            <UserListItemSkeleton key={index} role={tabs[activeTab]} />
          ))}
        {users &&
          users.map((users: any) =>
            Object.values(users).map((user: any, index: number) => (
              <UserListItem key={index} {...user} onDeleteClick={onDeleteClick} />
            )),
          )}
        <FetchButton
          name="There are no more users"
          onView={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </ListContainer>
      <DeleteUserModal
        isOpen={!!userToDelete}
        onClose={() => {
          setUserToDelete(null);
        }}
        user={userToDelete}
      />
    </UsersContainer>
  );
};
