import React, { useState } from 'react';

import { CustomButton } from '../../../common';
import { Flex } from '../../../common/Containers';
import { DateInput, PhoneNumberInput } from '../../../common/Inputs';
import { InputField, SelectField } from '../../../common/Inputs/Input';
import { InputWrapper } from '../../../common/Inputs/InputWrapper';
import { useForm } from '../../../hooks/useForm';
import { AddUserPhoto } from '../../Users/AddUserPhoto';
import {
  AddModalContent,
  CustomText,
  FlexInputContainer,
  ModalUser,
} from '../../Users/Users.style';
import { VendorCreateType } from '../PermanentVendors/VendorTypes';
import Checkbox from '@mui/material/Checkbox';
import { createVendor } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';
import moment from 'moment';

interface PermanentVendorsAddProps {
  bannerTitle: string;
  onClose: () => void;
  isOpen: boolean;
}

export const AddTemporaryVendor: React.FC<PermanentVendorsAddProps> = ({ onClose, isOpen }) => {
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const { values, forceChange, onChange } = useForm<VendorCreateType>({
    name: '',
    email: '',
    phoneNumber: '',
    expiryDate: moment().unix(),
    type: 'temporary',
    image: {
      type: '',
      data: '',
    },
  });
  const checkItem = (item: string) => {
    const { transactions } = values;
    let newArr = [];
    if (!transactions.includes(item)) {
      newArr = [...transactions, item];
    } else {
      newArr = transactions.filter((a) => a !== item);
    }
    forceChange(newArr, 'transactions');
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await createVendor(values);
    if (res.status === 200) {
      notifySuccess(res.data.message);
      onClose();
    } else {
      notifyError(res.data.message);
    }
    setLoading(false);
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <AddUserPhoto size={7} onChange={(data: string) => forceChange(data, 'image')} />

        <InputField
          id="name"
          info="Permanent Vendor Name"
          name="name"
          value={values.name}
          onChange={onChange}
          placeholder="Enter here"
        />

        <InputField
          id="email"
          info="Email"
          name="email"
          onChange={onChange}
          value={values.email}
          type="email"
          placeholder="Enter here"
        />

        <FlexInputContainer>
          <Flex>
            <PhoneNumberInput
              type="text"
              name="phoneNumber"
              info="PhoneNumber"
              placeholder="+1 992 9922"
              onChange={(value: string) => forceChange(value, 'phoneNumber')}
              value={values.phoneNumber}
            />
          </Flex>
        </FlexInputContainer>

        <FlexInputContainer>
          <Flex flexEnd alignEnd>
            <Flex>
              <DateInput
                id="expiryDate"
                name="expiryDate"
                info="Expiry Date"
                minDate={new Date()}
                onChange={(value: any) => forceChange(moment(value).unix(), 'expiryDate')}
                value={moment.unix(values.expiryDate)}
              />
            </Flex>
            <Flex>
              <CustomText>Info about expiry date of temporary vendor.</CustomText>
            </Flex>
          </Flex>
        </FlexInputContainer>

        <CustomButton
          content="Submit"
          width="100%"
          type="submit"
          margin="1% 0"
          onClick={handleSubmit}
          loading={loading}
        />
      </AddModalContent>
    </ModalUser>
  );
};
