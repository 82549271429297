import { Box, Input, styled, Typography } from '@mui/material';

export const NavContainer = styled(Box)`
  height: 67px;
  min-height: 67px;
  max-height: 67px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.darkerBlue};
  padding: 0 40px;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const LeftContainer = styled(Box)<{ align?: string; gap?: number }>`
  height: 100%;
  width: auto;
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || 'flex-start'};
  gap: ${({ gap }) => gap && `${gap}%`};

  a {
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: inherit;
  }
`;

export const LogoContainer = styled(Box)`
  height: 100%;
  width: 87%;
  display: flex;
  align-items: center;
`;

export const LogoText = styled(Typography)`
  width: 75%;
  font-size: 22px;
  margin-left: 2%;

  @media (max-width: 1024px) {
    font-size: 11px;
    margin-left: 6%;
  }
`;

export const NavSearchContainer = styled(Box)`
  height: 100%;
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled(Input)`
  height: 62%;
  width: 90%;
  border-radius: 29px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.common.white};

  img {
    margin-right: 2%;
  }
`;
