import React, { useContext, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { DashboardLayout } from '../../components/Layout/DashboardLayout';
import { Loading } from '../../components/Loading';
import { Logout } from '../../components/Logout/Logout';
import { Nav } from '../../components/Nav/Nav';
import { AuthContext } from '../../contexts/authContext';

export const Dashboard = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { pathname } = useLocation();
  const { userLoading } = useContext(AuthContext);

  if (userLoading) {
    return <Loading />;
  }

  return (
    <DashboardLayout dark={pathname.length > 2}>
      <Nav logout={() => setShowLogout(true)} />
      {showLogout && <Logout isOpen={showLogout} onClose={() => setShowLogout(false)} />}
      <Outlet />
    </DashboardLayout>
  );
};
