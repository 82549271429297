import { Box, Stack, styled } from "@mui/material";
import React from "react";
import { StyledAvatar } from "../common";
import { CustomText } from "../pages/Users/Users.style";

const StyledStack = styled(Stack)`
  @media(max-width: 1024px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1% 0;
  }
`

interface UserProfileProps {
  name: string;
  price?: string;
}

export const UserProfile: React.FC<UserProfileProps> = ({
  name,
  price,
}) => (
  <StyledStack direction="row" spacing={2} alignItems="center">
    <StyledAvatar />
    <Box>
      <CustomText bold={300}>
        {name}
      </CustomText>
      <CustomText bold={700}>
        {price}
      </CustomText>
    </Box>
  </StyledStack>
)
