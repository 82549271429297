import React from "react";

import { Flex } from "../../../UserDetail/UserDetail.style";
import { ExportedTickets } from "./ExportedTickets";
import { FilledTickets } from './FilledTickets';
import { PieTickets } from "./PieTickets";
import { ResolvedTickets } from "./ResolvedTickets";

export const AnalyticSupport = () => (
  <>
    <Flex>
      <FilledTickets />
      <ResolvedTickets />
    </Flex>

    <Flex>
      <PieTickets />
      <ExportedTickets />
    </Flex>
  </>
)
