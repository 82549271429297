import React, { useState } from 'react';
import { getBase64 } from '../../helpers/imageToBase64';

import { InfoContainer, SmallCircle } from '../../components/RouteInfo/RouteInfo.style';
import { AddPhotoContainer, CustomText, AddImageCircle } from './Users.style';

export const AddUserPhoto: React.FC<{ onChange?: any; userImage?: string; size?: number }> = ({
  onChange,
  userImage,
  size,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const handleChange = async (e: any) => {
    const url: any = await getBase64(e.target.files[0]);
    const formattedBinary = url.split(',')[1];
    const type = url.split(';')[0].split(':')[1];
    setImageUrl(url);
    onChange?.({
      type,
      data: formattedBinary,
    });
  };
  return (
    <>
      <AddPhotoContainer htmlFor="photo" gap={20}>
        <AddImageCircle size={size} userImage={userImage || imageUrl}>
          {!imageUrl && !userImage && <img src="/add.png" alt="add_photo" />}
        </AddImageCircle>
        <InfoContainer>
          <CustomText fontSize={1.1} bold={700}>
            Add Photo
          </CustomText>
          <CustomText fontSize={0.9} bold={300}>
            Info About photo size and format
          </CustomText>
        </InfoContainer>
      </AddPhotoContainer>
      <input type="file" id="photo" style={{ display: 'none' }} onChange={handleChange} />
    </>
  );
};
