import { Divider, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { composeExternalTicket } from '../../api';
import { CustomButton } from '../../common';
import { InputField } from '../../common/Inputs/Input';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';

interface ExternalSupportSendProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

export const ExternalSupportSend: React.FC<ExternalSupportSendProps> = ({
  isOpen,
  onClose,
  refetch,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const { values, onChange } = useForm<{
    subject: string;
    message: string;
    exportExternal: true;
  }>({ subject: '', message: '', exportExternal: true });

  const handleSubmit = async () => {
    setLoading(true);
    const res = await composeExternalTicket(values);
    if (res.status === 200) {
      setLoading(false);
      refetch();
      onClose();
    } else {
      setLoading(false);
      notifyError(res.data.message);
    }
  };

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          COMPOSE
        </CustomText>
        <Divider />
        <InputField
          id="subject"
          value={values.subject}
          name="subject"
          info="Subject *"
          placeholder="Enter here ..."
          onChange={onChange}
        />

        <InputField
          id="message"
          info="Message *"
          placeholder="Enter here ..."
          value={values.message}
          name="message"
          multiline
          onChange={onChange}
          rows={13}
        />

        <CustomButton
          content="SEND"
          width="100%"
          type="submit"
          onClick={handleSubmit}
          loading={loading}
        />
      </AddModalContent>
    </ModalUser>
  );
};
