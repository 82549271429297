import { styled, Box } from '@mui/material';

export const UserAnalyticContainer = styled(Box)`
  height: 75vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 3fr;
  gap: 10px;
`;

export const BigRow = styled(Box)`
  grid-column: 1 / 3;
`;
