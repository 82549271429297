import { Divider, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { CustomButton } from '../../../common';
import { Flex } from '../../../common/Containers';
import { DateInput, PhoneNumberInput } from '../../../common/Inputs';
import { InputField } from '../../../common/Inputs/Input';
import { InputWrapper } from '../../../common/Inputs/InputWrapper';
import { useForm } from '../../../hooks/useForm';
import { AddUserPhoto } from '../../Users/AddUserPhoto';
import {
  AddModalContent,
  CustomText,
  FlexInputContainer,
  ModalUser,
} from '../../Users/Users.style';

import Checkbox from '@mui/material/Checkbox';
import { editVendor } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';
import { AxiosResponse } from 'axios';
import { QueryObserverResult } from 'react-query';
import { VendorCreateType } from '../PermanentVendors/VendorTypes';
import moment from 'moment';

interface PermanentVendorsAddProps {
  onClose: () => void;
  isOpen: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  expiryDate: number;
  transactionType: string[];
  image: string;
  id: string;
}

export const EditTemporaryVendor: React.FC<PermanentVendorsAddProps> = ({
  onClose,
  isOpen,
  name,
  email,
  phoneNumber,
  expiryDate,
  transactionType,
  image,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { notifySuccess, notifyError } = useNotification();
  const { values, forceChange, onChange } = useForm<VendorCreateType>({
    vendorId: id,
    name: name,
    email,
    phoneNumber,
    expiryDate,
    transactions: transactionType,
  });
  const checkItem = (item: string) => {
    const { transactions } = values;
    let newArr = [];
    if (!transactions.includes(item)) {
      newArr = [...transactions, item];
    } else {
      newArr = transactions.filter((a: any) => a !== item);
    }
    forceChange(newArr, 'transactions');
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await editVendor(values);
    if (res.status === 200) {
      notifySuccess(res.data.message);
      onClose();
    } else {
      notifyError(res.data.message);
    }
    setLoading(false);
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <Flex column gap="0px" noCenter>
          <CustomText
            fontSize={1.4}
            bold={700}
            uppercase
            color={theme.color.darkerBlue}
            margin="0 0 20px 0"
          >
            EDIT VENDOR
          </CustomText>

          <Divider flexItem />
        </Flex>
        <AddUserPhoto
          size={7}
          userImage={image}
          onChange={(data: string) => forceChange(data, 'image')}
        />

        <InputField
          id="name"
          info="Permanent Vendor Name"
          name="name"
          value={values.name}
          onChange={onChange}
          placeholder="Enter here"
        />

        <InputField
          id="email"
          info="Email"
          name="email"
          onChange={onChange}
          value={values.email}
          type="email"
          placeholder="Enter here"
        />

        <FlexInputContainer>
          <Flex>
            <PhoneNumberInput
              type="text"
              name="phoneNumber"
              info="PhoneNumber"
              placeholder="+1 992 9922"
              onChange={(value: string) => forceChange(value, 'phoneNumber')}
              value={values.phoneNumber}
            />
          </Flex>
          <Flex fullHeight column>
            <InputWrapper info="Transactions"></InputWrapper>
            <Flex fullHeight>
              <Flex fullHeight noFlex gap="5px">
                <Checkbox
                  name="wallet"
                  checked={values.transactions.includes('wallet')}
                  onChange={() => checkItem('wallet')}
                />
                <CustomText>Credits</CustomText>
              </Flex>
              <Flex gap="5px">
                <Checkbox
                  checked={values.transactions.includes('schoolBill')}
                  name="schoolBill"
                  onChange={() => checkItem('schoolBill')}
                />
                <CustomText>School Bill</CustomText>
              </Flex>
            </Flex>
          </Flex>
        </FlexInputContainer>
        <FlexInputContainer>
          <Flex flexEnd alignEnd>
            <Flex>
              <DateInput
                id="expiryDate"
                name="expiryDate"
                info="Expiry Date"
                minDate={new Date()}
                onChange={(value: any) => forceChange(moment(value).unix(), 'expiryDate')}
                value={moment.unix(values.expiryDate)}
              />
            </Flex>
            <Flex>
              <CustomText>Info about expiry date of temporary vendor.</CustomText>
            </Flex>
          </Flex>
        </FlexInputContainer>

        <CustomButton
          content="Submit"
          width="100%"
          type="submit"
          margin="1% 0"
          onClick={handleSubmit}
          loading={loading}
        />
      </AddModalContent>
    </ModalUser>
  );
};
