import { stringLength } from '@firebase/util';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getTicket, sendReply, exportToExternal, assignAgent, resolveTicket } from '../api';
import { useNotification } from './useNotification';

export const useTicket = (id: string) => {
  const { notifySuccess, notifyError } = useNotification();
  const queryClient = useQueryClient();
  const [ticket, setTicket] = useState<null | {
    id: string;
    agent: null | any;
    createdBy: { uid: string; name: string; imageUrl: string };
    date: number;
    exportExternal: boolean;
    messages: {
      body: string;
      date: number;
      from: { photo: string; name: string; email: string };
      id: string;
      isFromExternalSupport: boolean;
      subject: string;
    }[];
    resolved: boolean;
  }>(null);

  const { data, isLoading, refetch } = useQuery(['ticket', id], () => getTicket(id));

  const agentAssign = useMutation(
    (agentId: any) => {
      return assignAgent({ ticketId: id, agentId: agentId });
    },
    {
      onSuccess: () => {
        notifySuccess('Agent assigned succesfully');
        queryClient.invalidateQueries('ticket');
      },
      onError: (e: any) => {
        notifyError(e.message);
      },
    },
  );

  // const mutation = useMutation('orders', addOrder, {
  //   onSuccess: () => {
  //     notify({ message: 'Order added succesfully' });
  //     queryClient.invalidateQueries('orders');
  //   },
  //   onError: (e: any) => {
  //     notifyError(e.message);
  //   }
  // });

  const ticketResolve = useMutation(resolveTicket, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ticket']);
      notifySuccess('Ticket marked resolved!');
    },
    onError: (e: any) => {
      notifyError('Somethnig went wrong, try again!');
    },
  });

  const reply = async (message: string) => {
    const res = await sendReply(id, message);
    if (res.status === 200) {
      notifySuccess('Message sent!');
      refetch();
      return res;
    }
  };

  const exportTicket = async () => {
    const res = await exportToExternal(id);
    if (res.status === 200) {
      refetch();
      return res;
    }
  };

  useEffect(() => {
    if (data) {
      setTicket(data.data.data);
    }
  }, [data]);

  return {
    ticket,
    isLoading,
    reply,
    exportTicket,
    agentAssign,
    ticketResolve,
  };
};
