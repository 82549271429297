import { styled, Box } from '@mui/material';

export const AnalyticSchoolBillContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 75vh;
  gap: 10px;
`;

export const AvatarCard = styled(Box)`
  display: flex;
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.borderGrey};
`;

export const AvatarCardContainer = styled(Box)`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
`;
