import { Box, Modal, styled, Tabs, Typography } from '@mui/material';
import emotionStyled from '@emotion/styled';
import { Circle } from '../../common';

export const UsersContainer = styled(Box)`
  height: 81vh;
  width: 100%;
  padding-bottom: 44px;
`;

export const FiltersContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const TabsContainer = styled(Tabs)<{ width?: string }>`
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};

  button {
    font-weight: 800;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  [aria-selected='true'] {
    background: -webkit-linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
    -webkit-background-clip: text;
  }

  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.color.darkerBlue};
  }
`;

export const ListContainer = styled(Box)`
  width: 100%;
  padding: 0 20px 100px 20px;
  position: relative;
  gap: 12px;
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CustomText = styled(Typography)<{
  fontSize?: number;
  color?: string;
  bold?: number;
  margin?: string;
  uppercase?: boolean;
  textCenter?: boolean;
}>`
  font-size: ${({ fontSize }) => `${fontSize}rem` || '1rem'};
  font-weight: ${({ bold }) => bold || 400};
  color: ${({ color, theme }) => color || theme.palette.common.black};
  margin: ${({ margin }) => margin || '0'};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({ textCenter }) => (textCenter ? 'center' : 'start')};
`;

export const UserListItemContainer = styled(Box)<{
  jcontent?: string;
  gap?: number;
  padding?: string;
  margin?: string;
  blue?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jcontent }) => jcontent || 'space-between'};
  gap: ${({ gap }) => `${gap}px` || '0'};
  padding: ${({ padding }) => padding || '20px'};
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme, blue }) =>
    blue ? theme.color.darkerBlue : theme.palette.common.white};
  color: ${({ blue, theme }) => (blue ? theme.palette.common.white : 'black')};
  position: relative;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  svg {
    cursor: pointer;
    transition: all 0.4s;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const UserListItemContent = styled(Box)<{
  jcontent?: string;
  margin?: string;
  flex?: number;
}>`
  height: 100;
  display: flex;
  align-items: center;
  justify-content: ${({ jcontent }) => jcontent || 'flex-start'};
  margin-left: ${({ margin }) => margin || '0'};
  flex: ${({ flex }) => flex};
`;

export const UserListButtons = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;

  @media (max-width: 768px) {
    width: 9%;
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
  }
`;

export const UserHeaderContainer = styled(Box)<{ width?: string; margin?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ModalUser = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50vw;
`;

export const AddModalContent = styled(Box)<{ ow?: boolean }>`
  min-height: 20vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 35%;
  border-radius: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  overflow: ${({ ow }) => (ow ? 'visible' : 'scroll')};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FlexInputContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const AddPhotoContainer = emotionStyled.label<{ gap?: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  cursor: pointer;
  position: relative;
`;

export const AddImageCircle = styled(Circle)<{
  limit?: boolean;
  color?: string;
  size?: number;
  userImage?: string;
}>`
  max-width: ${({ limit, size }) => (limit ? 'none' : size ? size + 'rem' : '132px')};
  max-height: ${({ size }) => (size ? size + 'rem' : '132px')};
  min-height: ${({ size }) => (size ? size + 'rem' : '132px')};
  overflow: hidden;
  position: relative;
  background: url(${({ userImage }) => (userImage ? userImage : '')});
  background-size: cover;
  background-color: ${({ color, theme }) => (color ? color : theme.color.darkerBlue)};

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
