import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { decodeUrl } from '../../helpers';
import { useUser } from '../../hooks/useUser';
import { EditUserContainer } from './EditUser.style';
import { Loading } from '../../components/Loading';
import { EditStudent } from './EditStudent';
import { EditParent } from './EditParent';
import { EditStaff } from './EditStaff';

export const EditUser = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const { user, isLoading } = useUser(params.id);
  const onClose = () => navigate(-1);

  if (isLoading) return <Loading />;

  return (
    <>
      <RouteInfo lastRoute={user && user.name} />
      <EditUserContainer>
        {user?.role === 'student' && <EditStudent user={user} onClose={onClose} />}
        {user?.role === 'parent' && <EditParent user={user} onClose={onClose} />}
        {user?.role === 'staff' && <EditStaff user={user} onClose={onClose} />}
      </EditUserContainer>
    </>
  );
};
