import React from "react";

interface UserArrowRightProps {
  fill?: string;
  onClick?: any;
  styles?: any;
}

export const UserArrowRight: React.FC<UserArrowRightProps> = ({ fill, onClick, styles }) => (
  <svg width="18" height="14" onClick={onClick} style={styles} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L14.5858 8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H14.5858L10.2929 1.70711C9.90237 1.31658 9.90237 0.683417 10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L17.7071 6.29289C17.8902 6.47595 17.9874 6.71232 17.9989 6.95203C17.9996 6.96801 18 6.984 18 7M17.9241 7.38278C17.8753 7.50073 17.803 7.61123 17.7071 7.70711L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071M17.9241 7.38278C17.9719 7.26756 17.9988 7.14146 18 7.00923L17.9241 7.38278Z" fill={fill || "url(#paint0_linear_829_1859)"}/>
  </svg>
)
