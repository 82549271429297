import React from 'react';

import { FooterShape } from '../../common';
import { AdminConsole } from '../../components/AdminConsole/AdminConsole';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';

export const Admin = () => (
  <>
    <RouteInfo />
    <AdminConsole />
    <FooterShape />
  </>
);
