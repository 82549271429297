import React from 'react';

export const People: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8376 11.4126C21.5501 12.5751 22.7501 14.1501 22.7501 16.2501V20.0001H26.5001C27.1876 20.0001 27.7501 19.4376 27.7501 18.7501V16.2501C27.7501 13.5251 23.2876 11.9126 19.8376 11.4126Z"
      fill={color ? color : '#0C84FC'}
    />
    <path
      d="M10.25 10C13.0114 10 15.25 7.76142 15.25 5C15.25 2.23858 13.0114 0 10.25 0C7.48858 0 5.25 2.23858 5.25 5C5.25 7.76142 7.48858 10 10.25 10Z"
      fill={color ? color : '#0C84FC'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7501 10C20.5126 10 22.7501 7.7625 22.7501 5C22.7501 2.2375 20.5126 0 17.7501 0C17.1626 0 16.6126 0.125 16.0876 0.3C17.1251 1.5875 17.7501 3.225 17.7501 5C17.7501 6.775 17.1251 8.4125 16.0876 9.7C16.6126 9.875 17.1626 10 17.7501 10Z"
      fill={color ? color : '#0C84FC'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 11.25C6.9125 11.25 0.25 12.925 0.25 16.25V18.75C0.25 19.4375 0.8125 20 1.5 20H19C19.6875 20 20.25 19.4375 20.25 18.75V16.25C20.25 12.925 13.5875 11.25 10.25 11.25Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
