import { CircularProgress, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { FetchingNextPageButton } from './CustomButton';

export const FetchButton: React.FC<{
  onView: () => void;
  isFetchingNextPage: boolean;
  name?: string;
}> = ({ onView, isFetchingNextPage, name }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      onView?.();
    }
  }, [inView]);
  return (
    <FetchingNextPageButton disabled ref={ref}>
      {isFetchingNextPage ? (
        <Stack alignItems="center" direction="row">
          Loading more... <CircularProgress size={16} />
        </Stack>
      ) : name ? (
        name
      ) : (
        'No more items'
      )}
    </FetchingNextPageButton>
  );
};
