import { useTheme } from "@mui/material";
import React from "react";
import { Flex } from "../../../UserDetail/UserDetail.style";
import { CreditTransactions } from "./CreditTransactions";
import { MostAddedToSchoolBill } from "./MostAddedToSchoolBill";
import { MostCreditedAccount } from "./MostCreditedAccount";
import { SchoolBillTransactions } from "./SchoolBillTransactions";
import { TotalTransactionsLineChart } from "./TotalTransactionsLineChart";
import { TotalTransactionsPie } from "./TotalTransactionsPie";

export const AnalyticTransactions = () => {
  const theme = useTheme();

  return (
    <>
      <Flex>
        <TotalTransactionsLineChart />
        <TotalTransactionsPie />
      </Flex>

      <Flex>
        <SchoolBillTransactions />
        <CreditTransactions />
      </Flex>

      <Flex>
        <MostAddedToSchoolBill theme={theme} />
        <MostCreditedAccount theme={theme} />
      </Flex>
    </>
  )
}
