import React from 'react';
import { AnalyticVendorContainer, MiddleRow } from './AnalyticVendor.style';
import { TotalVendors } from '../../../../components/Charts/TotalVendors';
import { TemporaryVendors } from '../../../../components/Charts/TemporaryVendors';
import { TotalCreditsRedeemed } from '../../../../components/Charts/TotalCreditsRedeemed';
import { VendorGrossRevenue } from '../../../../components/Charts/VendorGrossRevenue';
import { VendorSchoolBill } from '../../../../components/Charts/VendorSchoolBill';

export const AnalyticVendor = () => {
  return (
    <AnalyticVendorContainer>
      <TotalVendors />
      <TemporaryVendors />
      <MiddleRow>
        <TotalCreditsRedeemed />
      </MiddleRow>
      <VendorGrossRevenue />
      <VendorSchoolBill />
    </AnalyticVendorContainer>
  );
};
