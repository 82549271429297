import { Skeleton, useTheme } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallCustomAvatar } from '../../common';
import { DATE_FORMAT, MAIL_TIME, ROUTES } from '../../constants';
import { CustomText, UserListItemContainer, UserListItemContent } from '../Users/Users.style';

interface TransactionItemProps {
  name: string;
  type: string;
  date: number;
  price: number;
  imageUrl?: string;
  id: string;
}

export const TransactionsItem: React.FC<TransactionItemProps> = ({
  name,
  type,
  date,
  price,
  imageUrl,
  id,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <UserListItemContainer onClick={() => navigate(ROUTES.TRANSACTION_DETAILS + '/' + id)}>
      <UserListItemContent flex={15} gap={2} sx={{ cursor: 'pointer' }}>
        <SmallCustomAvatar image={imageUrl} />
        <CustomText fontSize={1.1} bold={700}>
          {name}
        </CustomText>
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <CustomText>{type}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={10}>
        <CustomText>{moment.unix(date).format('MMMM, DD, YYYY')}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={10}>
        <CustomText>{moment.unix(date).format('HH:mm')}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={3} jcontent="flex-end">
        <CustomText color={theme.color.depositGreen}>£{price / 100}</CustomText>
      </UserListItemContent>
    </UserListItemContainer>
  );
};

export const TransactionsItemSkeleton: React.FC = () => (
  <UserListItemContainer>
    <UserListItemContent flex={15} gap={2}>
      <Skeleton height={37} width={37} variant="circular" />
      <Skeleton width={200} />
    </UserListItemContent>
    <UserListItemContent flex={15}>
      <Skeleton width={100} />
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <Skeleton width={130} />
    </UserListItemContent>
    <UserListItemContent flex={10}>
      <Skeleton width={50} />
    </UserListItemContent>
    <UserListItemContent flex={3} jcontent="flex-end">
      <Skeleton width={30} />
    </UserListItemContent>
  </UserListItemContainer>
);
