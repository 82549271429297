import React from 'react';

export const Calendar = () => (
  <svg width="31" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 23.5625H6.5C5.42256 23.5625 4.38925 23.1345 3.62738 22.3726C2.86551 21.6108 2.4375 20.5774 2.4375 19.5V8.125C2.4375 7.04756 2.86551 6.01425 3.62738 5.25238C4.38925 4.49051 5.42256 4.0625 6.5 4.0625H19.5C20.5774 4.0625 21.6108 4.49051 22.3726 5.25238C23.1345 6.01425 23.5625 7.04756 23.5625 8.125V19.5C23.5625 20.5774 23.1345 21.6108 22.3726 22.3726C21.6108 23.1345 20.5774 23.5625 19.5 23.5625ZM6.5 5.6875C5.85353 5.6875 5.23355 5.94431 4.77643 6.40143C4.31931 6.85855 4.0625 7.47853 4.0625 8.125V19.5C4.0625 20.1465 4.31931 20.7665 4.77643 21.2236C5.23355 21.6807 5.85353 21.9375 6.5 21.9375H19.5C20.1465 21.9375 20.7665 21.6807 21.2236 21.2236C21.6807 20.7665 21.9375 20.1465 21.9375 19.5V8.125C21.9375 7.47853 21.6807 6.85855 21.2236 6.40143C20.7665 5.94431 20.1465 5.6875 19.5 5.6875H6.5Z"
      fill="#0C84FC"
    />
    <path
      d="M19.5 20.3125H16.25C16.0345 20.3125 15.8278 20.2269 15.6755 20.0745C15.5231 19.9222 15.4375 19.7155 15.4375 19.5V16.25C15.4375 16.0345 15.5231 15.8278 15.6755 15.6755C15.8278 15.5231 16.0345 15.4375 16.25 15.4375H19.5C19.7155 15.4375 19.9222 15.5231 20.0745 15.6755C20.2269 15.8278 20.3125 16.0345 20.3125 16.25V19.5C20.3125 19.7155 20.2269 19.9222 20.0745 20.0745C19.9222 20.2269 19.7155 20.3125 19.5 20.3125ZM17.0625 18.6875H18.6875V17.0625H17.0625V18.6875Z"
      fill="#0C84FC"
    />
    <path
      d="M22.75 10.5625H3.25C3.03451 10.5625 2.82785 10.4769 2.67548 10.3245C2.5231 10.1722 2.4375 9.96549 2.4375 9.75C2.4375 9.53451 2.5231 9.32785 2.67548 9.17548C2.82785 9.0231 3.03451 8.9375 3.25 8.9375H22.75C22.9655 8.9375 23.1722 9.0231 23.3245 9.17548C23.4769 9.32785 23.5625 9.53451 23.5625 9.75C23.5625 9.96549 23.4769 10.1722 23.3245 10.3245C23.1722 10.4769 22.9655 10.5625 22.75 10.5625Z"
      fill="#0C84FC"
    />
    <path
      d="M8.9375 7.3125C8.72201 7.3125 8.51535 7.2269 8.36298 7.07452C8.2106 6.92215 8.125 6.71549 8.125 6.5V3.25C8.125 3.03451 8.2106 2.82785 8.36298 2.67548C8.51535 2.5231 8.72201 2.4375 8.9375 2.4375C9.15299 2.4375 9.35965 2.5231 9.51202 2.67548C9.6644 2.82785 9.75 3.03451 9.75 3.25V6.5C9.75 6.71549 9.6644 6.92215 9.51202 7.07452C9.35965 7.2269 9.15299 7.3125 8.9375 7.3125Z"
      fill="#0C84FC"
    />
    <path
      d="M17.0625 7.3125C16.847 7.3125 16.6403 7.2269 16.488 7.07452C16.3356 6.92215 16.25 6.71549 16.25 6.5V3.25C16.25 3.03451 16.3356 2.82785 16.488 2.67548C16.6403 2.5231 16.847 2.4375 17.0625 2.4375C17.278 2.4375 17.4847 2.5231 17.637 2.67548C17.7894 2.82785 17.875 3.03451 17.875 3.25V6.5C17.875 6.71549 17.7894 6.92215 17.637 7.07452C17.4847 7.2269 17.278 7.3125 17.0625 7.3125Z"
      fill="#0C84FC"
    />
  </svg>
);
