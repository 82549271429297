import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CustomButton } from '../../common';
import { Flex } from '../../common/Containers';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useInvoice } from '../../hooks/useInvoice';
import { useAppSelector } from '../../store';
import { theme } from '../../Theme';
import { ExternalSupportTicketDetail } from '../ExternalSupportTicket/ExternalSupportTicketDetail';
import { MessagesAndFormContainer } from '../InternalSupportTicket/InternalSupportTicket.style';
import { UserDetailsContainer } from '../UserDetail/UserDetail.style';
import { CustomText } from '../Users/Users.style';
import { InvoiceDetailModal } from './InvoiceDetailModal';
import { MailMessage } from './MailMessage';

export const MailPage = () => {
  const navigate = useNavigate();
  const { mailItem } = useAppSelector((state) => state.user);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const { invoice, isLoading } = useInvoice(mailItem.invoiceId);

  if (!mailItem) return <Navigate to={ROUTES.MAIL} />;

  return (
    <>
      <RouteInfo
        banner="Mail"
        description="Connect instantly with your organisation"
        lastRoute="Mail Details"
      />
      <UserDetailsContainer>
        <ExternalSupportTicketDetail onClick={() => navigate(-1)}>
          {mailItem.type === 'invoice' && (
            <CustomButton
              content="Invoice Details"
              onClick={() => setInvoiceModalOpen(true)}
              loading={isLoading}
            />
          )}
        </ExternalSupportTicketDetail>
        <MessagesAndFormContainer>
          <MailMessage {...mailItem} />
        </MessagesAndFormContainer>
        {mailItem.type === 'payRequest' && (
          <Flex flexEnd p="2rem" spaceBetween>
            <Flex>
              {mailItem.paymentOffer && (
                <CustomText fontSize={2} bold={700} color={theme.color.depositDarkGreen}>
                  Price: £{mailItem.paymentOffer / 100}
                </CustomText>
              )}
            </Flex>
            <CustomButton
              width="15rem"
              bgcolor={
                mailItem.offerResponse === 'accepted'
                  ? theme.color.depositGreen
                  : mailItem.offerResponse === 'denied'
                  ? theme.color.schollBillRed
                  : theme.color.darkerBlue
              }
              content={mailItem.offerResponse ? mailItem.offerResponse : 'pending'}
            />
          </Flex>
        )}
      </UserDetailsContainer>
      <InvoiceDetailModal
        open={invoiceModalOpen}
        {...invoice}
        handleClose={() => setInvoiceModalOpen(false)}
      />
    </>
  );
};
