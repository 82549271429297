import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteInfo } from '../../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../../constants';
import { useVendors } from '../../../hooks/useVendors';
// import { VendorProfile } from '../../../components/Vendors/VendorProfile';
import { GroupItem, GroupItemSkeleton } from '../../Groups/GroupItem';
import { permanentVendors } from './PermanentVendors.constants';
import { PermanentVendorsContainerPage } from './PermanentVendors.style';
import { PermanentVendorsAdd } from '../TemporaryVendors/PermanentVendorsAdd';

export const PermanentVendors = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();
  const { vendors, refetch, isLoading } = useVendors('permanent');

  return (
    <>
      <RouteInfo
        banner="Permanent Vendors"
        description="Create and manage Permanent Vendors"
        withButton
        onClick={() => setShowAdd(true)}
        lastRoute="Permanent Vendors"
      />
      {showAdd && (
        <PermanentVendorsAdd
          bannerTitle="Add New Vendor"
          isOpen={showAdd}
          onClose={() => {
            setShowAdd(false);
          }}
          refetch={refetch}
        />
      )}
      <PermanentVendorsContainerPage>
        {isLoading && [...Array(20)].map((item: any) => <GroupItemSkeleton />)}
        {vendors?.map((vendor: any, index: number) => (
          <GroupItem
            key={index}
            image={vendor.photo}
            onClick={() => navigate(`${ROUTES.VENDOR_PROFILE}/${vendor.id}`)}
            {...vendor}
            type="Permanent Vendor"
          />
        ))}
      </PermanentVendorsContainerPage>
    </>
  );
};

// profile ? (
//   <VendorProfile
//     vendor={profile}
//     theme={theme}
//     bannerTitle="Vendor Profile"
//     back={() => setProfile(false)}
//   />
// ) : (
