import { MenuItem, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { addGroupMember } from '../../api';
import { CustomButton } from '../../common';
import { UserInput } from '../../common/Inputs';
import { useNotification } from '../../hooks/useNotification';
import { AddModalContent, CustomText, FlexInputContainer, ModalUser } from '../Users/Users.style';

interface GroupDetailAddMemberProps {
  groupId: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
  groupType: 'student' | 'parent' | 'staff';
}

export const GroupDetailAddMember: React.FC<GroupDetailAddMemberProps> = ({
  groupId,
  isOpen,
  onClose,
  refetch,
  groupType,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<string | null>('');
  const { notifyError, notifySuccess } = useNotification();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!userId) {
      notifyError('Select member you want to add!');
    } else {
      setLoading(true);
      const res = await addGroupMember({ userId, groupId });
      if (res.status === 200) {
        refetch();
        notifySuccess(res.data.message);
        setLoading(false);
        onClose();
      } else {
        notifyError(res.data.message);
        setLoading(false);
      }
    }
  };

  return (
    <ModalUser open={isOpen} onClose={onClose} onSubmit={handleSubmit}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          ADD NEW MEMBER
        </CustomText>

        <FlexInputContainer>
          <UserInput
            onChange={(event: any, obj: any) => {
              if (obj?.uid) {
                setUserId(obj.uid);
              } else {
                setUserId('');
              }
            }}
            value={userId}
            info="Member Name"
            userType={groupType}
          />
        </FlexInputContainer>

        <CustomButton content="confirm" width="100%" type="submit" loading={loading} />
      </AddModalContent>
    </ModalUser>
  );
};
