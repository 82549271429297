import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomInput } from './Input';
import { InputFieldProps } from './Input';
import { InputWrapper } from './InputWrapper';

interface AutocompletePropTypes extends InputFieldProps {
  isDisabledOption?: any;
  disabled?: boolean;
  onInputChange?: any;
}

export const AutocompleteInput: React.FC<AutocompletePropTypes> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  options,
  isDisabledOption,
  disabled,
  defaultValue,
  onInputChange,
  ...rest
}) => {
  return (
    <InputWrapper width={width} alignitems={alignitems} info={info}>
      <Autocomplete
        disabled={disabled}
        disablePortal
        defaultValue={defaultValue}
        options={options ? options : []}
        style={{ width: '100%' }}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionDisabled={(option) => isDisabledOption?.(option)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <CustomInput {...params} {...rest} />}
      />
    </InputWrapper>
  );
};
