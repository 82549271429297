import React from 'react';
import CountUp from 'react-countup';

import { CustomButton } from '../../common';
import { SmallCircle } from '../RouteInfo/RouteInfo.style';
import { People } from '../../svg/People';
import { Skeleton, useTheme } from '@mui/material';
import { LastCard } from './SchoolProfile.style';
import { Arrow } from '../../svg/Arrow';
import { Flex, WhiteContainer } from '../../common/Containers';
import { CustomText } from '../../pages/Users/Users.style';

export const UserCountCard: React.FC<{ title: string; color: string; total: number }> = ({
  title,
  color,
  total,
}) => {
  const theme = useTheme();
  return (
    <WhiteContainer>
      <Flex>
        <Flex>
          <SmallCircle color={color} size={50}>
            <People color="white" />
          </SmallCircle>
          <CustomText bold={700} fontSize={1.2}>
            {title}
          </CustomText>
        </Flex>
        <CustomText bold={700} color={theme.color.darkerBlue} fontSize={1.7}>
          {total ? <CountUp end={total} /> : 0}
        </CustomText>
      </Flex>
    </WhiteContainer>
  );
};

export const LastUserCountCard: React.FC<{ title: string; total: number }> = ({ title, total }) => {
  const theme = useTheme();

  return (
    <LastCard>
      <Flex>
        <Flex>
          <SmallCircle color="white" size={50}>
            <People color={theme.color.darkerBlue} />
          </SmallCircle>
          <CustomText bold={700} color="white" fontSize={1.2}>
            {title}
          </CustomText>
        </Flex>
        <CustomText bold={700} color="white" fontSize={1.7}>
          {total ? <CountUp end={total} /> : 0}
        </CustomText>
      </Flex>
    </LastCard>
  );
};

export const Buttons = () => {
  const theme = useTheme();
  return (
    <Flex noFlex>
      <CustomButton width="80%">
        <Flex contectCenter gap={10} p={5}>
          Admin consoles <Arrow color="white" />
        </Flex>
      </CustomButton>
      <CustomButton bgcolor={theme.color.lightGrey} width="20%">
        <Flex p={5}>
          <CustomText>invoices</CustomText>
        </Flex>
      </CustomButton>
    </Flex>
  );
};

export const InfoItem: React.FC<{ title: string; content?: string | undefined }> = ({
  title,
  content,
}) => {
  return (
    <Flex column noCenter gap={1} p={0} noFlex>
      <CustomText>{title}</CustomText>
      {content ? (
        <CustomText bold={700}>{content}</CustomText>
      ) : (
        <Skeleton width="100%" height="24px" />
      )}
    </Flex>
  );
};
