import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getSchoolBillTransactionsStudent } from '../api';

export const useTransactionSchoolBill = (id: string) => {
  const [totalSchoolBill, setTotalSchoolBill] = useState(null);

  //   let { data, isLoading } = useQuery(['transactions', id], () =>
  //     getSchoolBillTransactionsStudent({
  //       studentId: id,
  //     }),
  //   );

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['transactions', id],
      async ({ pageParam = 1 }) => {
        const res = await getSchoolBillTransactionsStudent({
          page: pageParam,
          studentId: id,
        });
        return res;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      setTotalSchoolBill(data.pages[0].data.data?.totalAmount);
    }
  }, [data]);

  return {
    totalSchoolBill,
    isLoading,
  };
};
