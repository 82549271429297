import { Divider, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../common';
import { Flex } from '../../common/Containers';
import { UserInput } from '../../common/Inputs';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';

export const AddStudentModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: any;
  loading: boolean;
}> = ({ isOpen, onClose, loading, onSubmit }) => {
  const theme = useTheme();
  const [value, setValue] = useState(null);
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent style={{ overflow: 'visible' }}>
        <Flex column gap="20">
          <Flex column gap="0px" noCenter>
            <CustomText
              fontSize={1.4}
              bold={700}
              uppercase
              color={theme.color.darkerBlue}
              margin="0 0 20px 0"
            >
              Add Student
            </CustomText>
            <Divider flexItem />
          </Flex>
          <UserInput
            info="Student Name"
            onChange={(event: any, obj: any) => {
              setValue(obj.uid);
            }}
            value={value}
            userType="student"
          />
          <CustomButton
            content="Submit"
            width="100%"
            onClick={() => onSubmit(value)}
            loading={loading}
          />
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
