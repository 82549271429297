import React from 'react';
import { Skeleton, useTheme } from '@mui/material';
import { InitialCircle } from '../../common';
import { LittleGroup } from '../../svg/LittleGroup';
import { CustomText } from '../Users/Users.style';
import { GroupdItemContainer, GroupItemMain, GroupItemSecondary } from './Groups.style';
import { Flex } from '../../common/Containers';
import { Info } from '../../svg/Info';
import moment from 'moment';

export interface GroupItemProps {
  name: string;
  id: string;
  type: string;
  vendorEmployees?: number;
  membersAmount?: number;
  image?: string;
  hexColor?: string;
  expiryDate?: number;
  onClick?: () => void;
}

export const GroupItem: React.FC<GroupItemProps> = ({
  name,
  type,
  id,
  vendorEmployees,
  membersAmount,
  hexColor,
  image,
  onClick,
  expiryDate,
}) => {
  const theme = useTheme();
  return (
    <GroupdItemContainer onClick={onClick}>
      <GroupItemMain>
        <InitialCircle img={image} bgcolor={hexColor} margin="4% 0 0 0" />
        <CustomText bold={700} margin="9% 0 0 0">
          {name}
        </CustomText>
        <CustomText bold={300}>{type && type?.toUpperCase()}</CustomText>
      </GroupItemMain>

      <GroupItemSecondary>
        <Flex gap="5px">
          <LittleGroup />
          <CustomText bold={700}>
            {membersAmount ? membersAmount : vendorEmployees || '0'}
          </CustomText>
        </Flex>
        {expiryDate && expiryDate < moment().unix() ? (
          <Flex gap="5px" noFlex>
            <Info />
            <CustomText color={theme.color.schollBillRed}>Expierd</CustomText>
          </Flex>
        ) : (
          expiryDate && <CustomText>{moment.unix(expiryDate).format('MM.DD.YYYY')}</CustomText>
        )}
      </GroupItemSecondary>
    </GroupdItemContainer>
  );
};

export const GroupItemSkeleton = () => {
  return (
    <GroupdItemContainer>
      <GroupItemMain>
        <Skeleton
          variant="circular"
          width="123px"
          height="123px"
          style={{ margin: '4% 0 0 0', padding: 0 }}
        />
        <Skeleton width="50%" style={{ margin: '9% 0 0 0', padding: 0 }} height="30px" />
        <Skeleton width="60%" style={{ margin: '0', padding: 0 }} height="40px" />
      </GroupItemMain>

      <GroupItemSecondary>
        <LittleGroup />
        <Skeleton width="20px" variant="circular" style={{ margin: '0 6%' }} height="20px" />
      </GroupItemSecondary>
    </GroupdItemContainer>
  );
};
