import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getVendors } from '../api';

export const useVendors = (type?: 'temporary' | 'permanent') => {
  const tabs: string[] = ['active', 'expired'];
  const [vendors, setVendors] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  const { data, isLoading, refetch } = useQuery(
    ['vendors', activeTab, type],
    () =>
      getVendors({
        type,
        status: type === 'temporary' ? tabs[activeTab] : null,
      }),
    { cacheTime: 0 },
  );

  useEffect(() => {
    if (data) {
      setVendors(data.data.data);
    }
  }, [data]);

  return {
    vendors,
    isLoading,
    refetch,
    setActiveTab,
    activeTab,
  };
};
