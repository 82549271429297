import React from 'react';
import { CustomText, UserHeaderContainer, UserListItemContent } from '../Users/Users.style';

export const MailListHeader = () => {
  return (
    <UserHeaderContainer>
      <UserListItemContent flex={15}></UserListItemContent>
      <UserListItemContent flex={20}>
        <CustomText bold={700}>Type</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={10}>
        <CustomText bold={700}>Date</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={10}>
        <CustomText bold={700}>Time</CustomText>
      </UserListItemContent>
    </UserHeaderContainer>
  );
};
