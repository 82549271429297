import { useEffect, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { ChartInfo, SmallCustomAvatar } from '../../common';
import { useTheme, Skeleton } from '@mui/material';
import { Right } from '../../pages/Overview/Overview.style';
import { Flex } from '../../common/Containers';
import { ChartContainer } from './Chart.style';
import { CustomText } from '../../pages/Users/Users.style';
import { LastsCreditBoughtContainer } from './CreditPieChart.style';
import { getAnalytics } from '../../api';
import moment from 'moment';

export const CreditPieChart = () => {
  const theme = useTheme();
  const [value, setValue] = useState<{
    unspentCreditsAmount: number;
    spentCreditsAmount: number;
    boughtCreditsAmount: number;
    lastBought?: {
      totalSpent: number;
      user: {
        imageUrl: string;
        name: string;
        uid: string;
      };
      transaction: {
        date: number;
        id: string;
        isPaid: boolean;
        isVendorConnected: boolean;
        paidType: string;
        paidUid: string;
        paymentId: string;
        paymentType: string;
        price: number;
        schoolId: string;
        type: string;
        uid: string;
      };
    };
  }>({
    unspentCreditsAmount: 0,
    spentCreditsAmount: 0,
    boughtCreditsAmount: 0,
  });

  const getData = async () => {
    const data = await getAnalytics('credits/last-bought', {});
    setValue(data.data.data);
  };

  const data = [
    { name: 'SPENT CREDIT', value: value.spentCreditsAmount, color: theme.color.schollBillRed },
    { name: 'UNSPENT CREDIT', value: value.unspentCreditsAmount, color: theme.color.darkerBlue },
  ];

  useEffect(() => {
    getData();
  }, []);
  const calculatePes = (number: number) => {
    const total = value.boughtCreditsAmount;
    return Math.floor((number * 100) / total);
  };
  return (
    <Flex column fullHeight>
      <Flex>
        <ChartContainer>
          <Flex p={20}>
            <ResponsiveContainer width="50%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="100%"
                  dataKey="value"
                >
                  {data.map((item: any, index: number) => (
                    <Cell key={index} fill={item.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Right>
              <ChartInfo
                bgcolor={theme.color.schollBillRed}
                banner="SPENT CREDIT"
                amount={value.spentCreditsAmount / 100}
                percentage={calculatePes(value.spentCreditsAmount)}
                noBorder
              />
              <ChartInfo
                bgcolor={theme.color.darkerBlue}
                banner="UNSPENT CREDIT"
                amount={value.unspentCreditsAmount / 100}
                percentage={calculatePes(value.unspentCreditsAmount)}
              />
            </Right>
          </Flex>
        </ChartContainer>
      </Flex>
      <Flex noFlex>
        <ChartContainer>
          <Flex p={20}>
            <Flex flex={2}>
              <CustomText bold={700} fontSize={1.5}>
                Lasts Credit Bought
              </CustomText>
            </Flex>
            <Flex flex={7}>
              <LastsCreditBoughtContainer>
                {!value?.lastBought ? (
                  <Flex flex={3}>
                    <Skeleton variant="circular" width="40px" height="40px" />
                    <Flex>
                      <Flex column noCenter gap="1px">
                        <Skeleton width="40%" height="24px" />
                        <Skeleton width="80%" height="24px" />
                      </Flex>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex flex={3}>
                    <SmallCustomAvatar image={value?.lastBought?.user.imageUrl} />
                    <Flex>
                      <Flex column noCenter gap="1px">
                        <CustomText>{value?.lastBought?.user.name}</CustomText>
                        <CustomText color={theme.color.depositDarkGreen} bold={700}>
                          {value?.lastBought?.transaction.price}
                        </CustomText>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex column gap="0px" flex={3}>
                  <CustomText>Date & Time</CustomText>
                  {value?.lastBought ? (
                    <CustomText bold={700}>
                      {moment.unix(value?.lastBought.transaction.date).format('MM/DD/YYYY - hh:mm')}
                    </CustomText>
                  ) : (
                    <Skeleton width="80%" height="24px" />
                  )}
                </Flex>
                <Flex flex={1}></Flex>
                <Flex column gap="0px" noCenter flex={2}>
                  <CustomText>Total Spent</CustomText>
                  <Flex>
                    {value?.lastBought ? (
                      <CustomText bold={700} color={theme.color.schollBillRed}>
                        {value?.lastBought.totalSpent / 100}
                      </CustomText>
                    ) : (
                      <Skeleton width="60%" height="24px" />
                    )}
                  </Flex>
                </Flex>
              </LastsCreditBoughtContainer>
            </Flex>
          </Flex>
        </ChartContainer>
      </Flex>
    </Flex>
  );
};
