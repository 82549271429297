import { UserListItemContainer } from '../../pages/Users/Users.style';
import { styled, Box } from '@mui/material';

export const ChartContainer = styled(UserListItemContainer)`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

export const ChartContainerContent = styled(Box)`
  padding: 20px;
  width: 100%;
  flex: 1;
`;
