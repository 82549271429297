import { Box, Button, Stack, styled } from "@mui/material";

export const TotalTransactionsLineHeader = styled(Box)<{ spanColor?: string }>`
  height: 16%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  position: relative;

  p span {
    color: ${({ spanColor, theme }) => spanColor || theme.palette.common.black};
  }

  @media(max-width: 1024px) {
    height: 24%;
    padding: 0;
    margin: 1% 0;
    position: relative;
  }
`;

export const ChartBannerContainer = styled(Box)`
  @media(max-width: 1024px) {
    width: 100%;

    p {
      font-size: 0.9rem;
    }
  }
`;

export const StyledBtn = styled(Button)`
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.grey[400]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[400]};
  }
`;

export const MenuAndArrowStack = styled(Stack)`
  justify-content: flex-end;

  @media(max-width: 768px) {
    justify-content: center;
  }
`;
