import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { isConstructorDeclaration } from 'typescript';
import { importUsers } from '../../api';
import { AttachFile, CustomButton } from '../../common';
import { InputField, SelectField } from '../../common/Inputs/Input';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { CustomText } from '../Users/Users.style';
import { DataMigrationContainer, DataMigrationContent } from './DataMigration.style';
import { DataMigrationAttachFile } from './DataMigrationAttachFile';

export const DataMigration = () => {
  const { notifyError, notifySuccess } = useNotification();
  const [loading, setLoading] = useState(false);
  const { values, onChange, forceChange } = useForm<{
    role: string;
    file: any;
    notes: string;
  }>({
    role: '',
    file: '',
    notes: '',
  });
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const file = new FormData();
      file.append('file', values.file.file);
      const res = await importUsers({ role: values.role, notes: values.notes }, file);
      if (res.data.error) {
        notifyError(res.data.message);
        setLoading(false);
      } else {
        notifySuccess('Users successfuly imported!');
        setLoading(false);
      }
    } catch (err) {
      notifyError('Something went wrong!');
      setLoading(false);
    }
  };
  return (
    <>
      <RouteInfo />
      <DataMigrationContainer>
        <DataMigrationContent>
          <DataMigrationAttachFile
            id="attachFile"
            info="Attach File"
            onChange={(event: any) => forceChange({ file: event.target.files[0] }, 'file')}
          >
            <img src="/attach.png" alt="" />
            <CustomText margin="0 0 0 2%">Choose</CustomText>
          </DataMigrationAttachFile>
          <SelectField
            id="selectUserType"
            info="Select User Type"
            name="role"
            value={values.role}
            onChange={onChange}
          >
            {['student', 'parent', 'staff'].map((role: string, index: number) => (
              <MenuItem key={index} value={role}>
                {role}
              </MenuItem>
            ))}
          </SelectField>

          <InputField
            id="notes"
            name="notes"
            onChange={onChange}
            info="Notes"
            placeholder="Enter here ..."
            multiline
            rows={6}
          />

          <CustomButton content="IMPORT" loading={loading} width="100%" onClick={handleSubmit} />
        </DataMigrationContent>
      </DataMigrationContainer>
    </>
  );
};
