import { useTheme, Skeleton, CircularProgress } from '@mui/material';
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { ChartInfo } from '../../common';
import { ChartTitle } from '../../common/ChartTitle';
import { ChartContainer, ChartContainerContent } from './Chart.style';
import { Right } from '../../pages/Overview/Overview.style';
import { Flex } from '../../common/Containers';
import { CustomText } from '../../pages/Users/Users.style';

interface PieChartPropTypes {
  data: any[];
  color?: string;
  title: string;
  openFilter?: () => void;
  filter?: any;
  loading: boolean;
  XLineName?: string;
  YLineName?: string;
  noFormat?: boolean;
  noPrefix?: boolean;
  chartInfo: {
    bgcolor: string;
    banner: string;
    amount: number;
  }[];
  percentageTotal: number;
  clearFilter?: () => void;
  initialFilter?: any;
  realFilter?: any;
}

export const CustomPieChart: React.FC<PieChartPropTypes> = ({
  data,
  title,
  openFilter,
  filter,
  loading,
  percentageTotal,
  clearFilter,
  realFilter,
  initialFilter,
  chartInfo,
}) => {
  const theme = useTheme();
  const calculatePes = (number: number) => {
    return Math.floor((number * 100) / percentageTotal);
  };
  return (
    <ChartContainer>
      <ChartTitle
        title={title}
        onClick={openFilter}
        filter={filter}
        clearFilter={clearFilter}
        realFilter={realFilter}
        initialFilter={initialFilter}
      />
      <ChartContainerContent>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.color.darkerBlue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <Flex fullHeight>
              <Flex p={20} fullHeight>
                <ResponsiveContainer width="50%" height="100%">
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="100%"
                      dataKey="value"
                    >
                      {data.map((item: any, index: number) => (
                        <Cell key={index} fill={item.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Right>
                  {chartInfo.map((item: any) => (
                    <ChartInfo
                      bgcolor={item.bgcolor}
                      banner={item.banner}
                      amount={item.amount}
                      percentage={calculatePes(item.amount)}
                    />
                  ))}
                </Right>
              </Flex>
            </Flex>
          </ResponsiveContainer>
        )}
      </ChartContainerContent>
    </ChartContainer>
  );
};
