import { Stack, useTheme } from '@mui/material';
import React from 'react';

import { useAuth } from '../../hooks/useAuth';
import { CustomButton } from '../../common';
import { CustomText } from '../../pages/Users/Users.style';
import { LogoutContent, LogoutModal } from './Logout.style';

interface LogoutProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Logout: React.FC<LogoutProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { logout } = useAuth();

  return (
    <LogoutModal open={isOpen} onClose={onClose}>
      <LogoutContent>
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          LOGOUT
        </CustomText>

        <CustomText>Are you sure to want to logout?</CustomText>
        <Stack direction="row" spacing={2}>
          <CustomButton
            content="No"
            width="150px"
            bgcolor={theme.palette.grey[300]}
            textcolor={theme.palette.common.black}
            onClick={onClose}
          />
          <CustomButton content="Yes" width="150px" onClick={logout} />
        </Stack>
      </LogoutContent>
    </LogoutModal>
  );
};
