import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getAllParents } from '../api';

export const useAllParents = (
  searchTerm?: null | string,
  filter?: { groupId?: null | string, schoolYear?: null | number },
) => {
  const [users, setUsers] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');

  const {
    data,
    error,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['users', searchTerm, filter, selectedGroupId],
    async ({ pageParam = 1 }) => {
      const res = await getAllParents({
        pageCount: 20,
        page: pageParam,
        name: searchTerm,
        ...filter,
      });
      return res;
    },
    {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.users }));
      setUsers(Object.values(arr));
    }
  }, [data]);

  return {
    users,
    isLoading,
    setSelectedGroupId,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
