import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Loading, LoadingFull } from '../../components/Loading';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { decodeUrl } from '../../helpers';
import { useGroup } from '../../hooks/useGroup';
import { UserDetailsContainer } from '../UserDetail/UserDetail.style';
import { ListContainer } from '../Users/Users.style';
import { GroupHeaderDetail } from './GroupHeaderDetail';
import { GroupListItemDetail } from './GroupListItemDetail';
import { GroupMainDetail } from './GroupMainDetail';
import { RemoveGroupMember } from './RemoveGroupMember';

export const GroupDetail = () => {
  const [searchMember, setSearchMember] = useState('');
  const { pathname } = useLocation();
  const id = decodeUrl(pathname);
  const queryClient = useQueryClient();

  const [memberToRemove, setMemberToRemove] = useState(null);
  const { group, delGroup, isLoading, refetch } = useGroup(id);

  const onChangeSearch = (e: any) => setSearchMember(e.target.value);

  const onDeleteClick = (user: any) => {
    setMemberToRemove(user);
  };

  if (isLoading) return <Loading />;

  return (
    <UserDetailsContainer>
      <RouteInfo
        banner="Groups"
        description="Create different groups to help manage users"
        smImage="/groups-sm.png"
        lastRoute={group && group.name}
      />

      <GroupMainDetail
        {...group}
        onDelete={async () => {
          await delGroup.mutateAsync(id);
          queryClient.invalidateQueries(['groups'], {
            refetchActive: true,
          });
        }}
        searchMember={searchMember}
        onChangeSearch={onChangeSearch}
        groupId={id}
        refetch={refetch}
      />

      <ListContainer>
        <GroupHeaderDetail />
        {group?.members.length > 0 &&
          group.members
            .filter((item: any) =>
              searchMember ? item.name.match(new RegExp(searchMember, 'gi')) : item,
            )
            .map((item: any, index: number) => (
              <GroupListItemDetail
                type={group.type}
                key={index}
                {...item}
                onDeleteClick={onDeleteClick}
              />
            ))}
      </ListContainer>
      {memberToRemove && (
        <RemoveGroupMember
          isOpen={!!memberToRemove}
          staff={memberToRemove}
          onClose={() => setMemberToRemove(null)}
          groupId={group.id}
        />
      )}
      {delGroup.isLoading && <LoadingFull />}
    </UserDetailsContainer>
  );
};
