import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getSchool } from '../api';

interface SchoolProps {
  isLoading: boolean;
  school: {
    address?: string;
    bank?: {};
    email?: string;
    founded?: number;
    hexColor?: number;
    imageUrl?: string;
    name?: string;
    parentCount?: number;
    phoneNumber?: string;
    squareData?: {};
    staffCount?: number;
    studentCount?: number;
    type?: string;
    uid?: string;
    website?: string;
  };
}

const initialState: SchoolProps = {
  isLoading: false,
  school: {},
};

export const getSchoolFromService = createAsyncThunk(
  'school/getsShoolFromService',
  async (_, thunkApi) => {
    try {
      const res = await getSchool();
      return res.data.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    setSchool: (state, action: PayloadAction<any>) => {
      state.school = action.payload;
    },
  },
  extraReducers: {
    [getSchoolFromService.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getSchoolFromService.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false;
      state.school = payload;
    },
    [getSchoolFromService.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setSchool } = schoolSlice.actions;
export default schoolSlice.reducer;
