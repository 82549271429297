import { Skeleton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SmallCustomAvatar } from '../../common';
import { ROUTES } from '../../constants';
import { UserDel, UserPen } from '../../svg/UserPen';
import {
  CustomText,
  UserListButtons,
  UserListItemContainer,
  UserListItemContent,
} from './Users.style';

export interface UserListItemProps {
  name?: string;
  email?: string;
  schoolYear?: number;
  group?: string;
  imageUrl?: string;
  uid?: string;
  role?: string;
  phoneNumber?: string;
  onDeleteClick?: (obj?: {
    name?: string;
    imageUrl?: string;
    email?: string;
    uid?: string;
  }) => void;
  noEdit?: boolean;
  schoolBill?: number;
}

export const UserListItem: React.FC<UserListItemProps> = ({
  name,
  email,
  schoolYear,
  group,
  uid,
  imageUrl,
  role,
  phoneNumber,
  onDeleteClick,
  noEdit,
  schoolBill,
}) => {
  const theme = useTheme();
  const [hoveredPen, setHoveredPen] = useState(false);
  const [hoveredDel, setHoveredDel] = useState(false);
  const navigate = useNavigate();
  return (
    <UserListItemContainer>
      <UserListItemContent
        gap={2}
        onClick={() => navigate(`${uid}`)}
        sx={{ cursor: 'pointer' }}
        flex={15}
      >
        <SmallCustomAvatar image={imageUrl} />
        <CustomText fontSize={1.1} bold={700}>
          {name}
        </CustomText>
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <CustomText>{email}</CustomText>
      </UserListItemContent>
      {role !== 'student' && (
        <UserListItemContent flex={5}>
          <CustomText>{phoneNumber}</CustomText>
        </UserListItemContent>
      )}
      {role === 'student' && (
        <UserListItemContent flex={10}>
          {schoolBill && schoolBill > 0 ? (
            <CustomText bold={700} color={theme.color.schollBillRed}>
              Unpaid
            </CustomText>
          ) : (
            <CustomText bold={700} color={theme.color.depositGreen}>
              Paid
            </CustomText>
          )}
        </UserListItemContent>
      )}
      {role === 'student' && (
        <UserListItemContent flex={5}>
          <CustomText>{schoolYear}</CustomText>
        </UserListItemContent>
      )}

      <UserListItemContent flex={7}>
        <UserListButtons>
          {!noEdit && (
            <UserPen
              fill={hoveredPen ? theme.palette.primary.light : ''}
              onMouseOver={() => setHoveredPen(true)}
              onMouseLeave={() => setHoveredPen(false)}
              onClick={() => navigate(`./${uid}/edit`)}
            />
          )}
          <UserDel
            fill={hoveredDel ? theme.palette.error.light : ''}
            onMouseOver={() => setHoveredDel(true)}
            onMouseLeave={() => setHoveredDel(false)}
            onClick={() => onDeleteClick?.({ name, imageUrl, email, uid })}
          />
        </UserListButtons>
      </UserListItemContent>
    </UserListItemContainer>
  );
};

export const UserListItemSkeleton: React.FC<UserListItemProps> = ({ role }) => {
  return (
    <UserListItemContainer>
      <UserListItemContent gap={2} flex={15}>
        <Skeleton variant="circular" width={37} height={37} />
        <Skeleton height="2rem" width={100} />
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <Skeleton height="1.7rem" width={100} />
      </UserListItemContent>
      {role !== 'student' && (
        <UserListItemContent flex={5}>
          <Skeleton height="1.7rem" width={100} />
        </UserListItemContent>
      )}
      {role === 'student' && (
        <UserListItemContent flex={10}>
          <Skeleton height="1.7rem" width={70} />
        </UserListItemContent>
      )}
      {role === 'student' && (
        <UserListItemContent flex={5}>
          <Skeleton height="1.7rem" width={150} />
        </UserListItemContent>
      )}

      <UserListItemContent flex={7}>
        <UserListButtons></UserListButtons>
      </UserListItemContent>
    </UserListItemContainer>
  );
};
