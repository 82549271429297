import React, { useState, useEffect } from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ListContainer } from '../Users/Users.style';
import { TransactionsHeader } from './TransactionsHeader';
import { TransactionsItem } from './TransactionsItem';
import { HeaderNavigation } from '../../components/HeaderNavigation';
import { Flex } from '../../common/Containers';
import { CustomButton, FetchingNextPageButton } from '../../common';
import { Filter } from '../../svg/Filter';
import { SelectField } from '../../common/Inputs';
import { SmallFillter } from '../../components/Charts/SmallFilter';
import { useUserTransactions } from '../../hooks/useUserTransactions';
import { useLocation } from 'react-router-dom';
import { decodeUrl } from '../../helpers';
import { Stack, CircularProgress } from '@mui/material';
import { useInView } from 'react-intersection-observer';

export const UserTransactions = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const { pathname } = useLocation();
  const { ref, inView } = useInView();

  const [filter, setFilter] = useState({
    order: 'DESC',
    from: null,
    to: null,
  });
  const orderOptions = [
    {
      name: 'Sort by Date New',
      value: 'DESC',
    },
    {
      name: 'Sort by Date Old',
      value: 'ASC',
    },
  ];

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const id = decodeUrl(pathname);

  console.log(id);

  const { isLoading, transactions, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useUserTransactions({
      userId: id,
      ...filter,
    });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <RouteInfo />
      <HeaderNavigation title="View Transactions">
        <Flex fullHeight>
          <Flex></Flex>
          <Flex>
            <SelectField
              value={filter.order}
              name="term"
              id="term"
              onChange={(e: any) => {
                setFilter((prev) => ({
                  ...prev,
                  order: e.target.value,
                }));
              }}
              options={orderOptions}
            />
          </Flex>
          <CustomButton content={<Filter />} height="56px" onClick={() => setFilterOpen(true)} />
        </Flex>
      </HeaderNavigation>
      <ListContainer>
        <TransactionsHeader />
        {transactions &&
          transactions.map((transactions: any) =>
            Object.values(transactions).map((transaction: any, index: number) => (
              <TransactionsItem key={index} {...transaction} />
            )),
          )}
        {Object.values?.(transactions?.[0])?.length > 0 && (
          <FetchingNextPageButton onClick={() => fetchNextPage()} disabled ref={ref}>
            {isFetchingNextPage ? (
              <Stack alignItems="center" direction="row">
                Loading more... <CircularProgress size={16} />
              </Stack>
            ) : (
              'No more items'
            )}
          </FetchingNextPageButton>
        )}
      </ListContainer>
      <SmallFillter handleClose={() => setFilterOpen(false)} onSave={onSave} open={filterOpen} />
    </div>
  );
};
