import moment from 'moment';
import { Skeleton } from '@mui/material';
import { InitialCircle } from '../../common';
import { Flex } from '../../common/Containers';
import { SmallCalendar } from '../../svg/SmallCalendar';
import { SmallClock } from '../../svg/SmallClock';
import { ElementaryDataContainer, UserMainInfoContainer } from '../UserDetail/UserDetail.style';
import { CustomText } from '../Users/Users.style';

export const TransactionDetailsHeader: React.FC<{
  isLoading: boolean;
  imageUrl: string;
  name: string;
  date: number;
}> = ({ isLoading, imageUrl, name, date }) => {
  return (
    <UserMainInfoContainer noBorder>
      <ElementaryDataContainer>
        {!isLoading ? (
          <InitialCircle img={imageUrl} />
        ) : (
          <Skeleton height={123} width={123} variant="circular" />
        )}
        <Flex column noCenter>
          {isLoading ? (
            <Skeleton height="2rem" width={200} />
          ) : (
            <CustomText fontSize={1.2} bold={700}>
              {name}
            </CustomText>
          )}
          {isLoading ? (
            <Skeleton height="2rem" width={123} />
          ) : (
            <Flex gap={10} noFlex>
              <SmallCalendar />
              <CustomText>{moment.unix(date).format('dddd, MMMM, DD, YYYY')}</CustomText>
              <SmallClock />
              <CustomText>{moment.unix(date).format('hh:mm')}</CustomText>
            </Flex>
          )}
        </Flex>
      </ElementaryDataContainer>
    </UserMainInfoContainer>
  );
};
