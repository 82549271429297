import { Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Switch } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { randomIntFromInterval } from "../../../../helpers";
import { CustomText } from "../../../Users/Users.style";
import { DataContainer } from "../../Analytics.style";
import { TotalTransactionsLineHeader } from "../AnalyticTransactions/AnalyticTransactions.style";
import { data } from "../AnalyticUsers/AnalyticTotalUsers";

const itemsData = data.map((item) => ({ name: item.name, value: randomIntFromInterval(0, 20)}));

export const ExportedTickets = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>Exported Tickets: 35</CustomText>
        <CustomText>2021 / All Months</CustomText>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Switch />
        <IconButton size="large">
          <Menu />
        </IconButton>
      </Stack>
    </TotalTransactionsLineHeader>
    <ResponsiveContainer height="83%" width="100%">
      <BarChart
        height={401}
        width={771}
        data={itemsData}
      >
        <CartesianGrid vertical={false} />
        <Tooltip cursor={false} />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis dataKey="name" tickLine={false} tickMargin={16} />
        <Bar dataKey="value" radius={[10,10,0,0]} barSize={35} fill="#FCF20C" />
      </BarChart>
    </ResponsiveContainer>
  </DataContainer>
)
