import React, { forwardRef, Ref } from 'react';
import { CustomInput } from './Input';

type InputComponent<InputComponentProps> = (
  props: InputComponentProps,
) => JSX.Element | React.ComponentClass<InputComponentProps, any>;

const InputPhoneField: any = forwardRef<JSX.Element>((props: any, ref: any) => {
  return <CustomInput {...props} inputRef={ref} />;
});

export default InputPhoneField;
