import { Box, styled, useTheme } from '@mui/material';
import React from 'react';

import { CustomText } from '../pages/Users/Users.style';

const Circle = styled(Box)<{
  height?: string;
  width?: string;
  bgcolor?: string;
  margin?: string;
  img?: string;
}>`
  height: ${({ height }) => height || '123px'};
  width: ${({ width }) => width || '123px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ bgcolor, theme }) => bgcolor || theme.palette.grey[500]};
  margin: ${({ margin }) => margin || '0'};
  position: relative;

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    background: url(${({ img }) => img});
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.palette.common.white} !important;
    }
  }
`;

interface InitialCircleProps {
  letters?: string;
  height?: string;
  width?: string;
  bgcolor?: string;
  margin?: string;
  img?: string;
}

export const InitialCircle: React.FC<InitialCircleProps> = ({
  letters,
  height,
  width,
  bgcolor,
  margin,
  img,
}) => {
  const theme = useTheme();

  return (
    <Circle height={height} width={width} bgcolor={bgcolor} margin={margin} img={img}>
      <CustomText fontSize={3.4} bold={700} color={theme.palette.common.white}>
        {letters}
      </CustomText>
    </Circle>
  );
};
