import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { Flex } from '../../common/Containers';
import { UserCard } from '../UserAnalytic/UserCard';
import { ChartContainer } from './Chart.style';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Right } from '../../pages/Overview/Overview.style';
import { UserChartInfo } from '../../common';
import { getAnalytics } from '../../api';

export const UserCount = () => {
  const theme = useTheme();
  const [value, setValue] = useState({
    lastAddedParentDate: 0,
    lastAddedStaffDate: 0,
    lastAddedStudentDate: 0,
    parentCount: 0,
    staffCount: 0,
    studentCount: 0,
  });
  const data = [
    {
      name: 'STUDENT',
      value: value.studentCount,
      color: theme.color.darkerBlue,
      lastAdded: value.lastAddedStudentDate,
    },
    {
      name: 'PARENT',
      value: value.parentCount,
      color: theme.color.parentYellow,
      lastAdded: value.lastAddedParentDate,
    },
    {
      name: 'STAFF',
      value: value.staffCount,
      color: theme.color.schollBillRed,
      lastAdded: value.lastAddedStaffDate,
    },
  ];

  const getData = async () => {
    const data = await getAnalytics('users', {});
    setValue(data.data.data);
  };
  useEffect(() => {
    getData();
  }, []);

  const calculatePes = (number: number) => {
    const total = value.studentCount + value.parentCount + value.staffCount;
    return Math.floor((number * 100) / total);
  };
  return (
    <>
      <Flex fullHeight>
        {data.map((item) => (
          <UserCard title={item.name} count={item.value} color={item.color} />
        ))}
      </Flex>
      <Flex fullHeight>
        <ChartContainer>
          <Flex p={20}>
            <ResponsiveContainer width="50%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius="100%"
                  dataKey="value"
                >
                  {data.map((item: any, index: number) => (
                    <Cell key={index} fill={item.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Right>
              {data.map((item) => (
                <UserChartInfo
                  bgcolor={item.color}
                  banner={item.name}
                  amount={item.value}
                  lastAdded={item.lastAdded}
                  percentage={calculatePes(item.value)}
                  noBorder
                />
              ))}
            </Right>
          </Flex>
        </ChartContainer>
      </Flex>
    </>
  );
};
