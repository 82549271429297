import { Skeleton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallCustomAvatar } from '../../../common';
import { ROUTES } from '../../../constants';
import { UserDel } from '../../../svg/UserPen';
import { UserListItemProps } from '../../Users/UserListItem';
import {
  CustomText,
  UserListButtons,
  UserListItemContainer,
  UserListItemContent,
} from '../../Users/Users.style';

export const StaffItem: React.FC<UserListItemProps> = ({
  uid,
  name,
  email,
  phoneNumber,
  imageUrl,
  group,
  onDeleteClick,
}) => {
  const theme = useTheme();
  const [hoveredPen, setHoveredPen] = useState(false);
  const [hoveredDel, setHoveredDel] = useState(false);
  const navigate = useNavigate();
  return (
    <UserListItemContainer>
      <UserListItemContent
        gap={2}
        onClick={() => navigate(`${ROUTES.USER_DETAIL}/${uid}`)}
        sx={{ cursor: 'pointer' }}
        flex={15}
      >
        <SmallCustomAvatar image={imageUrl} />
        <CustomText fontSize={1.1} bold={700}>
          {name}
        </CustomText>
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <CustomText>{email}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <CustomText>{phoneNumber}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <CustomText>{group}</CustomText>
      </UserListItemContent>
      <UserListItemContent flex={7}>
        <UserListButtons>
          <UserDel
            fill={hoveredDel ? theme.palette.error.light : ''}
            onMouseOver={() => setHoveredDel(true)}
            onMouseLeave={() => setHoveredDel(false)}
            onClick={() => onDeleteClick?.({ name, imageUrl, email, uid })}
          />
        </UserListButtons>
      </UserListItemContent>
    </UserListItemContainer>
  );
};

export const StaffItemSkeleton = () => {
  return (
    <UserListItemContainer>
      <UserListItemContent gap={2} flex={15}>
        <Skeleton variant="circular" width={37} height={37} />
        <Skeleton height="2rem" width={100} />
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <Skeleton height="1.7rem" width={100} />
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <Skeleton height="1.7rem" width={100} />
      </UserListItemContent>
      <UserListItemContent flex={5}>
        <Skeleton height="1.7rem" width={150} />
      </UserListItemContent>
      <UserListItemContent flex={7}>
        <UserListButtons></UserListButtons>
      </UserListItemContent>
    </UserListItemContainer>
  );
};
