import { useTheme, IconButton, Skeleton } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SmallCustomAvatar } from '../../common';
import { Flex } from '../../common/Containers';
import { MailListItem, MailListItemContent } from '../Mail/Mail.style';
import { CustomText } from '../Users/Users.style';
import { SupportMarker } from './InternalSupport.style';
import { ExportTicket } from '../../svg/ExportTicket';

interface InternalSupportItemProps {
  createdBy: any;
  subject: string;
  lastMessage: any;
  date: number;
  agent: any;
  resolved: boolean;
  image?: string;
  onClick?: () => void;
}

export const InternalSupportItem: React.FC<InternalSupportItemProps> = ({
  // createdBy,
  lastMessage,
  date,
  agent,
  resolved,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <MailListItem padding="10px 20px" cursor="pointer" onClick={onClick}>
      <SupportMarker active={resolved} />
      <MailListItemContent flex={1.5}>
        <SmallCustomAvatar image={lastMessage?.from?.photo} />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Flex column noCenter gap="0px">
          <CustomText bold={700}>{lastMessage?.from?.name}</CustomText>
          <CustomText fontSize={0.9} bold={300}>
            {lastMessage?.from?.email}
          </CustomText>
        </Flex>
      </MailListItemContent>

      <MailListItemContent flex={10}>{lastMessage?.subject}</MailListItemContent>
      <MailListItemContent flex={5}>{moment.unix(date).format('DD/MM/YYYY')}</MailListItemContent>
      <MailListItemContent flex={5}>{moment.unix(date).format('HH:MM')}</MailListItemContent>
      <MailListItemContent flex={5}>{agent?.name || 'Unassigned'}</MailListItemContent>
      <MailListItemContent flex={5}>
        <CustomText color={resolved ? theme.color.depositDarkGreen : theme.color.schollBillRed}>
          {resolved ? 'Resolved' : 'Unresolved'}
        </CustomText>
      </MailListItemContent>
      <MailListItemContent flex={1}>
        <IconButton disabled={resolved}>
          <ExportTicket color={!resolved && theme.color.darkerBlue} />
        </IconButton>
      </MailListItemContent>
    </MailListItem>
  );
};

export const InternalSupportItemSkeleton: React.FC = () => {
  return (
    <MailListItem padding="10px 20px" cursor="pointer">
      <MailListItemContent flex={1.5}>
        <Skeleton width={37} height={37} variant="circular" />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Flex column noCenter gap="0px">
          <Skeleton width={140} height="1.5rem" />
          <Skeleton width={140} height="1.2rem" />
        </Flex>
      </MailListItemContent>

      <MailListItemContent flex={10}>
        <Skeleton width={140} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={5}>
        <Skeleton width={140} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={5}>
        <Skeleton width={140} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={5}>
        <Skeleton width={140} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={5}>
        <Skeleton width={140} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={1}></MailListItemContent>
    </MailListItem>
  );
};
