import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton } from './CustomButton';

const CloseFilter: React.FC<{ clearFilter: () => void }> = ({ clearFilter }) => {
  return (
    <CustomButton
      onClick={clearFilter}
      content={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          <CloseIcon /> Clear filters
        </div>
      }
    />
  );
};

export default CloseFilter;
