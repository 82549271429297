import { Divider, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Flex } from '../../common/Containers';
import { useNotification } from '../../hooks/useNotification';
import { AddModalContent, CustomText, ModalUser } from '../Users/Users.style';
import CloseIcon from '@mui/icons-material/Close';
import { CustomInput } from '../../common/Inputs/Input';
import { CustomButton } from '../../common';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';

const UpdateSchoolBillModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  amount?: number | null;
  parentId: string;
  updateAmount: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>;
}> = ({ isOpen, onClose, amount, updateAmount, parentId }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(amount);
  const [editValue, setEditValue] = useState(null);

  const submit = async () => {
    if (!amount) {
      notifyError('There are no school bill!');
      return;
    }
    if (!value) {
      notifyError('Value cant be 0!');
      return;
    }
    if (!amount || !value || amount < value) {
      notifyError('Amount cant be grater than school bill!');
      return;
    }
    setLoading(true);
    await updateAmount.mutateAsync({ parentId, amount: value });
    setLoading(false);
    onClose();
  };

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <ModalUser open={isOpen} onClose={handleClose}>
      <AddModalContent component="form">
        <Flex spaceBetween>
          <CustomText uppercase color={theme.color.darkerBlue} bold={700} fontSize={1.2}>
            Update Amount
          </CustomText>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Flex>
        <Divider />
        <Flex column>
          <CustomText>Enter an amount to be deducted from outstanding school bill debt.</CustomText>
          <CustomText bold={700}>Amount</CustomText>
          {!edit ? (
            <CustomText fontSize={2} bold={700} color={theme.color.schollBillRed}>
              £{!value ? 0 : value / 100}
            </CustomText>
          ) : (
            <CustomInput
              type="number"
              value={editValue}
              onChange={(e: any) => setEditValue(e.target.value)}
            />
          )}
          {!edit ? (
            <CustomButton width="100%" loading={loading} onClick={submit}>
              Update Amount
            </CustomButton>
          ) : (
            <CustomButton
              width="100%"
              onClick={() => {
                editValue && setValue(editValue * 100);
                setEdit(false);
              }}
            >
              Save
            </CustomButton>
          )}
          {!edit ? (
            <CustomButton
              width="100%"
              bgcolor={theme.color.lightGrey}
              textcolor={theme.palette.common.black}
              onClick={() => setEdit(true)}
              disabled={loading}
            >
              Edit amount
            </CustomButton>
          ) : (
            <CustomButton
              width="100%"
              bgcolor={theme.color.lightGrey}
              textcolor={theme.palette.common.black}
              onClick={() => setEdit(false)}
              disabled={loading}
            >
              Cancel
            </CustomButton>
          )}
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};

export default UpdateSchoolBillModal;
