import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteInfo } from '../../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../../constants';
import { useVendors } from '../../../hooks/useVendors';
import { GroupItem, GroupItemSkeleton } from '../../Groups/GroupItem';
import { AddTemporaryVendor } from './AddTemporaryVendor';
import { PermanentVendorsContainerPage } from '../PermanentVendors/PermanentVendors.style';
import { TemporaryVendorsTabs } from './TemporaryVendorsTabs';

export const tabs: string[] = ['ACTIVE', 'EXPIRED'];

export const TemporaryVendors = () => {
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = useState(false);
  const { vendors, setActiveTab, activeTab, refetch, isLoading } = useVendors('temporary');

  const changeValue = (event: any, newValue: number) => setActiveTab(newValue);

  return (
    <>
      <RouteInfo
        banner="Temporary Vendors"
        description="Create and manage Temporary Vendors"
        withButton
        onClick={() => setShowAdd(true)}
        lastRoute="Temporary Vendors"
      />

      <AddTemporaryVendor
        bannerTitle="Add New Vendor"
        onClose={() => {
          refetch();
          setShowAdd(false);
        }}
        isOpen={showAdd}
      />
      <>
        <TemporaryVendorsTabs value={activeTab} changeValue={changeValue} />

        <PermanentVendorsContainerPage>
          {isLoading && [...Array(20)].map((item: any) => <GroupItemSkeleton />)}
          {vendors?.map((vendor: any, index: number) => (
            <GroupItem
              key={index}
              image={vendor.photo}
              onClick={() => navigate(`${ROUTES.VENDOR_PROFILE}/${vendor.id}`)}
              {...vendor}
              type="Temporary Vendor"
            />
          ))}
        </PermanentVendorsContainerPage>
      </>
    </>
  );
};
