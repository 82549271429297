import React, { useEffect, useState } from 'react';
import { Divider, MenuItem, useTheme } from '@mui/material';
import { useNotification } from '../../hooks/useNotification';
import { useUsers } from '../../hooks/useUsers';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { CustomButton } from '../../common';
import { InputField, SelectField, PhoneNumberInput, AutocompleteInput } from '../../common/Inputs';
import { useForm } from '../../hooks/useForm';
import { UserAddType, UserType } from '../../types';
import { AddUserPhoto } from './AddUserPhoto';
import { CustomText, FlexInputContainer } from './Users.style';
import { createUser } from '../../api';
import { Flex } from '../../common/Containers';

const cities: string[] = ['London', 'New York', 'Los Angeles', 'Seeatle', 'Toronto', 'Miami'];

export const AddParent: React.FC<{
  onClose: () => void;
  refetch: any;
  loading: boolean;
  setLoading: any;
}> = ({ onClose, refetch, loading, setLoading }) => {
  const theme = useTheme();
  const { users } = useUsers('student');
  const [userOption, setUserOptions] = useState<any[]>([]);
  const { notifyError, notifySuccess } = useNotification();
  const { values, onChange, forceChange } = useForm<UserAddType>({
    role: 'parent',
    image: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    studentId: '',
    address: '',
    city: '',
  });

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmit = async () => {
    setLoading(true);

    if (
      !values.firstName ||
      !values.lastName ||
      !values.phoneNumber ||
      !values.email ||
      !values.image ||
      !values.city
    ) {
      notifyError('Please fill in all details!');
      setLoading(false);
      return;
    } else {
      if (!isValidPhoneNumber(values.phoneNumber)) {
        notifyError('Please provide valid phone number!');
        setLoading(false);
        return;
      }
      const data = { ...values };
      !data.studentId && delete data.studentId;
      try {
        const res = await createUser(data);
        if (res.data.error) {
          notifyError(res.data.message);
        } else {
          notifySuccess(res.data.message);
        }
        setLoading(false);
        refetch();
        onClose();
      } catch (err: any) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Flex column gap="0px" noCenter>
        <CustomText
          fontSize={1.4}
          bold={700}
          uppercase
          color={theme.color.darkerBlue}
          margin="0 0 20px 0"
        >
          Add Parent
        </CustomText>

        <Divider flexItem />
      </Flex>
      <FlexInputContainer>
        <AddUserPhoto size={7} onChange={(data: string) => forceChange(data, 'image')} />
      </FlexInputContainer>

      <FlexInputContainer>
        <InputField
          type="text"
          name="firstName"
          info="First Name"
          padding="4px 20px"
          placeholder="First Name"
          onChange={(e: any) => onChange(e)}
          value={values.firstName}
        />
        <InputField
          type="text"
          name="lastName"
          info="Last Name"
          padding="4px 20px"
          placeholder="Last Name"
          onChange={onChange}
          value={values.lastName}
        />
      </FlexInputContainer>
      <InputField
        type="email"
        name="email"
        info="Email"
        padding="4px 20px"
        placeholder="Email"
        onChange={onChange}
        value={values.email}
      />
      <Flex>
        <InputField
          type="text"
          name="city"
          info="City"
          placeholder="City Name"
          onChange={(e: any) => onChange(e)}
          value={values.city}
        />
        <PhoneNumberInput
          type="text"
          name="phoneNumber"
          info="PhoneNumber"
          placeholder="+1 992 9922"
          onChange={(value: string) => forceChange(value, 'phoneNumber')}
          value={values.phoneNumber}
        />
      </Flex>
      <InputField
        id="address"
        info="Address"
        name="address"
        placeholder="Enter here..."
        value={values.address}
        onChange={onChange}
      />

      <FlexInputContainer>
        <AutocompleteInput
          value={values.parentId}
          name="userId"
          id="students"
          placeholder="Select Student"
          info="Students"
          options={userOption}
          onChange={(event: any, obj: { uid: string }) =>
            obj?.uid && forceChange(obj.uid, 'studentId')
          }
        />
      </FlexInputContainer>

      <Flex alignEnd>
        <CustomButton
          content="SUBMIT"
          onClick={handleSubmit}
          width="100%"
          height="56px"
          type="button"
          loading={loading}
        />
      </Flex>
    </>
  );
};
