import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { decodeUrl } from '../../../helpers';
import { useVendorProfile } from '../../../hooks/useVendorProfile';
import { UserListHeader } from '../../Users/UserListHeader';
import { ListContainer } from '../../Users/Users.style';
import { HeaderNavigation } from '../../../components/HeaderNavigation';
import { RouteInfo } from '../../../components/RouteInfo/RouteInfo';
import { VendorProfileHeader } from '../../../components/Vendors/VendorProfileHeader';
import { EditPermanentVendor } from './EditPermanentVendor';
import { EditTemporaryVendor } from './EditTemporaryVendor';
import { DeleteVendorModal } from './DeleteVendorModal';
import { RemoveMemberModal } from './RemoveMemberModal';
import { AddVendorStaffModal } from './AddVendorStaffModal';
import { StaffItem, StaffItemSkeleton } from './StaffItem';
import { CustomLinkButton } from '../../../common/LinkButton';
import { ArrowRight } from '@mui/icons-material';

export const VendorProfile: React.FC = () => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [staffToRemove, setStaffToRemove] = useState(null);
  const { pathname } = useLocation();
  const id = decodeUrl(pathname);
  const { vendor, refetch, staff, isLoading } = useVendorProfile(id);

  const onDeleteClick = (user: any) => {
    setStaffToRemove(user);
  };

  return (
    <>
      <RouteInfo
        withButton
        description="Create and manage Permanent Vendors"
        buttonText="ADD NEW STAFF"
        lastRoute={vendor && vendor.name}
        onClick={() => setStaffModal(true)}
      />
      <HeaderNavigation title="Vendor Profile">
        <CustomLinkButton href="https://pupil-wallet-vendor.web.app/" target="_blank">
          Vendor Console <ArrowRight />
        </CustomLinkButton>
      </HeaderNavigation>
      <VendorProfileHeader
        isLoading={isLoading}
        {...vendor}
        openEdit={() => setEditModal(true)}
        openDelete={() => setDeleteModal(true)}
      />
      <ListContainer>
        <UserListHeader activeTab={2} />
        {isLoading && [...Array(20)].map((item: any) => <StaffItemSkeleton />)}
        {staff.map((user: any) => (
          <StaffItem {...user} key={user.id} noEdit onDeleteClick={onDeleteClick} />
        ))}
      </ListContainer>
      {vendor?.type === 'permanent' && (
        <EditPermanentVendor
          isOpen={editModal}
          refetch={refetch}
          {...vendor}
          onClose={() => {
            refetch();
            setEditModal(false);
          }}
        />
      )}
      {vendor?.type === 'temporary' && (
        <EditTemporaryVendor
          isOpen={editModal}
          refetch={refetch}
          {...vendor}
          onClose={() => {
            refetch();
            setEditModal(false);
          }}
        />
      )}
      <DeleteVendorModal
        isOpen={deleteModal}
        vendor={vendor}
        onClose={() => setDeleteModal(false)}
      />
      {vendor && (
        <RemoveMemberModal
          isOpen={!!staffToRemove}
          staff={staffToRemove}
          onClose={() => setStaffToRemove(null)}
          refetch={refetch}
          vendorId={vendor.id}
        />
      )}
      {staffModal && vendor && (
        <AddVendorStaffModal
          isOpen={staffModal}
          vendorId={vendor.id}
          refetch={refetch}
          onClose={() => setStaffModal(false)}
        />
      )}
    </>
  );
};
