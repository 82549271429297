import { Box, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { CustomButton, InitialCircle } from '../../common';
import { Flex } from '../../common/Containers';
import { InputField } from '../../common/Inputs/Input';
import { Filter } from '../../svg/Filter';
import { LittleGroup } from '../../svg/LittleGroup';
import { GroupFilterContainer } from '../Groups/Groups.style';
import { ElementaryDataContainer, UserMainInfoContainer } from '../UserDetail/UserDetail.style';
import { CustomText } from '../Users/Users.style';
import { GroupDetailAddMember } from './GroupDetailAddMember';

interface GroupMainDetailProps {
  name: string;
  type: 'student' | 'parent' | 'staff';
  membersAmount: number;
  groupId: string;
  onChangeSearch: (e: any) => void;
  searchMember: string;
  image?: string;
  hexColor?: string;
  onDelete?: () => void;
  refetch?: any;
}

export const GroupMainDetail: React.FC<GroupMainDetailProps> = ({
  name,
  type,
  membersAmount,
  groupId,
  onChangeSearch,
  searchMember,
  image,
  hexColor,
  onDelete,
  refetch,
}) => {
  const theme = useTheme();
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <UserMainInfoContainer>
      {showAddModal && (
        <GroupDetailAddMember
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          groupId={groupId}
          refetch={refetch}
          groupType={type}
        />
      )}

      <ElementaryDataContainer gap="44px">
        <InitialCircle img={image} bgcolor={hexColor} />
        <Flex column noCenter>
          <CustomText fontSize={1.6} bold={700}>
            {name}
          </CustomText>
          <CustomText bold={300}>{type?.toUpperCase()} GROUP</CustomText>
          <Flex gap={5} noFlex>
            <LittleGroup />
            <CustomText bold={700}>
              {`${membersAmount} ${membersAmount < 2 ? 'member' : 'members'}`}
            </CustomText>
          </Flex>
        </Flex>
      </ElementaryDataContainer>

      <ElementaryDataContainer alignItems="flex-end" column>
        <GroupFilterContainer height="55px" width="67%" gap={27}>
          <InputField
            type="text"
            placeholder="Search member..."
            onChange={onChangeSearch}
            value={searchMember}
          />
        </GroupFilterContainer>

        <GroupFilterContainer height="55px" gap={35}>
          <CustomButton
            content="Add New Member"
            width="30%"
            onClick={() => setShowAddModal(true)}
          />
          <CustomButton
            content="Delete Group"
            width="30%"
            bgcolor={theme.color.schollBillRed}
            onClick={onDelete}
          />
        </GroupFilterContainer>
      </ElementaryDataContainer>
    </UserMainInfoContainer>
  );
};
