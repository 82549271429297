import React, { useState } from 'react';
import { AddModalContent, ModalUser, CustomText } from './Users.style';
import { Divider, useTheme } from '@mui/material';
import { CustomButton, SmallCustomAvatar } from '../../common';
import { Flex } from '../../common/Containers';
import { deleteUser } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useQueryClient } from 'react-query';

export const DeleteUserModal: React.FC<{ isOpen: boolean; onClose: () => void; user: any }> = ({
  isOpen,
  onClose,
  user,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      const res = await deleteUser(user.uid);
      queryClient.invalidateQueries(['users'], {
        refetchActive: true,
      });
      onClose();
      notifySuccess('User deleted successfuly!');
      setLoading(false);
      navigate(ROUTES.USERS);
    } catch (err) {
      return err;
    }
  };
  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent component="form">
        <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
          Delete User
        </CustomText>
        <Divider />
        <Flex column noCenter gap={20}>
          <CustomText bold={700} fontSize={1.2}>
            Are you sure you want to delete this user?
          </CustomText>
          <Flex>
            <SmallCustomAvatar image={user?.imageUrl} />
            <Flex column noCenter gap="0px">
              <CustomText bold={700}>{user?.name}</CustomText>
              <CustomText>{user?.email}</CustomText>
            </Flex>
          </Flex>
          <Flex>
            <CustomButton
              content="No"
              width="100%"
              bgcolor={theme.color.buttonGrey}
              textcolor={theme.palette.common.black}
              onClick={onClose}
            />
            <CustomButton content="Yes" width="100%" loading={loading} onClick={handleDeleteUser} />
          </Flex>
        </Flex>
      </AddModalContent>
    </ModalUser>
  );
};
