import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Flex } from '../../common/Containers';

import { ElementaryDataContainer, UserMainInfoContainer } from '../UserDetail/UserDetail.style';
import { CustomText } from '../Users/Users.style';

interface ExternalSupportTicketDetailProps {
  onClick: () => void;
  children?: any;
}

export const ExternalSupportTicketDetail: React.FC<ExternalSupportTicketDetailProps> = ({
  onClick,
  children,
}) => (
  <UserMainInfoContainer margin="1% 0">
    <ElementaryDataContainer>
      <IconButton onClick={onClick}>
        <ArrowBack />
      </IconButton>
      <CustomText fontSize={1.2} bold={700}>
        Ticket Details
      </CustomText>
    </ElementaryDataContainer>
    <Flex flexEnd>{children}</Flex>
  </UserMainInfoContainer>
);
