import React, { ReactNode } from 'react';
import { ROUTES } from '../../constants';
import { Mail } from '../../svg/Mail';
import { Profile } from '../../svg/Profile';
import { House } from '../../svg/House';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { MuiIconContainer } from '../../components/AdminConsole/AdminConsole.style';
import { Notation } from '../../svg/Notation';
import { Headset } from '../../svg/Headset';

export interface OverviewItemType {
  banner: string;
  link: string;
  smImage: ReactNode | string;
  litleBanner?: string;
  totalNumber?: number;
  numberColor?: string;
  secondKey?: string;
  first?: string;
  second?: string;
  third?: string;
  invert?: boolean;
  firstValue?: string;
  secondValue?: string;
  thirdValue?: string;
  totalKeyName?: string;
  dataFromApiName:
    | 'totalUserCount'
    | 'vendorsData'
    | 'groupsData'
    | 'supportTicketsData'
    | 'externalSupportTicketsData'
    | 'usersData'
    | 'mailsData';
}

export const overviewSections: OverviewItemType[] = [
  {
    banner: 'USERS',
    smImage: <Profile />,
    link: ROUTES.USERS,
    dataFromApiName: 'usersData',
    litleBanner: 'Total number of users',
    totalKeyName: 'totalUserCount',
    totalNumber: 3000,
    first: 'Total number of students: ',
    second: 'Total number of parents: ',
    third: 'Total number of staff: ',
    firstValue: 'studentUserCount',
    secondValue: 'parentUserCount',
    thirdValue: 'staffUserCount',
  },
  {
    banner: 'VENDOR CONSOLE',
    smImage: <House />,
    link: ROUTES.VENDORS,
    dataFromApiName: 'vendorsData',
    litleBanner: 'Total number of vendors',
    totalKeyName: 'totalVendorCount',
    totalNumber: 18,
    first: 'Total number of permanent vendors: ',
    second: 'Total number of temporary vendors: ',
    third: 'Best selling vendor: ',
    firstValue: 'permanentVendorCount',
    secondValue: 'temporaryVendorCount',
    thirdValue: 'bestSellingVendorName',
  },
  {
    banner: 'GROUPS',
    smImage: (
      <MuiIconContainer>
        <PeopleAltIcon />
      </MuiIconContainer>
    ),
    link: ROUTES.GROUPS,
    dataFromApiName: 'groupsData',
    litleBanner: 'Total number of groups',
    totalKeyName: 'totalGroupCount',
    totalNumber: 23,
    first: 'Total number of student groups: ',
    second: 'Total number of parent groups: ',
    third: 'Total number of staff: ',
    firstValue: 'studentGroupCount',
    secondValue: 'parentGroupCount',
    thirdValue: 'staffGroupCount',
  },
  {
    banner: 'MAIL',
    smImage: <Mail />,
    link: ROUTES.MAIL,
    dataFromApiName: 'mailsData',
    secondKey: 'latestMail',
    litleBanner: 'Total Messages',
    numberColor: 'white',
    totalKeyName: 'mailsCount',
    first: 'LATEST MESSAGES',
    second: 'From: ',
    third: 'Subject: ',
    invert: true,
  },
  {
    banner: 'INTERNAL SUPPORT',
    smImage: <Notation />,
    link: ROUTES.INTERNAL_SUPPORT,
    dataFromApiName: 'supportTicketsData',
    litleBanner: 'Total number of unresolved issues',
    totalKeyName: 'totalSupportTicketCount',
    totalNumber: 23,
    numberColor: 'error',
    first: 'In the last 24 hours: ',
    firstValue: 'last24HoursSupportTicketCount',
  },
  {
    banner: 'EXTERNAL SUPPORT',
    smImage: <Headset />,
    link: ROUTES.EXTERNAL_SUPPORT,
    dataFromApiName: 'externalSupportTicketsData',
    litleBanner: 'Total number of exported issues',
    totalKeyName: 'totalExternalSupportTicketCount',
    totalNumber: 3,
    numberColor: 'warning',
    first: 'Total number of resolved issues: ',
    firstValue: 'resolvedExternalSupportTicketCount',
  },
];

export const arrowStyle = {
  position: 'absolute',
  top: '50%',
  right: '24px',
  transform: 'translateY(-50%)',
};
