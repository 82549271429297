import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { signInWithCustomToken, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { BEARER, USER } from '../config/axios';
import { ROUTES } from '../constants';

import { login } from '../api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

import { getSchoolFromService } from '../store/schoolSlice';

export type AuthContextType = {
  user: any | null;
  authError: string | null;
  loginUser: (email: string, password: string) => Promise<void>;
  logout: () => void;
  userLoading: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  authError: null,
  loginUser: () => Promise.resolve(),
  logout: () => {},
  userLoading: true,
});

const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [authError, setAuthError] = useState<string | null>(null);
  const navigate = useNavigate();

  const loginUser = async (email: string, password: string) => {
    setAuthError(null);
    try {
      const res = await login({ email, password });
      if (res.data.data.user.user.role === 'school' || res.data.data.user.user.role === 'staff') {
        setUser(res.data.data.user.user);
        await signInWithCustomToken(auth, res.data.data.token);
        const user: any = auth.currentUser;

        if (user.accessToken) {
          const idToken = user.accessToken;
          localStorage.setItem(BEARER, idToken);
          localStorage.setItem(USER, JSON.stringify(res.data.data.user.user));
          navigate('/');
        }
      } else {
        setAuthError('Wrong credencials');
      }
    } catch (error) {
      setAuthError('Wrong credencials');
    }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserLoading(false);
    } else {
    }
  });

  const logout = async () => {
    signOut(auth).then(() => {
      localStorage.removeItem(BEARER);
      localStorage.removeItem(USER);
      setUser(null);
      navigate(ROUTES.LOGIN);
    });
  };

  useEffect(() => {
    // set user from localstorage to be user details for navbar and other
    if (!!localStorage.getItem(USER)) {
      const userr: any = localStorage.getItem(USER) || {};
      setUser(JSON.parse(userr));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, authError, loginUser, logout, userLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
