import { Divider, MenuItem, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { CustomButton } from '../../common';
import { SelectField, AutocompleteInput } from '../../common/Inputs';
import { useForm } from '../../hooks/useForm';
import { useGroups } from '../../hooks/useGroups';
import { AddModalContent, CustomText, FlexInputContainer, ModalUser } from './Users.style';

interface FilterType {
  groupId: string | null;
  schoolYear: number | null;
}

interface StudentFilterModal {
  isOpen: boolean;
  onClose: () => void;
  onSave: (values: any) => void;
  openTab: string;
}

export const StudentFilterModal: React.FC<StudentFilterModal> = ({
  isOpen,
  onClose,
  onSave,
  openTab,
}) => {
  const theme = useTheme();
  const [groupOptions, setGroupOptions] = useState<any[]>([]);

  const { groups } = useGroups(openTab);

  useEffect(() => {
    const arr: any[] = [];
    groups?.map((groups: any) =>
      Object.values(groups).map((group: any, index: number) => arr.push(group)),
    );
    setGroupOptions(arr);
  }, [groups]);

  const { values, onChange, forceChange } = useForm<FilterType>({
    groupId: null,
    schoolYear: null,
  });

  return (
    <ModalUser open={isOpen} onClose={onClose}>
      <AddModalContent>
        <CustomText fontSize={1.4} uppercase bold={700} color={theme.color.darkerBlue}>
          {openTab} filter
        </CustomText>
        <Divider />
        <FlexInputContainer>
          <AutocompleteInput
            value={values.groupId}
            name="groupId"
            id="group"
            placeholder="All Groups"
            info="Groups"
            isDisabledOption={(option: any) => values.groupId && option.type !== values.groupId}
            options={groupOptions}
            onChange={(event: any, obj: { id: string }) => {
              if (obj?.id) {
                forceChange(obj.id, 'groupId');
              } else {
                forceChange(null, 'groupId');
              }
            }}
          />

          {openTab === 'student' && (
            <SelectField
              value={values.schoolYear}
              name="schoolYear"
              id="schoolYear"
              placeholder="All Years"
              onChange={(e: any) => {
                forceChange(e.target.value, 'schoolYear');
              }}
              info="Year"
              options={[
                { name: '9', value: 9 },
                { name: '10', value: 10 },
                { name: '11', value: 11 },
                { name: '12', value: 12 },
              ]}
            />
          )}
        </FlexInputContainer>
        <CustomButton content="FILTERING" width="100%" onClick={() => onSave(values)} />
      </AddModalContent>
    </ModalUser>
  );
};
