import React from 'react';

import { CustomText, UserListItemContainer } from '../Users/Users.style';
import { ElementaryDataContainer, UserDetailListContainer } from './UserDetail.style';
import { Location } from '../../svg/Location';
import { UserDetailMail } from '../../svg/UserDetailsMail';
import { Iphone } from '../../svg/Iphone';
import { Calendar } from '../../svg/Calendar';
import { GraduationHat } from '../../svg/GraduationHat';
import { LittleGroup } from '../../svg/LittleGroup';
import { BlueEye } from '../../svg/BlueEye';
import { SchoolBill, ParentSchoolBill } from '../../common';
import { Skeleton, useTheme } from '@mui/material';
import moment from 'moment';
import { Flex } from '../../common/Containers';

interface UserAllDataItemsProps {
  address: string;
  email: string;
  phoneNumber: string;
  role?: string;
  schoolYear?: number;
  dateOfBirth?: number;
  groups?: any[];
  city?: string;
  uid: string;
  isLoading?: boolean;
  reqPaymentModal: boolean;
  setReqPaymentModal: any;
}

export const UserAllDataItems: React.FC<UserAllDataItemsProps> = ({
  address,
  email,
  phoneNumber,
  role,
  schoolYear,
  dateOfBirth,
  city,
  groups,
  uid,
  isLoading,
  reqPaymentModal,
  setReqPaymentModal,
}) => {
  const theme = useTheme();
  return (
    <UserDetailListContainer>
      <Flex column gap="10px">
        {!isLoading ? (
          <>
            {role === 'student' && (
              <UserListItemContainer jcontent="flex-start">
                <ElementaryDataContainer width="100%" gap="16px">
                  <Calendar />
                  <ElementaryDataContainer width="75%" gap="6px">
                    <CustomText bold={700}>Date of Birth:</CustomText>
                    <CustomText bold={300}>
                      {dateOfBirth && moment.unix(dateOfBirth).format('MMMM DD, YYYY')}
                    </CustomText>
                  </ElementaryDataContainer>
                </ElementaryDataContainer>
              </UserListItemContainer>
            )}
            <UserListItemContainer jcontent="flex-start">
              <ElementaryDataContainer width="100%" gap="16px">
                <Location />
                <ElementaryDataContainer width="75%" gap="6px">
                  <CustomText bold={700}>Address:</CustomText>
                  <CustomText bold={300}>{address ? address : city ? city : 'unknown'}</CustomText>
                </ElementaryDataContainer>
              </ElementaryDataContainer>
            </UserListItemContainer>

            <UserListItemContainer jcontent="flex-start">
              <ElementaryDataContainer width="100%" gap="16px">
                <UserDetailMail />
                <ElementaryDataContainer width="75%" gap="6px">
                  <CustomText bold={700}>Email:</CustomText>
                  <CustomText bold={300}>{email}</CustomText>
                </ElementaryDataContainer>
              </ElementaryDataContainer>
            </UserListItemContainer>

            <UserListItemContainer jcontent="flex-start">
              <ElementaryDataContainer width="100%" gap="16px">
                <Iphone color={theme.color.darkerBlue} />
                <ElementaryDataContainer width="75%" gap="6px">
                  <CustomText bold={700}>Phone:</CustomText>
                  <CustomText bold={300}>{phoneNumber}</CustomText>
                </ElementaryDataContainer>
              </ElementaryDataContainer>
            </UserListItemContainer>
            {role === 'student' && (
              <UserListItemContainer jcontent="flex-start">
                <ElementaryDataContainer width="100%" gap="16px">
                  <GraduationHat />
                  <ElementaryDataContainer width="75%" gap="6px">
                    <CustomText bold={700}>Year:</CustomText>
                    <CustomText bold={300}>{schoolYear}</CustomText>
                  </ElementaryDataContainer>
                </ElementaryDataContainer>
              </UserListItemContainer>
            )}

            {/* {role === 'parent' && (
              <UserListItemContainer jcontent="flex-start">
                <ElementaryDataContainer width="100%" gap="16px">
                  <GraduationHat />
                  <ElementaryDataContainer width="75%" gap="6px">
                    <CustomText bold={700}>Number of students:</CustomText>
                    <CustomText bold={300}>{(students && students.length) || '0'}</CustomText>
                  </ElementaryDataContainer>
                </ElementaryDataContainer>
              </UserListItemContainer>
            )} */}
            <UserListItemContainer jcontent="flex-start">
              <ElementaryDataContainer width="100%" gap="16px">
                <LittleGroup />
                <ElementaryDataContainer width="75%" gap="6px">
                  <CustomText bold={700}>Group:</CustomText>
                  <CustomText bold={300}>
                    {groups && groups[0]?.name ? groups[0].name : 'No Group'}
                  </CustomText>
                </ElementaryDataContainer>
              </ElementaryDataContainer>
            </UserListItemContainer>
            {role === 'staff' && (
              <UserListItemContainer jcontent="flex-start">
                <ElementaryDataContainer width="100%" gap="16px">
                  <BlueEye />
                  <ElementaryDataContainer width="75%" gap="6px">
                    <CustomText bold={700}>Permissions:</CustomText>
                    <CustomText bold={300}>Analytics, Billing</CustomText>
                  </ElementaryDataContainer>
                </ElementaryDataContainer>
              </UserListItemContainer>
            )}
          </>
        ) : (
          [...Array(6)].map((item: any, index) => (
            <UserListItemContainer jcontent="flex-start" key={index}>
              <ElementaryDataContainer width="100%" gap="16px">
                <Skeleton width={23} height={23} variant="circular" />
                <ElementaryDataContainer width="75%" gap="6px">
                  <Skeleton width={220} height={23.99} />
                </ElementaryDataContainer>
              </ElementaryDataContainer>
            </UserListItemContainer>
          ))
        )}
        {role === 'student' && <SchoolBill desc="Total School Bill Amount" uid={uid} />}
        {role === 'parent' && (
          <ParentSchoolBill
            desc="Total School Bill Amount"
            uid={uid}
            reqPaymentModal={reqPaymentModal}
            setReqPaymentModal={setReqPaymentModal}
          />
        )}
      </Flex>
    </UserDetailListContainer>
  );
};
