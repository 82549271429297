import React, { useRef } from 'react';
import { InputFieldProps } from './Input';
import InputPhoneField from './PhoneForwardRef';
import PhoneInput from 'react-phone-number-input/input';
import { InputWrapper } from './InputWrapper';

export const PhoneNumberInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  onChange,
  value,
  ...rest
}) => {
  const ref: any = useRef<HTMLInputElement>();
  return (
    <InputWrapper width={width} alignitems={alignitems} info={info}>
      <PhoneInput
        international
        inputComponent={InputPhoneField}
        value={value}
        smartCaret={false}
        onChange={onChange}
        id={id}
        padding={padding}
        {...rest}
        ref={ref}
      />
    </InputWrapper>
  );
};
