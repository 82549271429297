import { styled, Box } from '@mui/material';
import { UserListItemContainer } from './Users.style';

export const EditUserContainer = styled(Box)`
  width: 100%;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditUserWhiteContainer = styled(UserListItemContainer)`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const EditPhotoPenContainer = styled(Box)`
  position: absolute;
  left: 100%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0);
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.darkerBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;
