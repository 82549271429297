import React, { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { BigFilter } from './BigFilter';
import { getAnalytics } from '../../api';
import { useTheme } from '@mui/material';
import { YearMonthFilter } from './YearMonthFilter';

const initialFilter = {
  year: '',
  month: '',
};

export const BankAccount = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('deposits/total-transfered-to-bank-account', {
      year: filter.year,
      month: filter.month,
    });
    setValue(data?.data.data.monthsData);
    setTotalValue(data?.data.data.total);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);
  const clearFilter = () => setFilter(initialFilter);
  return (
    <>
      <LineCharts
        data={value}
        title={`Total Transfered to Bank Account: ${totalValue ? totalValue / 100 : 0}$`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          year: filter.year,
          month: filter.month,
        }}
        loading={loading}
        color={theme.color.depositGreen}
      />

      <YearMonthFilter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// 'deposits/total-transfered-to-bank-account',
