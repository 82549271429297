import { useLocation } from 'react-router-dom';
import { Flex } from '../../common/Containers';
import { HeaderNavigation } from '../../components/HeaderNavigation';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { decodeUrl } from '../../helpers';
import { useTransaction } from '../../hooks/useTransaction';
import { ListContainer } from '../Users/Users.style';
import { TransactionDetailsHeader } from './TransactionDetailsHeader';
import { TransactionDetailsListHeader } from './TransactionDetailsListHeader';
import { TransactionProduct } from './TransactionProduct';
import { TransactionTotalPrice } from './TransactionTotalPrice';

export const TransactionsDetails = () => {
  const { pathname } = useLocation();
  const id = decodeUrl(pathname);
  const { transactionDetails, isLoading } = useTransaction(id);
  return (
    <>
      <RouteInfo noLastRoute />
      <HeaderNavigation title="Transactions Details" titleName={transactionDetails?.name} />
      <TransactionDetailsHeader isLoading={isLoading} {...transactionDetails} />
      <Flex column>
        <ListContainer>
          <TransactionDetailsListHeader />
          {transactionDetails?.order?.products &&
            transactionDetails.order?.products.map((transaction: any, index: number) => (
              <TransactionProduct key={index} {...transaction} />
            ))}
        </ListContainer>
        <TransactionTotalPrice
          type={transactionDetails?.order?.type}
          totalPrice={transactionDetails?.price}
        />
      </Flex>
    </>
  );
};
