import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { addStudentToParent, getUserId, removeStudentFromParent } from '../api';
import { ROUTES } from '../constants';
import { useNotification } from './useNotification';

export const useUser = (id: string) => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotification();
  const [user, setUser] = useState<any>(null);
  const { data, isLoading, refetch } = useQuery(['user', id], () => getUserId(id), {
    cacheTime: 20,
  });
  const [relatedProfiles, setRelatedProfiles] = useState<[]>([]);

  const addStudent = useMutation(addStudentToParent, {
    onSuccess: (res: any) => {
      if (!res.data.error) {
        notifySuccess(res.data.message);
        refetch();
      } else {
        notifyError(res.data.message);
        return;
      }
    },
  });

  const removeStudent = useMutation((vareables: any) => removeStudentFromParent(vareables), {
    onSuccess: (data: any, vareables: any) => {
      const newRelatedProfiles: [] = [...relatedProfiles];
      const arr: any = newRelatedProfiles.filter((item: any) => item.uid !== vareables.studentId);
      setRelatedProfiles(arr);
    },
  });

  useEffect(() => {
    if (data) {
      if (!data?.data?.data?.user) {
        navigate(ROUTES.USERS);
        notifyError('There is problem with this user!');
        return;
      }
      setUser(data.data.data.user);
      if (data.data.data.students.length > 0) {
        setRelatedProfiles(data.data.data.students);
      }
      if (data.data.data.parent) {
        setRelatedProfiles(data.data.data.parent);
      }
    }
  }, [data]);

  return {
    user,
    isLoading,
    relatedProfiles,
    removeStudent,
    addStudent,
    refetch,
  };
};
