export const groupByType = (data: any) => {
  const returnData = data.reduce((types: any, group: any) => {
    const type = group.type;
    if (!types[type]) {
      types[type] = [];
    }
    types[type].push(group);
    return types;
  }, {});

  return returnData;
};
