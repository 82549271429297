import { Box, styled } from '@mui/material';
import { GroupdItemContainer } from '../../Groups/Groups.style';
import { VendorsContent } from '../Vendors.style';

export const PermanentVendorsContainerPage = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 20px;
  gap: 20px;
`;

export const PermanentVendorsContainer = styled(VendorsContent)`
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
`;

export const AddVendor = styled(GroupdItemContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: none;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AddContainer = styled(Box)<{ margin?: string }>`
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 29px;
  margin: ${({ margin }) => margin || 0};
`;

export const BackContainer = styled(Box)<{
  justifyContent?: string;
  column?: boolean;
  padding?: string;
  borderBottom?: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  padding: ${({ padding }) => padding || '0'};
  border-top: 1px solid ${({ theme }) => theme.color.borderGrey};
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom ? `1px solid ${theme.color.borderGrey}` : 'none'};

  @media (max-width: 768px) {
    flex-direction: ${({ column }) => column && 'column'};

    button {
      width: 44%;
    }
  }
`;

export const Flex = styled(Box)<{ gap?: string }>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '0'};

  button {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const AddVendorForm = styled(Box)`
  width: 35%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
