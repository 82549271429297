import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import React from 'react';
import { CustomButton } from '../../common';
import { Flex } from '../../common/Containers';

import { ElementaryDataContainer, UserMainInfoContainer } from '../UserDetail/UserDetail.style';
import { CustomText } from '../Users/Users.style';

interface InternalSupportTicketDetailProps {
  agent?: string | null;
  onClick?: () => void;
  setAssignAgentModal: any;
}

export const InternalSupportTicketDetail: React.FC<InternalSupportTicketDetailProps> = ({
  agent,
  onClick,
  setAssignAgentModal,
}) => (
  <UserMainInfoContainer noResponsive margin="1% 0">
    <ElementaryDataContainer>
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
      <CustomText fontSize={1.2} bold={700}>
        Message Details
      </CustomText>
    </ElementaryDataContainer>
    <Flex flexEnd gap="5px">
      {agent && (
        <>
          <CustomText bold={700}>Agent:</CustomText>
          <CustomText>{agent}</CustomText>
        </>
      )}
      {!agent && <CustomButton content="Assign Agent" onClick={() => setAssignAgentModal(true)} />}
    </Flex>
  </UserMainInfoContainer>
);
