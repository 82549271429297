import React, { useEffect, useState } from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { usePermisionGroups } from '../../hooks/usePermisionGroups';
import { GroupItemsContainer } from '../Groups/Groups.style';
import { AddPermission } from './AddPermission';
import { PermissionItem } from './PermisionItem';
import { permissions } from './Permissions.constants';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { getPermissionsFromService } from '../../store/permisions';
import { items } from '../Analytics/pages/AnalyticTransactions/MostAddedToSchoolBill';
import { GroupItemSkeleton } from '../Groups/GroupItem';

export const Permissions = () => {
  // const dispatch = useDispatch();
  // const { permissions } = useAppSelector((state) => state.permissions);
  const [permissionItem, setPermissionItem] = useState(null);
  const { groups, refetch, isLoading } = usePermisionGroups();

  return (
    <>
      <RouteInfo />

      {permissionItem && (
        <AddPermission
          isOpen={!!permissionItem}
          onClose={() => setPermissionItem(null)}
          group={permissionItem}
          refetch={refetch}
        />
      )}
      <GroupItemsContainer>
        {isLoading &&
          [...Array(20)].map((item: any, index: number) => <GroupItemSkeleton key={index} />)}
        {groups?.map((group: any, index: number) => (
          <PermissionItem key={group.id} {...group} onClick={() => setPermissionItem(group)} />
        ))}
      </GroupItemsContainer>
    </>
  );
};
