import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { DashboardCircle } from '../../common';
import { useAppSelector } from '../../store';
import { getMyPermissionsFromService } from '../../store/permisions';
import { Links, links } from './AdminConsole.constants';
import { AdminConsoleContainer } from './AdminConsole.style';
import { getArrayOfSameKeys } from '../../utils/objectMutations';

export const AdminConsole = () => {
  const dispatch = useDispatch();
  const user: any = localStorage.getItem('user');
  const { myPermissions } = useAppSelector((state) => state.permissions);

  useEffect(() => {
    dispatch(getMyPermissionsFromService());
  }, []);

  return (
    <AdminConsoleContainer>
      {links.map((link: Links) => (
        <Link
          key={link.descriptionText}
          to={
            (JSON.parse(user).role === 'school' && link.link) ||
            (!!getArrayOfSameKeys(myPermissions, 'id').includes(link.id) && link.link) ||
            ''
          }
          // to={link.link || ''}
        >
          <DashboardCircle
            {...link}
            noPermissions={
              JSON.parse(user).role !== 'school' &&
              !getArrayOfSameKeys(myPermissions, 'id').includes(link.id)
            }
          />
        </Link>
      ))}
    </AdminConsoleContainer>
  );
};
