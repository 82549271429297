import { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { BigFilter } from './BigFilter';
import { data } from '../../pages/Analytics/pages/AnalyticDeposits/AnalyticDeposits';
import { getAnalytics } from '../../api';
import { useTheme } from '@mui/material';

const initialFilter = {
  year: '',
  term: '',
  month: '',
  user: '',
  group: {
    name: null,
    id: null,
  },
  name: {
    name: null,
    uid: null,
  },
};

export const TotalFees = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalValue, setTotalValue] = useState(0);
  const [value, setValue] = useState([]);
  const [filter, setFilter] = useState(initialFilter);

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('deposits/total-transaction-fees', {
      year: filter.year,
      term: filter.term,
      month: filter.month,
      role: filter.user,
      groupId: filter.group.id,
      userId: filter.name.uid,
    });
    setValue(data?.data.data.monthsData);
    setTotalValue(data?.data.data.total);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };

  const clearFilter = () => setFilter(initialFilter);
  return (
    <>
      <LineCharts
        data={value}
        title={`Total Transaction Fees: ${totalValue ? totalValue / 100 : 0}$`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          year: filter.year,
          term: filter.term,
          month: filter.month,
          user: filter.user,
          group: filter.group.name,
          name: filter.name.name,
        }}
        loading={loading}
        YLineName="studentTotal"
        secondLine={{ name: 'parentTotal', color: theme.color.parentYellow }}
        color={theme.color.depositGreen}
      />

      <BigFilter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2
