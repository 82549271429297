import { SchoolInfoContainer, SchoolInfoCardContent } from './SchoolProfile.style';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { Flex, WhiteContainer } from '../../common/Containers';

import { CustomText } from '../Users/Users.style';
import { CardImage } from './SchoolProfile.style';
import { Skeleton, useTheme } from '@mui/material';
import {
  UserCountCard,
  LastUserCountCard,
  Buttons,
  InfoItem,
} from '../../components/SchoolProfile/UserCountCard';
import { InfoTitle } from '../../components/InfoTitle';
import { useSchoolProfile } from '../../hooks/useSchoolProfile';

export const SchoolProfile = () => {
  const theme = useTheme();
  const { school } = useSchoolProfile();
  return (
    <>
      <RouteInfo />
      <SchoolInfoContainer>
        <WhiteContainer>
          <SchoolInfoCardContent>
            <Flex column p={20} gap={30}>
              <CardImage size={35} image={school?.imageUrl} />
              <CustomText bold={700} fontSize={1.5}>
                {school?.name || <Skeleton height={10} width={200} />}
              </CustomText>
            </Flex>
            <Flex column noFlex>
              <UserCountCard
                title="STUDENTS"
                total={school?.studentCount}
                color={theme.color.darkerBlue}
              />
              <UserCountCard
                title="PARENTS"
                total={school?.parentCount}
                color={theme.color.parentYellow}
              />
              <UserCountCard
                title="STUFF"
                total={school?.staffCount}
                color={theme.color.schollBillRed}
              />
              <LastUserCountCard
                title="TOTAL"
                total={school?.studentCount + school?.parentCount + school?.staffCount}
              />
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
        <WhiteContainer padding="0px" noFlex sx={{ height: 'fit-content' }}>
          <SchoolInfoCardContent>
            <InfoTitle title="School Information" />
            <Flex p={20} noCenter column gap={16}>
              <InfoItem title="Address" content={school?.address} />
              <InfoItem title="Phone" content={school?.phoneNumber} />
              <InfoItem title="Founded" content={school?.founded} />
              <InfoItem title="School types:" content={school?.type} />
              <InfoItem title="Website" content={school?.website} />
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
        <WhiteContainer padding="0px" noFlex sx={{ height: 'fit-content' }}>
          <SchoolInfoCardContent>
            <InfoTitle title="Payout Information" />
            <Flex p={20} noCenter column gap={16}>
              <InfoItem title="Country" content={school?.bank?.country} />
              <InfoItem title="Bank Name" content={school?.bank?.name} />
              <InfoItem title="Account Number" content={school?.bank?.accountNumber} />
              <InfoItem title="Swift Code" content={school?.bank?.swiftCode} />
              <InfoItem title="Iban Number" content={school?.bank?.ibanNumber} />
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
      </SchoolInfoContainer>
    </>
  );
};
