import React, { useEffect, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { FilterModal, FilterPropTypes } from '../FilterModal';
import { SelectField, YearInput, MonthInput, AutocompleteInput } from '../../common/Inputs';
import { Flex } from '../../common/Containers';
import { CustomButton } from '../../common';
import moment from 'moment';
import { useGroups } from '../../hooks/useGroups';
import { useUsers } from '../../hooks/useUsers';
import { MenuItem } from '@mui/material';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const YearMonthFilter: React.FC<BigFilterPropTypes> = ({ open, handleClose, onSave }) => {
  const { values, forceChange } = useForm<{
    year?: null | number;
    month?: null | number;
  }>({
    year: '',
    month: '',
  });

  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
            placeholder="All Years"
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            placeholder="All Month"
            info="Month"
          />
        </Flex>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};

{
  /* <SelectField
  id="parents"
  info="Parents"
  name="parentId"
  value={values.parentId}
  onChange={onChange}
  placeholder="Select parent"
> */
}
