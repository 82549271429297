import { Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import { CustomText } from "../../pages/Users/Users.style";
import { PaginationContainer } from "./Pagination.style";

interface PaginationComponentProps {
  showItems: number;
  count: number;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  showItems,
  count,
}) => {
  const [page, setPage] = useState(1);

  const handleChange = (_: any, value: number) => setPage(value);

  return (
    <PaginationContainer>
      <CustomText>
        Showed {page === Math.ceil(count / showItems) ? count : page * showItems} from {count} entries
      </CustomText>

      <Stack spacing={0}>
        <Pagination
          count={Math.ceil(count / showItems)}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Stack>
    </PaginationContainer>
  )
}
