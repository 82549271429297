import { capitalize } from '@mui/material';

export const decodeUrl = (lnk: string) => {
  const arr = lnk.split('/');
  return arr[arr.length - 1];
};

export const formatUrl = (lnk: string) =>
  lnk
    .split('/')
    .map((a: string) => capitalize(a))
    .join(' / ');
export const makeLink = (lnk: string) => lnk.split(' / ').map((item: string) => item.toLowerCase());
// .join('/')
// .replace(/\s/g, '')

export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
export const isEmpty = (object: {}) => {
  return Object.values(object).some((value: any) => !value);
};
export const hasValue = (object: {}) => {
  console.log(object);
  return Object.values(object).some((value: any) => value !== null);
};

export const thousandToK = (number: number) => {
  if (number / 10000 > 1) {
    return number / 1000 + 'k';
  }
  return number;
};

export const base64toBlob = (base64Data: any) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
};
