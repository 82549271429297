export const faqs = [
  {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }, {
    question: 'Frequently asked questions',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa enim nec dui nunc mattis enim ut tellus elementum. Lectus proin nibh nisl condimentum id. Quam viverra orci sagittis eu volutpat odio facilisis.',
  }
]
