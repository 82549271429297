import { Tab } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { CustomButton } from '../../common';
import CloseFilter from '../../common/CloseFilter';
import { Filter } from '../../svg/Filter';
import { FiltersContainer, TabsContainer } from '../Users/Users.style';
import { a11yProps } from '../Users/UsersControl';

interface TransactionsControlProps {
  tabs: string[];
  value: number;
  openFilter: () => void;
  changeValue: (event: any, newValue: number) => void;
  filter: any;
  initialFilter: any;
  clearFilter: () => void;
}

export const TransactionsControl: React.FC<TransactionsControlProps> = ({
  tabs,
  value,
  changeValue,
  openFilter,
  filter,
  initialFilter,
  clearFilter,
}) => {
  return (
    <FiltersContainer>
      <TabsContainer width="75%" value={value} onChange={changeValue}>
        {tabs.map((item: string, index: number) => (
          <Tab key={index} label={item} {...a11yProps(index)} />
        ))}
      </TabsContainer>

      {_.isEqual(filter, initialFilter) ? (
        <CustomButton content={<Filter />} onClick={openFilter} />
      ) : (
        <CloseFilter clearFilter={clearFilter} />
      )}
    </FiltersContainer>
  );
};
