import { Box, Icon, Typography, styled } from '@mui/material';

export const AdminConsoleContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    aspect-ratio: 1/1;
  }
`;

export const MuiIconContainer = styled(Icon)<{ height?: number; width?: number }>`
  height: ${({ height }) => (height ? `${height}px` : '71px')};
  width: ${({ width }) => (width ? `${width}px` : '71px')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const UserContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
`;

export const UserName = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  line-height: 16px;
`;
