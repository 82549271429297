import React, { useState } from 'react';

import { UserAllDataItems } from './UserAllDataItems';
import { UserAllDataNotes } from './UserAllDataNotes';
import { UserAllRelatedProfile } from './UserAllRelatedProfile';
import { UserAllDataContainer } from './UserDetail.style';
import { StudentRelatedProfiles } from './StudentRelatedProfiles';
import { ReqPaymentModal } from './ReqPaymentModal';

interface UserMainDetailProps {
  role: string;
  address: string;
  email: string;
  phoneNumber: string;
  uid: string;
  schoolYear?: number;
  dateOfBirth?: number;
  relatedProfiles?: any;
  groups?: any[];
  city?: string;
  parentId?: string;
  students?: any[];
  isLoading?: boolean;
  removeStudent?: any;
  addStudent?: any;
  notes?: string;
}

export const UserAllData: React.FC<UserMainDetailProps> = ({
  address,
  email,
  phoneNumber,
  role,
  uid,
  parentId,
  dateOfBirth,
  city,
  groups,
  schoolYear,
  isLoading,
  students,
  relatedProfiles,
  removeStudent,
  addStudent,
  notes,
}) => {
  const [reqPaymentModal, setReqPaymentModal] = useState(false);
  return (
    <UserAllDataContainer gap={27}>
      <UserAllDataItems
        address={address}
        email={email}
        phoneNumber={phoneNumber}
        role={role}
        schoolYear={schoolYear}
        dateOfBirth={dateOfBirth}
        groups={groups}
        city={city}
        uid={uid}
        isLoading={isLoading}
        setReqPaymentModal={setReqPaymentModal}
        reqPaymentModal={reqPaymentModal}
      />
      {role === 'parent' && (
        <UserAllRelatedProfile
          removeStudent={removeStudent}
          relatedProfiles={relatedProfiles}
          setReqPaymentModal={setReqPaymentModal}
          addStudent={addStudent}
          uid={uid}
        />
      )}
      {role === 'student' && parentId && (
        <StudentRelatedProfiles parentId={parentId} relatedProfiles={relatedProfiles} uid={uid} />
      )}
      {isLoading && <div></div>}
      <UserAllDataNotes notes={notes} />
    </UserAllDataContainer>
  );
};
