import React, { useState } from 'react';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Divider, MenuItem, useTheme } from '@mui/material';
import { useNotification } from '../../hooks/useNotification';
import { CustomButton } from '../../common';
import { InputField, DateInput, SelectField, PhoneNumberInput } from '../../common/Inputs';
import { useForm } from '../../hooks/useForm';
import { UserAddType } from '../../types';
import { AddUserPhoto } from './AddUserPhoto';
import { CustomText, FlexInputContainer } from './Users.style';
import { createUser } from '../../api';
import { Flex } from '../../common/Containers';

const cities: string[] = ['London', 'New York', 'Los Angeles', 'Seeatle', 'Toronto', 'Miami'];

export const AddStaff: React.FC<{
  onClose: () => void;
  refetch: any;
  loading: boolean;
  setLoading: any;
}> = ({ onClose, refetch, loading, setLoading }) => {
  const theme = useTheme();

  const { notifyError, notifySuccess } = useNotification();
  const { values, onChange, forceChange } = useForm<UserAddType>({
    role: 'staff',
    image: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    city: '',
    address: '',
  });

  const handleSubmit = async () => {
    if (!moment(values.dateOfBirth).isValid()) {
      notifyError('Please provide valid date!');
      return;
    }
    if (
      !values.firstName ||
      !values.lastName ||
      !values.address ||
      !values.phoneNumber ||
      !values.email ||
      !values.image ||
      !values.city
    ) {
      notifyError('Please fill in all details!');
      setLoading(false);
      return;
    } else {
      setLoading(true);

      if (!isValidPhoneNumber(values.phoneNumber)) {
        notifyError('Please provide valid phone number!');
        setLoading(false);
        return;
      }
      const data = { ...values };
      data.dateOfBirth = moment(values.dateOfBirth).unix();
      try {
        const res = await createUser(data);
        if (res.data.error) {
          notifyError(res.data.message);
          return;
        } else {
          notifySuccess(res.data.message);
        }
        setLoading(false);
        refetch();
        onClose();
      } catch (err: any) {}
    }
  };

  return (
    <>
      <CustomText fontSize={1.4} bold={700} uppercase color={theme.color.darkerBlue}>
        Add Staff
      </CustomText>
      <Divider />
      <FlexInputContainer>
        <AddUserPhoto size={7} onChange={(data: string) => forceChange(data, 'image')} />
      </FlexInputContainer>

      <FlexInputContainer>
        <InputField
          type="text"
          name="firstName"
          info="First Name"
          padding="4px 20px"
          placeholder="First Name"
          onChange={(e: any) => onChange(e)}
          value={values.firstName}
        />
        <InputField
          type="text"
          name="lastName"
          info="Last Name"
          padding="4px 20px"
          placeholder="Last Name"
          onChange={onChange}
          value={values.lastName}
        />
      </FlexInputContainer>
      <InputField
        type="email"
        name="email"
        info="Email"
        padding="4px 20px"
        placeholder="Email"
        onChange={onChange}
        value={values.email}
      />

      <FlexInputContainer>
        <InputField
          type="text"
          name="city"
          info="City"
          placeholder="City Name"
          onChange={(e: any) => onChange(e)}
          value={values.city}
        />
        <PhoneNumberInput
          type="text"
          name="phoneNumber"
          info="PhoneNumber"
          placeholder="+1 992 9922"
          onChange={(value: string) => forceChange(value, 'phoneNumber')}
          value={values.phoneNumber}
        />
      </FlexInputContainer>
      <InputField
        id="address"
        info="Address"
        name="address"
        placeholder="Enter here..."
        value={values.address}
        onChange={onChange}
      />
      <Flex alignEnd>
        <DateInput
          id="dateOfBirth"
          name="dateOfBirth"
          info="Date of Birth"
          onChange={(value: any) => forceChange(value, 'dateOfBirth')}
          value={values.dateOfBirth}
        />
        <CustomButton
          content="SUBMIT"
          onClick={handleSubmit}
          width="100%"
          height="57px"
          type="button"
          loading={loading}
        />
      </Flex>
    </>
  );
};
