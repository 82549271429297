import { Menu } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import { UserProfile } from "../../../../components/UserProfile";
import { UserArrowRight } from "../../../../svg/UserArrowRight";
import { arrowStyle } from "../../../Overview/Overview.constants";
import { CustomText } from "../../../Users/Users.style";
import { DataContainer } from "../../Analytics.style";
import { MenuAndArrowStack, TotalTransactionsLineHeader } from "./AnalyticTransactions.style";
import { items, MostAddedToSchoolBillProps } from "./MostAddedToSchoolBill";

const itemsData = items.map((item) => ({ ...item, price: '400' }));

export const MostCreditedAccount: React.FC<MostAddedToSchoolBillProps> = ({
  theme,
}) => (
  <DataContainer height="16%" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>
          Most Credited Account
        </CustomText>
        <CustomText>2021 / All Months / Students</CustomText>
      </Box>
      <MenuAndArrowStack direction="row" alignItems="center" width="50%">
        <IconButton size="large">
          <Menu />
        </IconButton>
        <UserArrowRight fill={theme.color.lightBlue} styles={arrowStyle} />
      </MenuAndArrowStack>
    </TotalTransactionsLineHeader>
    <Stack
      direction="row"
      justifyContent="space-around"
      spacing={2}
      margin="2% 0"
    >
      {itemsData.map((item) => (
        <UserProfile {...item} />
      ))}
    </Stack>
  </DataContainer>
)
