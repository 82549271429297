import React, { useEffect, useState } from 'react';
import { LineCharts } from './LineChart';
import { getAnalytics } from '../../api';
import { useTheme } from '@mui/material';
import { SmallFillterWithVendors } from './SmallFilterWithVendors';
import moment from 'moment';
import { fillDates } from '../../utils/dateSeparate';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
  vendor: {
    name: null,
    id: null,
  },
};

export const TotalCreditsRedeemed = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState<{}[]>([]);
  const [totalValue, setTotalValue] = useState(0);
  //   const { value, isLoading, totalValue } = useAnalytics('deposits/number-of-deposits', {
  //     year: filter.year,
  //     term: filter.term,
  //     month: filter.month,
  //     role: filter.user,
  //     groupId: filter.group,
  //     userId: filter.name,
  //   });
  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('vendors/credits', {
      from: filter.from,
      to: filter.to,
      vendorId: filter.vendor.id,
    });
    const value: {}[] = fillDates(filter.from, [], filter, data?.data.data.monthsData);
    setValue(value);
    setTotalValue(data?.data.data.total);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);

  return (
    <>
      <LineCharts
        data={value}
        title={`Total Credits Redeemed: ${totalValue ? totalValue / 100 : 0}$`}
        openFilter={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
          vendor: filter.vendor?.name,
        }}
        loading={loading}
        color={theme.color.depositGreen}
      />

      <SmallFillterWithVendors
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        onSave={onSave}
      />
    </>
  );
};

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// /analytics/school/deposits/gross-revenue?year=2021&term=summer_term&month=2&groupId=yYJWKR8mpMelnBBYxltw&userId=QZtfTnTrZTUVUbPHGcwMOTZGyhR2

// 'deposits/total-transfered-to-bank-account',
