import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { UserAllData } from './UserAllData';
import { decodeUrl } from '../../helpers';

import { UserDetailsContainer } from './UserDetail.style';
import { UserMainDetail } from './UserMainDetail';
import { useUser } from '../../hooks/useUser';
import { DeleteUserModal } from '../Users/DeleteUserModal';
import { EditUser } from '../Users/EditUser';

export const UserDetail = () => {
  const params: any = useParams();
  const id = params.id;
  const { user, isLoading, relatedProfiles, removeStudent, addStudent, refetch } = useUser(id);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    refetch();
  }, []);

  const onDeleteClick = (user: any) => {
    setUserToDelete(user);
  };

  return (
    <UserDetailsContainer>
      <>
        <RouteInfo
          banner="Users"
          description="Manage users in your organisation"
          lastRoute={user && user.name}
        />
        <UserMainDetail
          {...user}
          isLoading={isLoading}
          addStudent={addStudent}
          onDeleteClick={onDeleteClick}
          uid={id}
        />
        <UserAllData
          {...user}
          isLoading={isLoading}
          relatedProfiles={relatedProfiles}
          removeStudent={removeStudent}
          addStudent={addStudent}
        />
        <DeleteUserModal
          isOpen={!!userToDelete}
          onClose={() => {
            setUserToDelete(null);
          }}
          user={userToDelete}
        />
      </>
    </UserDetailsContainer>
  );
};
