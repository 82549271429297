import { styled, Box } from '@mui/material';

export const AnalyticGroupsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 75vh;
  gap: 16px;
`;

export const GroupCard = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  height: 100%;
  width: 100%;
  position: relative;
`;

export const CardContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 60px;
  padding: 20px;
  width: 100%;
`;

export const CardIndexNumber = styled(Box)`
  position: absolute;
  left: 30px;
  top: 20px;
`;

export const CardImage = styled(Box)<{
  color?: string;
}>`
  display: flex;
  aspect-ratio: 1/1;
  max-width: 50%;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ color, theme }) => (color ? color : theme.color.darkerBlue)};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    max-width: 50%;
    max-height: 50%;
  }
`;
