import { Box } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { ChartInfo } from '../../../../common';
import { DataContainer } from '../../Analytics.style';

const colors: string[] = ['#4BFE2D', '#FF7676', '#FCF20C'];
const data = [
  {
    name: 'Resolved Tickets',
    value: 50,
  },
  {
    name: 'Unresolved Tickets',
    value: 35,
  },
  {
    name: 'Exported Tickets',
    value: 15,
  },
];

export const PieTickets = () => (
  <DataContainer height="50vh" width="47vw" gap="11%">
    <ResponsiveContainer height="100%" width="50%">
      <PieChart height={444} width={444}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="100%"
          labelLine={false}
          dataKey="value"
          fill="#8884d8"
        >
          {data.map((item, index) => (
            <Cell key={index} fill={colors[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>

    <Box>
      {data.map((item, index) => (
        <ChartInfo
          key={index}
          bgcolor={colors[index]}
          banner={item.name}
          amount={item.value}
          percentage={item.value}
          noBorder={data.length - 1 === index}
        />
      ))}
    </Box>
  </DataContainer>
);
