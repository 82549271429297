import { Tab } from '@mui/material';
import React from 'react';

import { FiltersContainer, TabsContainer } from '../Users/Users.style';
import { a11yProps } from '../Users/UsersControl';

interface MailControlProps {
  changeValue: any;
  tabs: string[];
  activeTab: number;
}

export const MailControl: React.FC<MailControlProps> = ({ changeValue, activeTab, tabs }) => (
  <FiltersContainer>
    <TabsContainer width="83%" value={activeTab} onChange={changeValue}>
      {tabs.map((item: string, index: number) => (
        <Tab key={index} label={item} {...a11yProps(index)} />
      ))}
    </TabsContainer>
  </FiltersContainer>
);
