import { Box, styled, useTheme } from '@mui/material';
import React from 'react';

import { CustomText } from '../pages/Users/Users.style';

export const FaqNumberContainer = styled(Box)`
  height: 38px;
  width: 38px;
  position: relative;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const FaqNumberSvg = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19" cy="19" r="19" fill="url(#paint0_linear_835_2159)" />
    <defs>
      <linearGradient
        id="paint0_linear_835_2159"
        x1="20.3705"
        y1="54.3537"
        x2="61.1692"
        y2="20.3993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#54B1E5" />
        <stop offset="1" stopColor="#0C84FC" />
      </linearGradient>
    </defs>
  </svg>
);

export const FaqNumber: React.FC<{ num: number }> = ({ num }) => {
  const theme = useTheme();

  return (
    <FaqNumberContainer>
      <FaqNumberSvg />
      <CustomText bold={700} color={theme.palette.common.white}>
        {num}
      </CustomText>
    </FaqNumberContainer>
  );
};
