import { styled, Box } from '@mui/material';
import React from 'react';
import { Footer } from '../svg/Footer';

export const ImageContainer = styled(Box)`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: -10px;
  z-index: -1;

  // @media(max-width: 1444px) {
  //   z-index: 4;
  // }
`;

export const FooterShape = () => (
  <ImageContainer>
    <Footer />
  </ImageContainer>
);
