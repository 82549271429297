import { Box, Skeleton, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SmallCustomAvatar } from '../../common';
import { Flex } from '../../common/Containers';
import { SupportMarker } from '../InternalSupport/InternalSupport.style';
import { MailListItem, MailListItemContent } from '../Mail/Mail.style';
import { CustomText } from '../Users/Users.style';

interface ExternalSupportItemInboxProps {
  id: string;
  createdBy: {};
  subject: string;
  date: number;
  resolved: boolean;
  lastMessage: {
    subject: string;
    from: {
      name: string;
      email: string;
      photo: string;
    };
  };
  onClick: () => void;
}

export const ExternalSupportItemInbox: React.FC<ExternalSupportItemInboxProps> = ({
  resolved,
  date,
  lastMessage,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <MailListItem padding="10px 20px" cursor="pointer" onClick={onClick}>
      <SupportMarker active={resolved} />
      <MailListItemContent flex={15}>
        <Flex>
          <Flex>
            <SmallCustomAvatar image={lastMessage?.from.photo} />
          </Flex>
          <Flex flex={11}>
            <Box>
              <CustomText bold={700}>{lastMessage?.from.name}</CustomText>
              <CustomText fontSize={0.9} bold={300}>
                {lastMessage?.from.email}
              </CustomText>
            </Box>
          </Flex>
        </Flex>
      </MailListItemContent>

      <MailListItemContent flex={15}>{lastMessage.subject}</MailListItemContent>
      <MailListItemContent flex={10}>{moment.unix(date).format('DD/MM/YYYY')}</MailListItemContent>
      <MailListItemContent flex={10}>{moment.unix(date).format('HH:MM')}</MailListItemContent>
      <MailListItemContent flex={3}>
        <CustomText color={resolved ? theme.color.depositDarkGreen : theme.color.schollBillRed}>
          {resolved ? 'Resolved' : 'Unresolved'}
        </CustomText>
      </MailListItemContent>
    </MailListItem>
  );
};

export const ExternalSupportItemInboxSkeleton: React.FC = () => {
  return (
    <MailListItem padding="11.2px 20px" cursor="pointer">
      <MailListItemContent flex={15}>
        <Flex>
          <Flex>
            <Skeleton width={37} height={37} variant="circular" />
          </Flex>
          <Flex flex={11}>
            <Box>
              <Skeleton width={140} height="1.5rem" />
              <Skeleton width={200} height="1.2rem" />
            </Box>
          </Flex>
        </Flex>
      </MailListItemContent>

      <MailListItemContent flex={15}>
        <Skeleton width={100} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton width={100} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton width={50} height="1rem" />
      </MailListItemContent>
      <MailListItemContent flex={3}>
        <Skeleton width={100} height="1rem" />
      </MailListItemContent>
    </MailListItem>
  );
};
