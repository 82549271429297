interface termType {
  name: string;
  value: string;
}

export const terms: termType[] = [
  {
    name: 'Michael Term',
    value: 'michael_term',
  },
  {
    name: 'Lent Term',
    value: 'lent_term',
  },
  {
    name: 'Summer Term',
    value: 'summer_term',
  },
];
