import { useTheme } from '@mui/material';
import React from 'react';
import { Flex } from '../../common/Containers';

import { CustomText } from '../Users/Users.style';
import { UserDetailListContainer } from './UserDetail.style';

export const UserAllDataNotes: React.FC<{ notes?: string }> = ({ notes }) => {
  const theme = useTheme();
  return (
    <UserDetailListContainer>
      <Flex column noCenter gap={26}>
        <CustomText fontSize={1.1} color={theme.color.darkerBlue} bold={700}>
          NOTES:
        </CustomText>
        <CustomText fontSize={0.9} bold={300}>
          {notes}
        </CustomText>
      </Flex>
    </UserDetailListContainer>
  );
};
