import { Box, CircularProgress, IconButton, styled, useTheme } from '@mui/material';
import React from 'react';
import { StyledAvatar } from './SmallCustomAvatar';
import { CustomText, UserListItemContainer } from '../pages/Users/Users.style';
import { ArrowBox } from '../pages/UserDetail/UserDetail.style';
import { UserArrowRight } from '../svg/UserArrowRight';
import { InitialCircle } from '../common';
import { Skeleton } from '@mui/material';
import { Flex } from './Containers';
import { UserDel } from '../svg/UserPen';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';

const StyledUserListItem = styled(UserListItemContainer)`
  position: relative;
  width: 100%;
`;

interface UserProfileLitemProps {
  name: string;
  second?: string;
  third?: string;
  imageUrl?: string;
  uid?: string;
  removeStudent?: any;
  noDelete?: boolean;
  parentId?: string;
  to: string;
}

export const UserProfileLitem: React.FC<UserProfileLitemProps> = ({
  name,
  uid,
  second,
  third,
  imageUrl,
  removeStudent,
  noDelete,
  parentId,
  to,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledUserListItem jcontent="flex-start" gap={16} padding="20px">
      <InitialCircle img={imageUrl} height="74px" width="74px" />
      <Box>
        <CustomText bold={700}>{name}</CustomText>
        <CustomText>Email: {second}</CustomText>
        <CustomText>Phone: {third}</CustomText>
      </Box>
      <ArrowBox>
        {!noDelete ? (
          <IconButton
            size="small"
            onClick={() => removeStudent.mutate({ studentId: uid, parentId })}
          >
            {!removeStudent.isLoading ? (
              <UserDel fill={theme.color.schollBillRed} />
            ) : (
              <CircularProgress size={20} />
            )}
          </IconButton>
        ) : (
          <Flex></Flex>
        )}
        <IconButton size="small">
          <UserArrowRight
            onClick={() => navigate(`/users/${to}/${uid}`)}
            fill={theme.color.darkerBlue}
          />
        </IconButton>
      </ArrowBox>
    </StyledUserListItem>
  );
};

export const UserProfileLitemSkeleton = () => (
  <StyledUserListItem jcontent="flex-start" gap={16} padding="20px" margin="2% 0">
    <Skeleton height={74} width={74} variant="circular" />
    <Box>
      <Skeleton height="1.5rem" width={200} />
      <Flex gap="5px">
        <Skeleton height="1.5rem" width={350} />
      </Flex>
      <Flex gap="5px">
        <Skeleton height="1.5rem" width={300} />
      </Flex>
    </Box>
    <ArrowBox></ArrowBox>
  </StyledUserListItem>
);
