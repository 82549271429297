import { useTheme } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';

import { useNavigate } from 'react-router-dom';

import { SmallCircle } from '../../components/RouteInfo/RouteInfo.style';
import { UserArrowRight } from '../../svg/UserArrowRight';
import { CustomText } from '../Users/Users.style';
import { arrowStyle } from './Overview.constants';
import {
  OverviewSectionItemContainer,
  OverviewSectionItemMain,
  OverviewSectionItemSecondary,
} from './Overview.style';

interface OverviewSectionItemProps {
  link: string;
  banner?: string;
  smImage: any;
  litleBanner?: string;
  totalNumber?: number;
  numberColor?: string;
  first?: string;
  second?: string;
  third?: string;
  invert?: boolean;
  value?: any;
  secondKey?: string;
  firstValue?: string;
  secondValue?: string;
  thirdValue?: string;
  totalKeyName?: number;
  dataFromApiName?: string;
}

export const MailSectionItem: React.FC<OverviewSectionItemProps> = ({
  smImage,
  link,
  litleBanner,
  banner,
  totalNumber,
  numberColor,
  first,
  second,
  third,
  invert,
  value,
  firstValue,
  secondValue,
  thirdValue,
  totalKeyName,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getNumberColor = (color?: string) => {
    switch (color) {
      case 'white':
        return theme.palette.common.white;
      case 'warning':
        return theme.palette.warning.light;
      case 'error':
        return theme.palette.error.light;
      default:
        return theme.color.darkerBlue;
    }
  };

  return (
    <OverviewSectionItemContainer padding="0" blue={invert}>
      <OverviewSectionItemMain gap={9} onClick={() => navigate(link)}>
        {/* <SmallCircle>{smImage && <img src={smImage} alt="" />}</SmallCircle> */}
        {smImage && (
          <SmallCircle color={getNumberColor(numberColor)}>
            {smImage &&
              React.cloneElement(smImage, { color: invert ? theme.color.darkerBlue : 'white' })}
          </SmallCircle>
        )}
        <CustomText fontSize={1.1} bold={700} color={invert ? 'white' : 'black'}>
          {banner}
        </CustomText>
        <UserArrowRight fill={theme.palette.common.white} styles={arrowStyle} />
      </OverviewSectionItemMain>

      <OverviewSectionItemSecondary>
        <CustomText bold={600} color={invert ? 'white' : 'black'}>
          {litleBanner}
        </CustomText>
        <CustomText
          fontSize={1.6}
          bold={700}
          color={invert ? 'white' : getNumberColor(numberColor)}
        >
          {totalKeyName ? <CountUp end={totalKeyName} /> : 0}
        </CustomText>
        <CustomText color={invert ? 'white' : 'black'}>
          {first}
          {firstValue}
        </CustomText>
        <CustomText color={invert ? 'white' : 'black'}>
          {second}
          {secondValue}
        </CustomText>
        <CustomText color={invert ? 'white' : 'black'}>
          {third}
          {thirdValue}
        </CustomText>
      </OverviewSectionItemSecondary>
    </OverviewSectionItemContainer>
  );
};
