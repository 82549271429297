import { Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Theme } from "@mui/material";
import React from "react";
import { UserProfile } from "../../../../components/UserProfile";
import { UserArrowRight } from "../../../../svg/UserArrowRight";
import { arrowStyle } from "../../../Overview/Overview.constants";
import { CustomText } from "../../../Users/Users.style";
import { DataContainer } from "../../Analytics.style";
import { MenuAndArrowStack, TotalTransactionsLineHeader } from "./AnalyticTransactions.style";

export const items = [
  {
    name: 'Mark Smith',
    price: '18 / 180',
  }, {
    name: 'Jack Morisson',
    price: '16 / 150',
  }, {
    name: 'Andrew Hamilton',
    price: '15 / 120',
  }
]

export interface MostAddedToSchoolBillProps {
  theme: Theme;
}

export const MostAddedToSchoolBill: React.FC<MostAddedToSchoolBillProps> = ({
  theme,
}) => (
  <DataContainer height="16%" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>
          Most Added To School Bill
        </CustomText>
        <CustomText>2021 / All Months</CustomText>
      </Box>
      <MenuAndArrowStack direction="row" alignItems="center" width="50%">
        <IconButton size="large">
          <Menu />
        </IconButton>
        <UserArrowRight fill={theme.color.lightBlue} styles={arrowStyle} />
      </MenuAndArrowStack>
    </TotalTransactionsLineHeader>
    <Stack
      direction="row"
      justifyContent="space-around"
      spacing={2}
      margin="2% 0"
    >
      {items.map((item) => (
        <UserProfile {...item} />
      ))}
    </Stack>
  </DataContainer>
)
