import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getGroups } from '../api';

export const useGroups = (type?: null | string, searchTerm?: null | string) => {
  const [groups, setGroups] = useState([]);

  // const { data, isLoading } = useQuery('groups', () => getGroups({ pageCount: 20 }));

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['groups', type, searchTerm],
      async ({ pageParam = 1 }) => {
        const res = await getGroups({
          pageCount: 20,
          page: pageParam,
          groupType: type,
          name: searchTerm,
        });
        return res;
      },
      {
        cacheTime: 0,
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      const arr: any = data.pages.map((item: any) => ({ ...item?.data?.data?.groups }));
      setGroups(arr);
    }
  }, [data]);

  return {
    groups,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  };
};
