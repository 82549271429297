import React from 'react';

export const Store = () => (
  <svg width="72" height="60" viewBox="0 0 72 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.3629 13.8984L62.7772 1.75781C62.0993 0.667969 60.8954 0 59.6097 0H12.3889C11.1032 0 9.89928 0.667969 9.22136 1.75781L1.63564 13.8984C-2.27994 20.168 1.19149 28.8867 8.50837 29.8828C9.03434 29.9531 9.57201 29.9883 10.1097 29.9883C13.5694 29.9883 16.6317 28.4648 18.7356 26.1094C20.8395 28.4648 23.9136 29.9883 27.3616 29.9883C30.8214 29.9883 33.8837 28.4648 35.9876 26.1094C38.0915 28.4648 41.1655 29.9883 44.6136 29.9883C48.0733 29.9883 51.1356 28.4648 53.2395 26.1094C55.3551 28.4648 58.4175 29.9883 61.8655 29.9883C62.4149 29.9883 62.9408 29.9531 63.4668 29.8828C70.8071 28.8984 74.2902 20.1797 70.3629 13.8984ZM61.8889 33.75C60.7201 33.75 59.5629 33.5742 58.4408 33.3047V45H13.5577V33.3047C12.4356 33.5625 11.2785 33.75 10.1097 33.75C9.40837 33.75 8.69538 33.7031 8.00577 33.6094C7.35123 33.5156 6.70837 33.3633 6.08889 33.1875V56.25C6.08889 58.3242 7.76032 60 9.82915 60H62.1928C64.2616 60 65.933 58.3242 65.933 56.25V33.1875C65.3019 33.375 64.6707 33.5273 64.0162 33.6094C63.3032 33.7031 62.6019 33.75 61.8889 33.75Z"
      fill="#0C84FC"
    />
  </svg>
);
