import React from 'react';

export const Iphone: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="31" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="phone">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9162 22.1388C22.5387 21.5678 22.8915 20.7608 22.8881 19.916L22.8881 16.9313C22.919 15.4143 21.8117 14.1121 20.3078 13.8999C19.4136 13.7819 18.5443 13.5654 17.7077 13.2532C16.6137 12.8419 15.3778 13.1055 14.545 13.929L13.8239 14.6501C11.9793 13.4742 10.414 11.9089 9.23814 10.0643L9.95523 9.34721C10.7827 8.51045 11.0463 7.27455 10.6342 6.17824C10.3228 5.34388 10.1063 4.47464 9.98954 3.58934C9.77754 2.0891 8.49408 0.985327 6.98829 1.00015L3.99813 1.0001C3.15508 1.00089 2.35208 1.35558 1.78433 1.97753C1.21659 2.59948 0.936396 3.43141 1.01219 4.2701C1.35855 7.53288 2.46343 10.6532 4.23885 13.3938C5.84819 15.9265 8.00173 18.08 10.5418 19.6941C13.2623 21.4584 16.3674 22.563 19.6001 22.9142C20.4594 22.9921 21.2938 22.7099 21.9162 22.1388ZM13.8239 14.6501C14.0364 14.7856 14.2527 14.9158 14.4724 15.0408L13.271 15.203L13.8239 14.6501ZM20.8881 16.9201C20.901 16.3877 20.5312 15.9512 20.0374 15.8815C19.0025 15.745 17.9865 15.4919 17.0063 15.1262C16.6408 14.9888 16.2289 15.0766 15.9552 15.3472L14.6852 16.6172C14.3669 16.9355 13.8752 17.0019 13.4838 16.7794C10.8238 15.2669 8.62136 13.0644 7.10883 10.4044C6.88632 10.0131 6.95271 9.52131 7.27102 9.20299L8.53708 7.93696C8.81158 7.65936 8.89944 7.2474 8.76123 6.8797C8.39631 5.90177 8.1432 4.88569 8.00797 3.86005C7.93772 3.36303 7.5099 2.99511 6.99813 3.0001L3.99907 3.0001C3.71837 3.00036 3.4507 3.11859 3.26145 3.32591C3.07221 3.53322 2.97881 3.81053 3.00254 4.0745C3.31419 7.0093 4.31278 9.82943 5.92214 12.3138C7.37874 14.606 9.32218 16.5495 11.6222 18.0111C14.088 19.6101 16.8945 20.6085 19.7981 20.9242C20.0786 20.9495 20.3567 20.8554 20.5642 20.6651C20.7717 20.4747 20.8893 20.2057 20.8881 19.9201V16.9201Z"
        fill="black"
      />
      <mask id="mask0_0_4335" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          id="Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9162 22.1388C22.5387 21.5678 22.8915 20.7608 22.8881 19.916L22.8881 16.9313C22.919 15.4143 21.8117 14.1121 20.3078 13.8999C19.4136 13.7819 18.5443 13.5654 17.7077 13.2532C16.6137 12.8419 15.3778 13.1055 14.545 13.929L13.8239 14.6501C11.9793 13.4742 10.414 11.9089 9.23814 10.0643L9.95523 9.34721C10.7827 8.51045 11.0463 7.27455 10.6342 6.17824C10.3228 5.34388 10.1063 4.47464 9.98954 3.58934C9.77754 2.0891 8.49408 0.985327 6.98829 1.00015L3.99813 1.0001C3.15508 1.00089 2.35208 1.35558 1.78433 1.97753C1.21659 2.59948 0.936396 3.43141 1.01219 4.2701C1.35855 7.53288 2.46343 10.6532 4.23885 13.3938C5.84819 15.9265 8.00173 18.08 10.5418 19.6941C13.2623 21.4584 16.3674 22.563 19.6001 22.9142C20.4594 22.9921 21.2938 22.7099 21.9162 22.1388ZM13.8239 14.6501C14.0364 14.7856 14.2527 14.9158 14.4724 15.0408L13.271 15.203L13.8239 14.6501ZM20.8881 16.9201C20.901 16.3877 20.5312 15.9512 20.0374 15.8815C19.0025 15.745 17.9865 15.4919 17.0063 15.1262C16.6408 14.9888 16.2289 15.0766 15.9552 15.3472L14.6852 16.6172C14.3669 16.9355 13.8752 17.0019 13.4838 16.7794C10.8238 15.2669 8.62136 13.0644 7.10883 10.4044C6.88632 10.0131 6.95271 9.52131 7.27102 9.20299L8.53708 7.93696C8.81158 7.65936 8.89944 7.2474 8.76123 6.8797C8.39631 5.90177 8.1432 4.88569 8.00797 3.86005C7.93772 3.36303 7.5099 2.99511 6.99813 3.0001L3.99907 3.0001C3.71837 3.00036 3.4507 3.11859 3.26145 3.32591C3.07221 3.53322 2.97881 3.81053 3.00254 4.0745C3.31419 7.0093 4.31278 9.82943 5.92214 12.3138C7.37874 14.606 9.32218 16.5495 11.6222 18.0111C14.088 19.6101 16.8945 20.6085 19.7981 20.9242C20.0786 20.9495 20.3567 20.8554 20.5642 20.6651C20.7717 20.4747 20.8893 20.2057 20.8881 19.9201V16.9201Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_4335)">
        <g id="COLOR/ black">
          <rect id="Rectangle" width="24" height="24" fill={color ? color : '#32C5FF'} />
        </g>
      </g>
    </g>
  </svg>
);
