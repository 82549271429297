import React from 'react';

export const Deposit = () => (
  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4375 16.4286H12.3049C11.9943 16.4286 11.7424 16.7044 11.7424 17.0446V19.6551H6V13.1429H9.9375C10.2481 13.1429 10.5 12.867 10.5 12.5268V10.4732C10.5 10.133 10.2481 9.85714 9.9375 9.85714H6V6.59422C6 4.93771 7.15134 3.66347 8.8965 3.66347C10.0055 3.66347 11.047 4.25413 11.5989 4.63116C11.8404 4.79612 12.1562 4.73646 12.3343 4.49332L13.6699 2.67011C13.8683 2.39924 13.8236 2.00244 13.5706 1.79318C12.8027 1.15821 11.0895 0 8.80927 0C4.96997 0 2.25 2.70774 2.25 6.46675V9.85714H0.9375C0.626859 9.85714 0.375 10.133 0.375 10.4732V12.5268C0.375 12.867 0.626859 13.1429 0.9375 13.1429H2.25V19.7143H0.5625C0.251859 19.7143 0 19.9901 0 20.3304V22.3839C0 22.7242 0.251859 23 0.5625 23H14.4375C14.7481 23 15 22.7242 15 22.3839V17.0446C15 16.7044 14.7481 16.4286 14.4375 16.4286Z"
      fill="white"
    />
  </svg>
);
