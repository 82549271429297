import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getMyPermisions, getPermisions } from '../api';

interface NotificationProps {
  isLoading: boolean;
  myPermissions: string[];
  permissions: string[];
}

const initialState: NotificationProps = {
  isLoading: false,
  myPermissions: [],
  permissions: [],
};

export const getPermissionsFromService = createAsyncThunk(
  'permisions/getPermisionsFromService',
  async (_, thunkApi) => {
    try {
      const res = await getPermisions();
      return res.data.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

export const getMyPermissionsFromService = createAsyncThunk(
  'permisions/getPermisionsFromService',
  async (_, thunkApi) => {
    try {
      const res = await getMyPermisions();
      return res.data.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

const permissionsSlice = createSlice({
  name: 'permisions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<any>) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    [getPermissionsFromService.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPermissionsFromService.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false;
      state.permissions = payload;
    },
    [getPermissionsFromService.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [getMyPermissionsFromService.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getMyPermissionsFromService.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false;
      state.myPermissions = payload;
    },
    [getMyPermissionsFromService.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
