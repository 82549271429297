import format from 'date-fns/format';
import moment from 'moment';

export const groupByDate = (data: any) => {
  const groups = data.reduce((dates: any, transaction: any) => {
    const date = format(new Date(transaction.date), 'DD/MM/yyyy');
    if (!dates[date]) {
      dates[date] = [];
    }
    dates[date].push(transaction);
    return dates;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      date,
      transactions: groups[date],
    };
  });
};

export const fillDates = (
  month: number,
  arr: {}[],
  filter: { from: number; to: number },
  value: {}[],
) => {
  const monthArr: {}[] = arr;
  const startMonth = moment.unix(month).startOf('month').unix();
  if (month <= filter.to) {
    const item = value.find(
      (item: any) => moment.unix(item?.monthStartDate).startOf('month').unix() === startMonth,
    );
    if (item) {
      monthArr.push(item);
    } else {
      monthArr.push({
        monthStartDate: startMonth,
        total: 0,
      });
    }
    fillDates(moment.unix(startMonth).add(1, 'months').unix(), monthArr, filter, value);
  }
  return monthArr;
};
