import { UserAnalyticContainer, BigRow } from './UserAnalytic.style';
import { UserCount } from '../../../../components/Charts/UserCount';
import { GroupBarChart } from '../../../../components/Charts/GroupBarChart';

export const UserAnalytic = () => {
  return (
    <UserAnalyticContainer>
      <UserCount />
      <BigRow>
        <GroupBarChart />
      </BigRow>
    </UserAnalyticContainer>
  );
};
