import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';

import { CustomText, UserListItemContent, UserHeaderContainer } from './Users.style';

export const UserListHeader: React.FC<{ activeTab?: number }> = ({ activeTab }) => {
  return (
    <UserHeaderContainer>
      <UserListItemContent flex={15} sx={{ cursor: 'pointer' }} gap={2}>
        <CustomText fontSize={1.1} bold={700}>
          {activeTab === 0 && 'Student Name'}
          {activeTab === 1 && 'Parent Name'}
          {activeTab === 2 && 'Staff Name'}
        </CustomText>
      </UserListItemContent>
      <UserListItemContent flex={15}>
        <CustomText fontSize={1.1} bold={700}>
          Email
        </CustomText>
      </UserListItemContent>
      {activeTab !== 0 && (
        <UserListItemContent flex={5}>
          <CustomText fontSize={1.1} bold={700}>
            Phone
          </CustomText>
        </UserListItemContent>
      )}
      {activeTab === 0 && (
        <UserListItemContent flex={10}>
          <CustomText fontSize={1.1} bold={700}>
            School bill
          </CustomText>
        </UserListItemContent>
      )}
      {activeTab === 0 && (
        <UserListItemContent flex={5}>
          <CustomText fontSize={1.1} bold={700}>
            Year
          </CustomText>
        </UserListItemContent>
      )}

      <UserListItemContent flex={7} />
    </UserHeaderContainer>
  );
};
