import { Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Switch } from "@mui/material";
import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { CustomText } from "../../../Users/Users.style";
import { DataContainer } from "../../Analytics.style";
import { StyledBtn, TotalTransactionsLineHeader } from "./AnalyticTransactions.style";
import { data } from "./TotalTransactionsLineChart";

export const SchoolBillTransactions = () => (
  <DataContainer height="50vh" width="47vw" column>
    <TotalTransactionsLineHeader>
      <Box>
        <CustomText bold={700} fontSize={1.4}>Transactions: 371</CustomText>
        <CustomText>2021 / All Groups</CustomText>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Switch />
        <IconButton size="large">
          <Menu />
        </IconButton>
        <StyledBtn variant="contained">Settled</StyledBtn>
      </Stack>
    </TotalTransactionsLineHeader>
    <ResponsiveContainer height="81%" width="100%">
      <BarChart
        height={400}
        width={771}
        data={data}
      >
        <CartesianGrid vertical={false} />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis dataKey="name" tickLine={false} />
        <Bar dataKey="student" radius={[10,10,0,0]} barSize={35} fill="#FF7676" />
      </BarChart>
    </ResponsiveContainer>
  </DataContainer>
)
