import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { decodeUrl } from '../../helpers';
import { useNotification } from '../../hooks/useNotification';
import { useTicket } from '../../hooks/useTicket';
import { CreateReply } from '../InternalSupportTicket/CreateReply';
import {
  MessagesAndFormContainer,
  MessagesList,
} from '../InternalSupportTicket/InternalSupportTicket.style';
import { InternalSupportTicketMessages } from '../InternalSupportTicket/InternalSupportTicketMessages';
import { UserDetailsContainer } from '../UserDetail/UserDetail.style';
import { ExternalSupportTicketDetail } from './ExternalSupportTicketDetail';

export const ExternalSupportTicket = () => {
  const { notifyError } = useNotification();
  const [replyText, setReplyText] = useState<string>('');
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const id = decodeUrl(pathname);
  const { ticket, reply, isLoading } = useTicket(id);
  const navigate = useNavigate();

  const back = () => {
    navigate(ROUTES.EXTERNAL_SUPPORT);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await reply(replyText);
      if (!res?.data.error) {
        setLoading(false);
        setReplyText('');
      } else {
        notifyError(res?.data.message);
        setLoading(false);
      }
    } catch (error) {
      notifyError('Something went wrong!');
      setLoading(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyText(event.target.value);
  };

  return (
    <>
      <RouteInfo
        banner="External Support"
        description="Manage internal support tickets"
        smImage="/internal-support-sm.png"
        redirect={back}
        noLastRoute
      />
      <UserDetailsContainer>
        <ExternalSupportTicketDetail onClick={() => navigate(-1)} />
        <MessagesAndFormContainer>
          <MessagesList>
            {ticket?.messages.map(
              (
                message: {
                  body: string;
                  date: number;
                  from: { photo: string; name: string; email: string };
                  id: string;
                  isFromExternalSupport: boolean;
                  subject: string;
                },
                index: number,
              ) => (
                <InternalSupportTicketMessages
                  resolved={ticket?.resolved}
                  exportExternal={ticket?.exportExternal}
                  main={index === 0}
                  {...message}
                  external={true}
                />
              ),
            )}
          </MessagesList>
          {ticket && !ticket?.resolved && (
            <CreateReply
              value={replyText}
              onChange={onChange}
              handleSubmit={handleSubmit}
              loading={loading}
              exportExternal={ticket?.exportExternal}
            />
          )}
        </MessagesAndFormContainer>
        {isLoading && <Loading />}
      </UserDetailsContainer>
    </>
  );
};
