import React, { useRef } from 'react';
import { InputField } from './Input';
import { InputFieldProps } from './Input';
import { Flex } from '../Containers';

export const ColorPicker: React.FC<InputFieldProps> = ({ value, onChange, ...rest }) => {
  const ref: any = useRef(null);
  return (
    <Flex>
      <InputField
        info="Group Color"
        padding="4px 20px"
        placeholder="Group Name"
        name="hexColor"
        value={value}
        type="text"
        onClick={() => ref.current.click()}
        inputProps={{
          style: {
            color: `#${value}`,
            fontWeight: 700,
            textTransform: 'uppercase',
          },
        }}
        {...rest}
      />
      <input
        type="color"
        ref={ref}
        style={{ opacity: 0, position: 'absolute', bottom: 0 }}
        onChange={(e) => {
          onChange(e.target.value.split('#')[1]);
        }}
      />
    </Flex>
  );
};
