import { ROUTES } from './constants';
import { RouteObject, useRoutes, Navigate, Outlet } from 'react-router-dom';

import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Users } from './pages/Users/Users';
import { Admin } from './pages/Dashboard/Admin';
import { NotFound } from './pages/NotFound/NotFound';
import { UserDetail } from './pages/UserDetail/UserDetail';
import { Overview } from './pages/Overview/Overview';
import { Groups } from './pages/Groups/Groups';
import { GroupDetail } from './pages/GroupDetail/GroupDetail';
import { Mail } from './pages/Mail/Mail';
import { DataMigration } from './pages/DataMigration/DataMigration';
import { SchoolProfile } from './pages/SchoolProfile/SchoolProfile';
import { InternalSupport } from './pages/InternalSupport/InternalSupport';
import { InternalSupportTicket } from './pages/InternalSupportTicket/InternalSupportTicket';
import { Vendors } from './pages/Vendors/Vendors';
import { VendorsConsole } from './pages/Vendors/VendorsConsole';
import { PermanentVendors } from './pages/Vendors/PermanentVendors/PermanentVendors';
import { TemporaryVendors } from './pages/Vendors/TemporaryVendors/TemporaryVendors';
import { Permissions } from './pages/Permissions/Permissions';
import { Transactions } from './pages/Transactions/Transactions';
import { ExternalSupport } from './pages/Externalsupport/ExternalSupport';
import { ExternalSupportTicket } from './pages/ExternalSupportTicket/ExternalSupportTicket';
import { Analytics } from './pages/Analytics/Analytics';
import { AnalyticGroups } from './pages/Analytics/pages/AnalyticGroups/AnalyticGroups';
import { Faq } from './pages/Faq/Faq';
import { AnalyticTransactions } from './pages/Analytics/pages/AnalyticTransactions/AnalyticTransactions';
import { AnalyticSupport } from './pages/Analytics/pages/AnalyticSupport/AnalyticSupport';
import { AnalyticDeposits } from './pages/Analytics/pages/AnalyticDeposits/AnalyticDeposits';
import { MailPage } from './pages/MailPage/MailPage';
import { AnalyticSchoolBill } from './pages/Analytics/pages/AnalyticSchoolBill/AnalyticSchoolBill';
import { AnalyticsCredits } from './pages/Analytics/pages/AnalyticsCredits/AnalyticsCredits';
import { AnalyticVendor } from './pages/Analytics/pages/AnalyticVendor/AnalyticVendor';
import { UserAnalytic } from './pages/Analytics/pages/UserAnalytic/UserAnalytic';
import { UserTransactions } from './pages/Transactions/UserTransactions';
import { TransactionsDetails } from './pages/Transactions/TransactionsDetails';

import { USER } from './config/axios';
import { VendorProfile } from './pages/Vendors/VendorProfile/VendorProfile';
import { EditUser } from './pages/Users/EditUser';

const getRoutes = (isLogged: boolean): RouteObject[] => [
  {
    path: ROUTES.LOGIN,
    element: isLogged ? <Navigate to={ROUTES.DASHBOARD} /> : <Login />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTES.NOTFOUND,
    element: <NotFound />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: isLogged ? <Dashboard /> : <Navigate to={ROUTES.LOGIN} />,
    children: [
      {
        path: ROUTES.ADMIN,
        element: <Admin />,
      },
      {
        path: ROUTES.OVERVIEW,
        element: <Overview />,
      },
      {
        path: ROUTES.USERS,
        element: <Outlet />,
        children: [
          { path: '', element: <Users /> },
          {
            path: `${ROUTES.USERS}/:id`,
            element: <Outlet />,
            children: [
              { path: '', element: <UserDetail /> },
              { path: 'edit', element: <EditUser /> },
            ],
          },
        ],
      },
      {
        path: `${ROUTES.USER_TRANSACTIONS}/:id`,
        element: <UserTransactions />,
      },
      {
        path: ROUTES.FAQ,
        element: <Faq />,
      },
      {
        path: ROUTES.GROUPS,
        element: <Groups />,
      },
      {
        path: `${ROUTES.GROUP_DETAIL}/:id`,
        element: <GroupDetail />,
      },
      {
        path: ROUTES.ANALYTICS,
        element: <Analytics />,
        children: [
          {
            index: true,
            element: <AnalyticDeposits />,
          },
          {
            path: ROUTES.ANALYTICS_GROUPS,
            element: <AnalyticGroups />,
          },
          {
            path: ROUTES.ANALYTICS_SCHOOL_BILL,
            element: <AnalyticSchoolBill />,
          },
          {
            path: ROUTES.ANALYTICS_CREDITS,
            element: <AnalyticsCredits />,
          },
          {
            path: ROUTES.ANALYTICS_TRANSACTIONS,
            element: <AnalyticTransactions />,
          },
          {
            path: ROUTES.ANALYTICS_SUPPORT,
            element: <AnalyticSupport />,
          },
          {
            path: ROUTES.ANALYTIC_VENDOR,
            element: <AnalyticVendor />,
          },
          {
            path: ROUTES.ANALYTIC_USERS,
            element: <UserAnalytic />,
          },
          {
            path: ROUTES.ANALYTIC_DEPOSITS,
            element: <AnalyticDeposits />,
          },
        ],
      },
      {
        path: ROUTES.DATA_MIGRATION,
        element: <DataMigration />,
      },
      {
        path: ROUTES.SCHOOL_PROFILE,
        element: <SchoolProfile />,
      },
      {
        path: ROUTES.INTERNAL_SUPPORT,
        element: <InternalSupport />,
      },
      {
        path: `${ROUTES.INTERNAL_SUPPORT_TICKET}/:id`,
        element: <InternalSupportTicket />,
      },
      {
        path: ROUTES.EXTERNAL_SUPPORT,
        element: <ExternalSupport />,
      },
      {
        path: `${ROUTES.EXTERNAL_SUPPORT_TICKET}/:id`,
        element: <ExternalSupportTicket />,
      },
      {
        path: ROUTES.VENDORS,
        element: <Vendors />,
      },
      {
        path: ROUTES.PERMANENT_VENDORS,
        element: <PermanentVendors />,
      },
      {
        path: ROUTES.TEMPORARY_VENDORS,
        element: <TemporaryVendors />,
      },
      {
        path: `${ROUTES.VENDOR_PROFILE}/:id`,
        element: <VendorProfile />,
      },
      {
        path: ROUTES.PERMISSIONS,
        element: <Permissions />,
      },
      {
        path: ROUTES.TRANSACTIONS,
        element: <Transactions />,
      },
      {
        path: `${ROUTES.TRANSACTION_DETAILS}/:id`,
        element: <TransactionsDetails />,
      },
      {
        path: `${ROUTES.MAIL}/:type`,
        element: <Mail />,
      },
      {
        path: `${ROUTES.MAIL_PAGE}/:id`,
        element: <MailPage />,
      },
    ],
  },
];

export const AppRoutes = () => {
  const routes = useRoutes(getRoutes(!!localStorage.getItem(USER)));

  return <>{routes}</>;
};
